import { Component, TemplateRef, ViewChild } from '@angular/core';
import { TourMatMenuModule, TourService } from 'ngx-ui-tour-md-menu';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCheckbox } from '@angular/material/checkbox';

@Component({
  selector: 'maporium-tour-step-template',
  template: `
    <ng-template #customTemplate let-step="step">
      <mat-card
        (click)="$event.stopPropagation()"
        [style.width]="step.stepDimensions?.width"
        [style.min-width]="step.stepDimensions?.minWidth"
        [style.max-width]="step.stepDimensions?.maxWidth">
        <mat-card-header>
          <div class="header-group">
            <mat-card-title class="w-100 text-center">
              {{ step.title }}
            </mat-card-title>
            <button
              mat-icon-button
              (click)="tourService.end()"
              class="close"
            >
              <mat-icon>close</mat-icon>
            </button>
          </div>
        </mat-card-header>

        <mat-card-content class="mat-body">
          <div class="content" style="font-size: 20px" [innerHTML]="step.content">
          </div>
        </mat-card-content>

        <mat-card-actions
          [class.no-progress]="!step.showProgress">
          <button
            mat-button
            class="prev"
            [ngClass]="{'no-prev': !tourService.hasPrev(step)}"
            [disabled]="!tourService.hasPrev(step)"
            (click)="tourService.prev()">
            <mat-icon>chevron_left</mat-icon>
            PREVIOUS
          </button>
          @if (step.showProgress) {
            <div class="progress-count">
              {{ tourService.steps!.indexOf(step) + 1 }} / {{ tourService.steps!.length }}
            </div>
          }
          @if (tourService.hasNext(step) && !step.nextOnAnchorClick) {
            <button
              class="next"
              (click)="tourService.next()"
              mat-button>
              {{ step.nextBtnTitle }}
              <mat-icon iconPositionEnd>chevron_right</mat-icon>
            </button>
          }
          @if (!tourService.hasNext(step)) {
            <button
              mat-button
              class="end-btn"
              (click)="tourService.end()"
            >
              {{ step.endBtnTitle }}
            </button>
          }
        </mat-card-actions>
        <div class="dont-show-again-section d-flex align-items-center justify-content-center">
          <mat-checkbox (click)="doNotShowAgain()">
            Do not show this again
          </mat-checkbox>
        </div>
      </mat-card>
    </ng-template>
  `,
  standalone: true,
  styleUrls: ['./tour-step-template.component.scss'],
  imports: [
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
    CommonModule,
    RouterModule,
    TourMatMenuModule,
    MatCheckbox
  ]
})
export class TourStepTemplateComponent {
  static readonly SHOW_TOUR_KEY = 'showTour';
  @ViewChild('customTemplate', { static: true }) customTemplate!: TemplateRef<any>;

  constructor(public tourService: TourService) {
  }

  doNotShowAgain() {
    localStorage.setItem(TourStepTemplateComponent.SHOW_TOUR_KEY, 'false');
  }
}

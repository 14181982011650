import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { CommonModule } from '@angular/common';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { User } from '../api/interfaces/user.interface';
import { MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { map, Observable, startWith } from 'rxjs';
import { UserService } from '../api/services/user.service';
import { MapService } from '../api/services/map.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ConfirmationDialogComponent } from './confirmation-dialog.component';
import { GraphMap } from '../api/interfaces/graph-node.interface';
import { set } from 'lodash';

@Component({
  selector: 'maporium-transfer-map-dialog',
  styles: [`
    ::ng-deep {

      .share-map-form {
        --mdc-theme-primary: #000000;

        .mdc-floating-label {
          color: #000000 !important;
        }
      }

    }
  `],
  template: `
    <div class="w-100 d-flex justify-content-between align-items-center dialog-header">
      <h2 class="align-items-center d-flex m-0 p-0"><span class="material-symbols-rounded me-2">passkey</span>
        Transfer Smap
      </h2>
      <mat-icon class="material-symbols-rounded close-button" mat-dialog-close matTooltip="Close the panel">
        close
      </mat-icon>
    </div>
    <div mat-dialog-content class="p-3 px-4">
      <form class="share-map-form">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>New Owner</mat-label>
          <mat-chip-grid #chipGrid aria-label="User selection">
            <mat-chip-row *ngFor="let email of emails" (removed)="remove(email)">
              {{ getUserName(email) }} <{{ email }}>
              <button matChipRemove [attr.aria-label]="'remove ' + email">
                <mat-icon>cancel</mat-icon>
              </button>
            </mat-chip-row>
          </mat-chip-grid>
          <input #userInput
                 [formControl]="userCtrl"
                 [matChipInputFor]="chipGrid"
                 [matAutocomplete]="auto"
                 [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                 [disabled]="emails.length > 0"
                 (matChipInputTokenEnd)="add($event)" />
          <mat-autocomplete #auto="matAutocomplete"
                            (optionSelected)="selected($event)">
            <mat-option *ngFor="let user of filteredUsers | async" [value]="user">
              {{ user }}
            </mat-option>
          </mat-autocomplete>

        </mat-form-field>
      </form>
      <mat-error *ngIf="userCtrl.hasError('invalidAutocompleteObject') && userCtrl.dirty">
        One of the Emails is not
        recognized. Click one of the autocomplete options.
      </mat-error>

    </div>
    <div mat-dialog-actions class="d-flex justify-content-center">
      <button mat-button mat-dialog-close>Cancel</button>
      <button mat-button (click)="update()" color="accent" *ngIf="hasChanges">Transfer</button>
    </div>
  `,
  standalone: true,
  imports: [
    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatTooltipModule,
    MatInputModule,
    MatTableModule,
    CommonModule,
    ReactiveFormsModule,
    MatChipsModule,
    MatAutocompleteModule
  ]
})
export class TransferMapDialogComponent {
  separatorKeysCodes: number[] = [ENTER, COMMA];
  userCtrl = new FormControl('');
  filteredUsers: Observable<string[]>;
  emails: string[] = [];
  allUserEmails: string[] = [];
  allAvailableUsers: User[] = [];
  sharedWithUsers: User[] = [];
  hasChanges = false;

  //@ts-ignore
  @ViewChild('userInput') userInput: ElementRef<HTMLInputElement>;

  constructor(private userService: UserService,
              private mapService: MapService,
              private dialog: MatDialog,
              private dialogRef: MatDialogRef<TransferMapDialogComponent>,
              @Inject(MAT_DIALOG_DATA) private data: { map: GraphMap }) {
    this.userService.getAvailableUsersForTransfer()
      .subscribe((users: User[]) => {
        this.allUserEmails = users.map(user => user.email) as string[];
        this.allAvailableUsers = users;
      });

    this.filteredUsers = this.userCtrl.valueChanges.pipe(
      startWith(null),
      map((fruit: string | null) => (fruit ? this._filter(fruit) : this.allUserEmails.slice()))
    );
  }

  add(event: MatChipInputEvent): void {
    if (this.userCtrl.invalid) return;
    const value = (event.value || '').trim();

    if (value) {
      const index = this.allUserEmails.indexOf(value);
      if (index === -1) return;
      this.emails = [value]; // Restrict to one selection
      this.sharedWithUsers = [this.allAvailableUsers[index]]; // Restrict to one user
      if (index >= 0) {
        this.allUserEmails.splice(index, 1);
      }
    }

    // Clear the input value and disable further input
    event.chipInput.clear();
    this.userCtrl.disable(); // Disable further typing
    this.filteredUsers = new Observable(); // Clear autocomplete options
    this.userCtrl.setValue(null);
    this.hasChanges = true;
  }

  //TODO: rework this
  getUserName(email: string): string {
    if (!email) return '';
    const user = this.sharedWithUsers.find(user => user.email === email);
    if (!user) return '';
    return user?.name as string;
  }

  remove(email: string): void {
    const index = this.emails.indexOf(email);

    if (index >= 0) {
      this.allUserEmails.push(email);
      this.allAvailableUsers.push(this.sharedWithUsers[index]);
      this.emails.splice(index, 1);
      this.sharedWithUsers.splice(index, 1);
    }

    // Re-enable input after removal
    this.userCtrl.enable(); // Re-enable typing
    this.filteredUsers = this.userCtrl.valueChanges.pipe( // Restore autocomplete options
      startWith(null),
      map((value: string | null) =>
        value ? this._filter(value) : this.allUserEmails.slice()
      )
    );
    this.hasChanges = true;
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    const selectedEmail = event.option.viewValue;
    const index = this.allUserEmails.indexOf(selectedEmail);

    if (index !== -1) {
      this.emails = [selectedEmail]; // Restrict to one selection
      this.sharedWithUsers = [this.allAvailableUsers[index]]; // Restrict to one user
      this.allUserEmails.splice(index, 1);
    }

    // Disable further input
    this.userInput.nativeElement.value = '';
    this.userCtrl.disable(); // Disable further typing
    this.filteredUsers = new Observable(); // Clear autocomplete options
    this.userCtrl.setValue(null);
    this.hasChanges = true;
  }

  update() {
    if (this.emails.length === 0) return;
    const confirmation = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Confirm Transfer',
        icon: 'warning',
        message: `Are you sure that you want to transfer the '${this.data.map.name}' smap to user '${this.emails[0]}'?`
      }
    });
    confirmation.afterClosed().subscribe((result) => {
      if (result) {
        this.mapService.changeOwner(this.data.map.id, this.sharedWithUsers[0].id)
          .subscribe((res) => {
            set(res, 'isReadonly', true);
            this.mapService.setCurrentSelectedMapToStore(res as any);
            setTimeout(() => {
              location.reload();
            }, 100);
          });
      }
    });
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allUserEmails.filter(fruit => fruit.toLowerCase().includes(filterValue));
  }
}

import { Directive, effect, ElementRef, Renderer2 } from '@angular/core';
import { MapService } from '../../api/services/map.service';
import { GraphMap } from '../../api/interfaces/graph-node.interface';
import { SettingsService } from '../../api/services/setting.service';

@Directive({
  selector: '[appSmapStyles]',
  standalone: true
})
export class SmapStylesDirective {
  private readonly DEFAULT_COLOR = '#FFFFFF'; // Default fallback color

  constructor(private el: ElementRef,
              private mapService: MapService,
              private renderer: Renderer2,
              private settingsService: SettingsService) {
    // Effect 1: React to map style changes
    effect(() => {
      const map = this.mapService.getSelectedMapSignal();
      this.applyStyles(map?.styles || {});
      const settings = this.settingsService.getLocalSettingsSignal() || {}; // Default to empty object
      this.updateBackgroundOffset(settings.isSidebarOpen ?? false, settings.sidebarWidth ?? 0);
    });

    // Effect 2: React to changes in LocalStorageSettings
    effect(() => {
      const settings = this.settingsService.getLocalSettingsSignal() || {}; // Default to empty object
      this.updateBackgroundOffset(settings.isSidebarOpen ?? false, settings.sidebarWidth ?? 0);
    });
  }

  /** Main method to apply all styles */
  private applyStyles(styles: Partial<GraphMap['styles']>): void {
    this.ensureContainerIsSetup();
    this.clearExistingLayers();
    this.createOverlayLayer(styles.color, styles.colorIntensity);
    this.createBackgroundImageLayer(styles.backgroundImageUrl, styles.opacity);
  }

  /** Ensures the container has the correct CSS setup */
  private ensureContainerIsSetup(): void {
    const container = this.el.nativeElement;
    this.renderer.setStyle(container, 'position', 'relative');
    this.renderer.setStyle(container, 'overflow', 'hidden');
  }

  /** Removes any existing layers */
  private clearExistingLayers(): void {
    const container = this.el.nativeElement;
    const existingLayers = container.querySelectorAll('.background-layer, .overlay-layer');
    existingLayers.forEach((layer: HTMLElement) => layer.remove());
  }

  /** Creates the background image layer */
  private createBackgroundImageLayer(imageUrl?: string, opacity?: number, isSidebarOpen?: boolean): void {
    const container = this.el.nativeElement;

    if (imageUrl) {
      const sidebarWidth = isSidebarOpen ? this.getSidebarWidth() / 2 : '0px'; // If sidebar is closed, no offset

      const imageLayer = this.renderer.createElement('div');
      this.renderer.addClass(imageLayer, 'background-layer');
      this.renderer.setStyle(imageLayer, 'position', 'absolute');
      this.renderer.setStyle(imageLayer, 'top', '0');
      this.renderer.setStyle(imageLayer, 'left', `-${sidebarWidth}`); // Offset based on sidebar width
      this.renderer.setStyle(imageLayer, 'width', `calc(100% + ${sidebarWidth})`); // Adjust width
      this.renderer.setStyle(imageLayer, 'height', '100%');
      this.renderer.setStyle(imageLayer, 'pointer-events', 'none');
      this.renderer.setStyle(imageLayer, 'z-index', '-1');
      this.renderer.setStyle(imageLayer, 'background-image', `url(${imageUrl})`);
      this.renderer.setStyle(imageLayer, 'background-size', 'auto');
      this.renderer.setStyle(imageLayer, 'background-position', 'center');
      this.renderer.setStyle(imageLayer, 'background-repeat', 'no-repeat');
      this.renderer.setStyle(imageLayer, 'opacity', this.clamp(opacity ?? 1, 0, 1).toString());
      this.renderer.appendChild(container, imageLayer);
    }
  }

  private updateBackgroundOffset(isSidebarOpen: boolean, sidebarWidth: number): void {
    const container = this.el.nativeElement;
    const backgroundLayer = container.querySelector('.background-layer');

    if (backgroundLayer) {
      const offset = isSidebarOpen ? -sidebarWidth : 0; // Negative for left offset
      this.renderer.setStyle(backgroundLayer, 'left', `${offset / 2}px`);
    }
  }

  /** Creates the color overlay layer */
  private createOverlayLayer(color?: string, intensity?: number): void {
    const container = this.el.nativeElement;

    if (color) {
      const overlayLayer = this.renderer.createElement('div');
      this.renderer.addClass(overlayLayer, 'overlay-layer');
      this.renderer.setStyle(overlayLayer, 'position', 'absolute');
      this.renderer.setStyle(overlayLayer, 'top', '0');
      this.renderer.setStyle(overlayLayer, 'left', '0');
      this.renderer.setStyle(overlayLayer, 'width', '100%');
      this.renderer.setStyle(overlayLayer, 'height', '100%');
      this.renderer.setStyle(overlayLayer, 'pointer-events', 'none');
      this.renderer.setStyle(overlayLayer, 'z-index', '-1'); // Above the background image layer

      const overlayColor = this.getColorWithIntensity(color, intensity ?? 1);
      this.renderer.setStyle(overlayLayer, 'background-color', overlayColor);
      this.renderer.appendChild(container, overlayLayer);
    }
  }

  /** Converts a color to a hex format and adjusts intensity */
  private getColorWithIntensity(color: string, intensity: number): string {
    try {
      const alpha = this.clamp(intensity, 0, 1).toFixed(2); // Directly clamp intensity to 0-1
      const hexColor = this.toHexColor(color);
      const alphaHex = Math.round(parseFloat(alpha) * 255) // Convert alpha to hex
        .toString(16)
        .padStart(2, '0');
      return `${hexColor}${alphaHex}`;
    } catch {
      console.error('Invalid color provided:', color);
      return this.DEFAULT_COLOR; // Fallback to default color
    }
  }

  /** Validates and ensures the color is in hex format */
  private toHexColor(color: string): string {
    if (/^#([0-9A-F]{3}){1,2}$/i.test(color)) {
      return color;
    }
    console.error('Invalid color format:', color);
    return this.DEFAULT_COLOR; // Default to white
  }

  /** Clamps a value to a given range */
  private clamp(value: number, min: number, max: number): number {
    return Math.max(min, Math.min(max, value));
  }

  /** Dynamically fetches the sidebar width from the CSS variable */
  private getSidebarWidth(): number {
    const sidebarWidth = getComputedStyle(document.documentElement).getPropertyValue('--sidebar-width');
    return parseFloat(sidebarWidth) || 0; // Fallback to 0 if not set
  }
}

import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'maporium-confirm-dialog',
  styles: [`
    pre {
      white-space: pre-wrap;
      word-wrap: break-word;
      overflow-x: auto;
      max-height: 90px;
    }
  `],
  template: `
    <div class="w-100 d-flex justify-content-between align-items-center dialog-header">
      <h2 class="align-items-center d-flex m-0 p-0"><span class="material-symbols-rounded me-2">{{icon}}</span>
        {{title}}
      </h2>
      <mat-icon class="material-symbols-rounded close-button" mat-dialog-close matTooltip="Close the panel">
        close
      </mat-icon>
    </div>
    <div mat-dialog-content>
      <p style="white-space: pre-line">{{message}}</p>
      <pre *ngIf="errorMessage" [innerHTML]="errorMessage">
      </pre>
    </div>
    <div mat-dialog-actions class="d-flex justify-content-evenly">
      <button mat-button mat-dialog-close *ngIf="showCancel">{{cancelText}}</button>
      <button mat-button (click)="confirm()" color="accent" cdkFocusInitial>{{confirmText}}</button>
    </div>
  `,
  imports: [
    MatDialogModule,
    MatButtonModule,
    MatTooltipModule,
    CommonModule,
    MatIconModule
  ],
  standalone: true
})

export class ConfirmationDialogComponent {
  public title = 'Confirm Your Action';
  public message = 'This action is permanent, please confirm?';
  public errorMessage = undefined;
  public cancelText = 'Cancel';
  public confirmText = 'Confirm';
  public icon = 'warning';
  public showCancel = true;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private onConfirm = undefined;
  constructor(
    private matDialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data?: {
      title?: string,
      message?: string,
      icon?: string,
      cancelText?: string,
      confirmText?: string,
      onConfirm?: () => void,
      showCancel?: boolean,
      errorMessage?: string
    }
  ) {
    if (data) {
      this.title = data.title || this.title;
      this.message = data.message || this.message;
      this.icon = data.icon || this.icon;
      this.cancelText = data.cancelText || this.cancelText;
      this.confirmText = data.confirmText || this.confirmText;
      // @ts-ignore
      this.onConfirm = data.onConfirm || this.onConfirm;
      this.showCancel = data.showCancel !== undefined ? data.showCancel : this.showCancel;
      // @ts-ignore
      this.errorMessage = data.errorMessage || this.errorMessage;
    }
  }

  confirm() {
    if (this.onConfirm){
      // @ts-ignore
      this.onConfirm();
    }
    this.matDialogRef.close(true);
  }


  protected readonly undefined = undefined;
}

import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren
} from '@angular/core';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { TextAreaComponent } from '../../forms/text-area/text-area-input.component';
import { CommentService } from '../../../api/services/comment.service';
import { Comment, CommentEntityType } from '../../../api/interfaces/comment';
import { DatePipe, JsonPipe, NgClass, NgTemplateOutlet } from '@angular/common';
import { MatDivider } from '@angular/material/divider';
import { User } from '../../../api/interfaces/user.interface';
import { UserService } from '../../../api/services/user.service';
import { AutoResizeInputDirective } from '../../directives/auto-resize-input.directive';
import { AutoSelectInputDirective } from '../../directives/auto-select-input.directive';
import { MatTooltip } from '@angular/material/tooltip';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { TruncatePipe } from '../../pipes/truncate.pipe';
import { finalize, Subscription, timer } from 'rxjs';
import { MapService } from '../../../api/services/map.service';
import { sum } from 'lodash';
import { MarkdownComponent } from 'ngx-markdown';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
  standalone: true,
  selector: 'maporium-comments',
  templateUrl: './comments.component.html',
  imports: [
    ReactiveFormsModule,
    TextAreaComponent,
    DatePipe,
    MatDivider,
    AutoResizeInputDirective,
    AutoSelectInputDirective,
    MatTooltip,
    CdkTextareaAutosize,
    TruncatePipe,
    NgTemplateOutlet,
    JsonPipe,
    NgClass,
    MarkdownComponent,
    TranslatePipe
  ],
  styleUrls: ['./comments.component.scss']
})
export class CommentsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() entityId: string | undefined;
  @Input() entityType: CommentEntityType | undefined;
  @Input() isEditing = true;
  @Output() updated = new EventEmitter<boolean>();
  @Output() commentCount = new EventEmitter<number>();

  @ViewChildren('txtAreaValue') commentTextArea: QueryList<ElementRef> | undefined;

  public newCommentControl = new FormControl('', Validators.required);
  public comments: Comment[] = [];
  public replyToIndex: number | null = null;
  public createNewComment = false;
  public user: User;
  public maxNameLength = 20;
  public currentEditingCommentElementId: string | null = null;

  private subs = new Subscription();
  private currentEditingComment: Comment | null = null;
  private fetchingComments = false;

  constructor(private commentService: CommentService,
              private mapService: MapService,
              private userService: UserService) {
    this.user = this.userService.getCurrentUserFromStorage();
  }

  get newCommentDate() {
    return new Date();
  }

  getIsLongName(user?: User) {
    const length = this.getFullName(user).length;
    return length > this.maxNameLength;
  }

  getFullName(user?: User) {
    return user ? `${user.name} ${user.lastName}` : `${this.user.name} ${this.user.lastName}`;
  }

  getFormattedDate(comment?: Comment) {
    return new DatePipe('en-US').transform(comment ? comment.createdAt : this.newCommentDate, 'MM/dd/yyyy hh:mm a');
  }

  get currentUser() {
    return this.userService.getCurrentUserFromStorage();
  }

  get isSmapAuthor() {
    return this.mapService.isSmapAuthor();
  }

  ngOnInit() {
    this.subs.add(
      timer(0, 60000).subscribe(() => {
        this.getComments();
      })
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['entityId']?.firstChange || changes['isReadOnly']?.firstChange || changes['entityId'] === undefined) {
      return;
    }


    console.log(changes['entityId'].previousValue);
    if (changes['entityId'].currentValue !== changes['entityId'].previousValue) {
      this.getComments();
    }
  }

  startCreatingComment() {
    this.createNewComment = true;

    setTimeout(() => {
      if (this.commentTextArea) {
        this.commentTextArea.first.nativeElement.focus();
      }
    }, 100);
  }

  startEditingComment(elementId: string, comment: Comment) {
    if (this.currentUser.id !== comment.user.id) {
      return;
    }
    this.currentEditingCommentElementId = elementId;
    this.currentEditingComment = comment;
    setTimeout(() => {
      if (this.commentTextArea) {
        this.newCommentControl.setValue(comment.content);
      }
    }, 10);
  }

  stopEditingComment() {
    this.currentEditingCommentElementId = null;
    this.currentEditingComment = null;
    this.newCommentControl.reset();
  }

  addComment() {
    if (this.currentEditingCommentElementId && this.currentEditingComment) {
      this.editComment(this.currentEditingComment);
      return;
    }
    const value = this.newCommentControl.value;
    if (!this.entityId && !this.entityType) {
      return;
    }
    if (this.newCommentControl.valid) {
      if (this.replyToIndex !== null) {
        this.commentService.replyToComment(this.comments[this.replyToIndex].id, value!)
          .pipe(
            finalize(() => {
              this.replyToIndex = null;
            })
          )
          .subscribe(() => {
            this.cancelComment();
            this.getComments();
            this.updated.emit(true);
          });
      } else {
        this.commentService.addComment(this.entityId!, this.entityType!, value!)
          .subscribe(() => {
            this.cancelComment();
            this.getComments();
            this.updated.emit(true);
          });
      }

    }
  }

  editComment(comment: Comment) {
    if (!this.currentEditingCommentElementId || this.newCommentControl.invalid) {
      return;
    }

    this.commentService.editComment(comment.id, this.newCommentControl.value as string)
      .subscribe(() => {
        this.stopEditingComment();
        this.getComments();
      });
  }

  getComments(emitUpdate = false) {
    if (!this.entityId) {
      return;
    }
    if (this.fetchingComments) {
      return;
    }

    this.fetchingComments = true;
    this.subs.add(
      this.commentService.getComments(this.entityId)
        .pipe(
          finalize(() => this.fetchingComments = false)
        )
        .subscribe((comments: Comment[]) => {
          this.comments = comments;
          this.commentCount.emit(comments.length + sum(comments.map(c => c.replies.length)));
          if (emitUpdate) {
            this.updated.emit(this.comments.length > 0);
          }
        })
    );
  }

  cancelComment() {
    this.createNewComment = false;
    this.currentEditingCommentElementId = null;
    this.currentEditingComment = null;
    this.replyToIndex = null;
    this.newCommentControl.setValue('');
  }

  deleteComment(comment: Comment) {
    if (this.currentUser.id !== comment.user.id && !this.isSmapAuthor) {
      return;
    }
    this.commentService.deleteComment(comment.id)
      .subscribe(() => {
        this.getComments(true);
      });
  }

  replyToComment(index: number) {
    this.replyToIndex = index;
    this.startCreatingComment();
  }
}

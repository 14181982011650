import {
  Component,
  effect,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { AutoSelectInputDirective } from '../../directives/auto-select-input.directive';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDivider } from '@angular/material/divider';
import { MatInputModule } from '@angular/material/input';
import { debounceTime, distinctUntilChanged } from 'rxjs';
import { isEqual } from 'lodash';
import { AutoResizeInputDirective } from '../../directives/auto-resize-input.directive';
import { MarkdownService } from '../../helpers/markdown.service';
import { MarkdownComponent, MARKED_OPTIONS, provideMarkdown } from 'ngx-markdown';
import { JsonPipe } from '@angular/common';
import { marked } from 'marked';
import { MapService } from '../../../api/services/map.service';

@Component({
  standalone: true,
  selector: 'maporium-title-description',
  templateUrl: './title-description-input.component.html',
  styleUrls: ['./title-description-input.component.scss'],
  imports: [
    AutoSelectInputDirective,
    ReactiveFormsModule,
    MatDivider,
    MatInputModule,
    AutoResizeInputDirective,
    MarkdownComponent,
    JsonPipe
  ],
  providers: [
    provideMarkdown({
      markedOptions: {
        provide: MARKED_OPTIONS,
        useValue: {
          gfm: true,
          breaks: true
        }
      }
    })
  ]
})
export class TitleDescriptionInputComponent implements OnChanges, OnInit, OnDestroy {
  @Input() fields: {
    title: string;
    description?: string;
  } | undefined;
  @Input() entityId: string | undefined;
  @Input() isEditing = false;
  @Input() isNew = false;
  @Input() isMap = false;
  @Input() noDescription = false;
  @Input() allowMultiLine = true;
  @Output() fieldsChange = new EventEmitter<{ title: string, description?: string, valid?: boolean }>();
  @ViewChild('titleInput') titleInput!: ElementRef;
  @ViewChild('textAreaElement') descriptionInput!: ElementRef;

  isMarkdownEnabled = false;

  formGroup = new FormGroup({
    title: new FormControl('', Validators.required),
    description: new FormControl('')
  });
  document = document;
  isUpdatingFromServer = false;

  constructor(private markdownService: MarkdownService, private mapService: MapService) {
    effect(() => {
      this.isMarkdownEnabled = this.markdownService.markdownEnabledSignal;
    });

    effect(() => {
        const map = this.mapService.getSelectedMapSignal();
        if (map && this.isMap) {
          this.formGroup.patchValue({title: map.name, description: map.description});
        }
      }
    );
  }

  get description(): string {
    return MarkdownService.cleanUpMarkdown(marked(MarkdownService.convertToMarkdown(this.fields?.description || '')) as string);
  }

  ngOnInit() {
    if (this.fields && this.isEditing) {
      this.formGroup.patchValue(this.fields);
    }
    this.formGroup.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged((prev, curr) => isEqual(prev, curr))
      )
      .subscribe((value) => {
        // Only emit changes if not updating from server
        if (!this.isUpdatingFromServer) {
          const fields = this.formGroup.getRawValue();
          this.fieldsChange.emit({ ...fields, valid: this.formGroup.valid } as {
            title: string,
            description?: string,
            valid?: boolean
          });
        }
      });
  }


  ngOnDestroy(): void {
    const fields = this.formGroup.getRawValue();
    this.fieldsChange.emit({...fields, valid: this.formGroup.valid} as { title: string, description?: string, valid?: boolean});
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.isNew && changes['isNew']?.firstChange) {
      // @ts-ignore
      this.formGroup.patchValue(this.fields);
      this.selectNameInput();
      return;
    }
    const idsDoNotMatch = changes['entityId'] && changes['entityId'].currentValue !== changes['entityId'].previousValue;

    if (this.fields && !this.isNew) {
      // Merge logic: Check if incoming fields should update untouched form fields
      const incomingFields = this.fields;
      const currentFormValues = this.formGroup.value;
      // Track modified fields and merge
      const mergedValues = { ...currentFormValues };
      Object.keys(incomingFields).forEach((key) => {
        const fieldControl = this.formGroup.get(key);

        // Only update if the field is untouched and pristine
        if (fieldControl?.pristine || idsDoNotMatch) {
          // @ts-ignore
          mergedValues[key] = incomingFields[key];
        }
      });

      // Patch merged values
      if (!isEqual(mergedValues, currentFormValues)) {
        this.isUpdatingFromServer = true;
        this.formGroup.patchValue(mergedValues, { emitEvent: false });
        this.isUpdatingFromServer = false;
      }
    }
  }



  selectNameInput() {
    setTimeout(() => {
      this.titleInput?.nativeElement?.select();
      this.isNew = false;
    }, 200);
  }

  resizeDescription() {
    const textarea = this.descriptionInput.nativeElement;
    textarea.style.height = '20px';
    if (textarea.scrollHeight > textarea.clientHeight) {
      textarea.style.height = textarea.scrollHeight + 'px';
    }
  }

  onEnter($event: any) {
    if(!this.allowMultiLine) {
      $event.preventDefault();
    }
  }
}

import { Injectable } from '@angular/core';
import { JobResource } from '../resource/job.resource';
import { Job } from '../interfaces/jobs.dto';

@Injectable({
  providedIn: 'root'
})
export class JobService {
  private reconnectInterval: any;

  constructor(private jobResource: JobResource) {
  }

  /**
   * Fetch a job by ID.
   * Delegates to the JobResource.
   */
  getJob(jobId: string) {
    return this.jobResource.getJob(jobId);
  }

  /**
   * Connect to the SSE endpoint for job updates.
   * @param onMessage - Callback for job updates.
   * @param onError - Callback for connection errors.
   */
  connectToJobUpdates(
    onMessage: (data: Job) => void,
    onError: (error: any) => void
  ): void {
    this.jobResource.connectToSse(
      (data: Job) => {
        onMessage(data);

        // Close the connection if the job is no longer in progress
        if (data.status === 'COMPLETED' || data.status === 'FAILED') {
          this.cleanup();
        }
      },
      (error) => {
        console.error('SSE Error:', error);

        // Retry connection on error
        this.reconnectInterval = setTimeout(
          () => this.connectToJobUpdates(onMessage, onError),
          5000
        );
        onError(error);
      }
    );
  }


  /**
   * Cleanup: Close SSE connection and clear intervals.
   */
  cleanup(): void {
    this.jobResource.closeSse();

    if (this.reconnectInterval) {
      clearTimeout(this.reconnectInterval);
      this.reconnectInterval = null;
    }
  }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatListModule } from '@angular/material/list';
import { CommonModule } from '@angular/common';
import { MatLineModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { UserService } from '../api/services/user.service';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { ToursService } from '../api/services/tour.service';
import { CreateNewTourDialogComponent } from './create-new-tour-dialog.component';
import { MapService } from '../api/services/map.service';
import { MapTour } from '../api/interfaces/map-tour';
import { ConfirmationDialogComponent } from './confirmation-dialog.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { isInIframe } from '../shared/helpers/maporium.validators';

@Component({
  selector: 'maporium-tour-list',
  styleUrls: ['./tour-list.bottomsheet.component.scss'],
  template: `
    <mat-nav-list class="list-wrapper">
      <a href="" mat-list-item *ngFor="let tour of tours"
         [matTooltip]="tour.description || tour.name"
         matTooltipPosition="right"
         (click)="selectTour(tour); $event.preventDefault(); $event.stopPropagation()"
         class="d-flex justify-content-evenly p-0">
        <span class="row w-100 align-items-center m-0">
          <span class="col-2 align-items-center d-flex">
              <mat-icon class="material-symbols-rounded"
                        *ngIf="!tour.excludeFromReadOnly"
                        matTooltip="Everyone can see this story">visibility</mat-icon>
              <mat-icon class="material-symbols-rounded"
                        matTooltip="Viewers with whom you share this smap cannot see this story"
                        *ngIf="tour.excludeFromReadOnly">visibility_off</mat-icon>
          </span>
          <span class="col-6 pe-5">
              <span matLine>{{tour.name}}</span>
          </span>
          <span class="col-4  d-flex justify-content-end">
            <button mat-icon-button
                    matTooltip="Edit the story’s definition"
                    (click)="edit(tour);$event.preventDefault();  $event.stopPropagation()">
              <mat-icon class="material-symbols-rounded">edit</mat-icon>
            </button>

            <button mat-icon-button
                    matTooltip="Delete the story"
                    (click)="askForConfirmation(tour);$event.preventDefault();  $event.stopPropagation()">
                    <mat-icon class="material-symbols-rounded">delete</mat-icon>
            </button>
          </span>
        </span>
      </a>
    </mat-nav-list>
    <mat-divider></mat-divider>
    <div class="p-0 mt-1">
        <span class="row w-100 align-items-center m-0">
          <button matTooltip="Create a new story as a sequence of scenes"
                  mat-flat-button
                  (click)="createNew(); $event.preventDefault();">
            <mat-icon class="material-symbols-rounded">add_circle</mat-icon>
              Create Story
            </button>
        </span>
    </div>
  `,

  standalone: true,
  imports: [
    MatListModule,
    CommonModule,
    MatLineModule,
    MatIconModule,
    MatButtonModule,
    MatSnackBarModule,
    MatTooltipModule,
  ]
})

export class TourListBottomsheetComponent implements OnInit, OnDestroy {
  public tours: any[] = [];
  private subSink: Subscription = new Subscription();

  constructor(private matDialog: MatDialog,
              private userService: UserService,
              private mapService: MapService,
              private snackBar: MatSnackBar,
              private toursService: ToursService,
              private bottomSheetRef: MatBottomSheetRef<TourListBottomsheetComponent>) {
  }

  ngOnInit() {
    this.subSink.add(
      (isInIframe() ? this.toursService.getAllPublic(this.mapService.getCurrentSelectedMapFromStore().id) : this.toursService.getAll(this.mapService.getCurrentSelectedMapFromStore().id))
        .subscribe((tours) => {
        this.tours = tours;
      })
    );
  }

  ngOnDestroy() {
    this.subSink.unsubscribe();
  }

  selectTour(tour: MapTour) {
    if (tour.steps && tour.steps.length > 0 ) {
      this.bottomSheetRef.dismiss(tour);
    } else {
      this.snackBar.open('This story has no steps', 'Dismiss', {duration: 60000});
    }
  }

  createNew() {
    const ref = this.matDialog.open(CreateNewTourDialogComponent);
    ref.afterClosed().subscribe((tour: any) => {
      if (tour) {
        (isInIframe() ?
          this.toursService.getAllPublic(this.mapService.getCurrentSelectedMapFromStore().id) :
          this.toursService.getAll(this.mapService.getCurrentSelectedMapFromStore().id)
        )
          .subscribe((tours) => {
          this.tours = tours;
        })
      }
    });
  }

  edit(tour: MapTour) {
    const ref = this.matDialog.open(CreateNewTourDialogComponent, {data: tour});
    ref.afterClosed().subscribe((v: any) => {
      if (v) {
        (isInIframe() ?
        this.toursService.getAllPublic(this.mapService.getCurrentSelectedMapFromStore().id) :
        this.toursService.getAll(this.mapService.getCurrentSelectedMapFromStore().id)
        )
          .subscribe((tours) => {
          this.tours = tours;
        })
      }
    });
  }

  delete(tour: MapTour) {
    this.toursService.delete(tour).subscribe((res) => {
      this.tours = this.tours.filter((t) => t.id !== tour.id);
    });
  }

  askForConfirmation(tour: MapTour) {
    const ref = this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Delete Story',
        message: `Are you sure that you want to delete the '${tour.name}' story?`,
        confirmText: 'Delete'
      }
    });
    ref.afterClosed().subscribe((res) => {
      if (res) {
        this.delete(tour);
      }
    });
  }
}

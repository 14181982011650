import { Core, EdgeSingular, NodeSingular } from 'cytoscape';
import { get, omit } from 'lodash';
import { GraphNodeLink } from '../../api/interfaces/graph-node.interface';

function bendFnc(ele: any) {
  return ele.data('bendPointPositions');
}

function controlFnc(ele: any) {
  return ele.data('controlPointPositions');
}

export function mapAnchorsToXYArray(anchors: number[]) {
  let result = [];
  if (anchors?.length === undefined) {
    console.warn('Anchors array is not valid');
    return [];
  }

  // Iterate over the array in steps of 2 (for x and y coordinates)
  for (let i = 0; i < anchors.length; i += 2) {
    result.push({
      x: anchors[i],
      y: anchors[i + 1]
    });
  }

  return result;
}

export function getEqualDistributedAnchorPoints(edge: EdgeSingular) {
  // Get positions of source and target nodes, adjusted for bounding boxes
  const sourcePos = getEdgeAdjustedPosition(edge.source(), edge.target().position());
  const targetPos = getEdgeAdjustedPosition(edge.target(), edge.source().position());

  // Calculate vector components for the direction from source to target
  const dx = targetPos.x - sourcePos.x;
  const dy = targetPos.y - sourcePos.y;

  // Calculate the two anchor points along the edge
  const anchorPointOneThirdFromSource = {
    x: sourcePos.x + dx / 3,
    y: sourcePos.y + dy / 3
  };

  const anchorPointOneThirdFromTarget = {
    x: targetPos.x - dx / 3,
    y: targetPos.y - dy / 3
  };

  return { source: anchorPointOneThirdFromSource, target: anchorPointOneThirdFromTarget };
}


function getEdgeAdjustedPosition(node: NodeSingular, otherNodePosition: { x: number; y: number }) {
  const bb = node.boundingBox({ includeLabels: false });
  const centerX = bb.x1 + (bb.x2 - bb.x1) / 2;
  const centerY = bb.y1 + (bb.y2 - bb.y1) / 2;

  // Calculate the vector from the center of the node to the other node
  const dx = otherNodePosition.x - centerX;
  const dy = otherNodePosition.y - centerY;

  const scalingFactor = Math.min(
    Math.abs((bb.x2 - bb.x1) / 2 / dx) || 1,
    Math.abs((bb.y2 - bb.y1) / 2 / dy) || 1
  );

  return {
    x: centerX + dx * scalingFactor,
    y: centerY + dy * scalingFactor
  };
}

export function recalculateEqualDistributedAnchorPoints(edge: EdgeSingular, anchorCount: number) {
  if (!edge || anchorCount < 1) {
    console.error('Edge and a valid number of anchor points must be provided');
    return [];
  }

  // Get positions of source and target nodes, adjusted for bounding boxes
  const sourcePos = getEdgeAdjustedPosition(edge.source(), edge.target().position());
  const targetPos = getEdgeAdjustedPosition(edge.target(), edge.source().position());

  // Calculate evenly distributed anchor points
  const calculateEqualDistributedPoints = (source: { x: number; y: number }, target: {
    x: number;
    y: number
  }, count: number) => {
    const dx = (target.x - source.x) / (count + 1);
    const dy = (target.y - source.y) / (count + 1);
    const points = [];
    for (let i = 1; i <= count; i++) {
      points.push({
        x: source.x + dx * i,
        y: source.y + dy * i
      });
    }
    return points;
  };

  return calculateEqualDistributedPoints(sourcePos, targetPos, anchorCount);
}


export function callAddAnchor(edge: EdgeSingular, anchorType: 'bend' | 'control') {
  const addAnchorEvent = new CustomEvent('addAnchorEvent', {
    detail: {
      link: edge,
      anchorType,
      anchorPoints: getEqualDistributedAnchorPoints(edge)
    }
  });
  document.dispatchEvent(addAnchorEvent);
}

export function recreateLink(linkObject: GraphNodeLink, cy: Core): EdgeSingular {
  const edgeEditingProps = [
    'cyedgebendeditingDistances',
    'cyedgebendeditingWeights',
    'bendPointPositions',
    'controlPointPositions',
    'cyedgecontroleditingWeights',
    'cyedgecontroleditingDistances'
  ];

  const cleanedLinkData = omit(linkObject, edgeEditingProps);

  const linkId = get(linkObject, 'id');
  const sourceNodeId = get(linkObject, 'source');
  const targetNodeId = get(linkObject, 'target');

  cy.$('#' + linkId).remove();

  return cy.add({
    group: 'edges',
    data: {
      ...cleanedLinkData,
      source: sourceNodeId,
      target: targetNodeId
    }
  });
}


export function createNewAnchorsAndInitiateEdgeEditing(edge: EdgeSingular, edgeEditingInstance: any): {
  dataKey: string,
  anchorsArray: { x: number; y: number }[]
} {
  const anchorPoints = getEqualDistributedAnchorPoints(edge);
  const anchorsArray = [{ x: anchorPoints.source.x, y: anchorPoints.source.y }, {
    x: anchorPoints.target.x,
    y: anchorPoints.target.y
  }];
  const edgeType = edge.data('type');
  let dataKey = edgeType === 'STRAIGHT' ? 'bendPointPositions' : 'controlPointPositions';
  dataKey = edgeType === 'BEZIER' ? 'NO_DATA' : dataKey;
  let edgeEditingType = edgeType === 'BEZIER' ? 'none' : edgeType === 'STRAIGHT' ? 'bend' : 'control';
  const clearPointsData = (edge: EdgeSingular) => {
    edge.data('controlPointPositions', []);
    edge.data('bendPointPositions', []);
    edge.data('cyedgebendeditingDistances', []);
    edge.data('cyedgebendeditingWeights', []);
    edge.data('cyedgecontroleditingWeights', []);
    edge.data('cyedgecontroleditingDistances', []);
    edge.removeClass('edgebendediting-hasbendpoints');
    edge.removeClass('edgecontrolediting-hascontrolpoints');
  };

  if (edgeType === 'STRAIGHT') {
    clearPointsData(edge);
    edge.data(dataKey, anchorsArray);
    edge.removeClass('ignore_control_points');
    edge.removeClass('edgecontrolediting-hascontrolpoints');
  } else if (edgeType === 'UNBUNDLED_BEZIER') {
    clearPointsData(edge);
    edge.data(dataKey, anchorsArray);
    edge.removeClass('ignore_control_points');
    edge.removeClass('edgebendediting-hasbendpoints');
  } else {
    edge.addClass('ignore_control_points');
    edge.removeClass('edgebendediting-hasbendpoints');
    clearPointsData(edge);
  }
  edgeEditingInstance.initAnchorPoints(edge, edgeEditingType, bendFnc, controlFnc);
  return {
    dataKey,
    anchorsArray
  };
}

export function getPointDataFromAnchorsArray(edge: EdgeSingular, edgeEditingInstance: any, stateIndex = null): {
  dataKey: string,
  pointData: { x: number; y: number }[]
} | null {
  const anchorsArray = edgeEditingInstance.getAnchorsAsArray(edge);
  const type = stateIndex !== null ? edge.data().states[stateIndex].link.type : edge.data('type');
  const dataKey = type === 'STRAIGHT' ? 'bendPointPositions' : 'controlPointPositions';
  const pointData = mapAnchorsToXYArray(anchorsArray);

  if (type === 'BEZIER') {
    return null;
  }

  return {
    dataKey,
    pointData
  };
}

import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { DefaultValues, PACKAGE_NAMES } from '@maporium-workspace/shared';
import { GraphNode } from '../../api/interfaces/graph-node.interface';
import { NodeStyleAdvancedComponent } from './sections/node-style-advanced.component';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MetaDataControlComponent } from '../../shared/forms/key-value-table/meta-data-control.component';
import { MatListModule } from '@angular/material/list';
import { SelectInputComponent } from '../../shared/forms/select-input/select-input.component';
import { NodeStyleLightComponent } from './sections/node-style-light.component';
import { MatButtonModule } from '@angular/material/button';
import { isUndefined, orderBy, parseInt } from 'lodash';
import { SidebarFormAbstractComponent } from '../abstract/sidebar-form-abstract.component';
import {
  TitleDescriptionInputComponent
} from '../../shared/forms/title-description-input/title-description-input.component';
import { CdkAccordion } from '@angular/cdk/accordion';
import {
  MaporiumAccordionItemComponent
} from '../../shared/components/maporium-accordion/maporium-accordion-item.component';
import { metadata } from 'reflect-metadata/no-conflict';
import { MatIcon } from '@angular/material/icon';
import { CommentsComponent } from '../../shared/components/comments/comments.component';
import { NodeService } from '../../api/services/node.service';
import { hasGeneratedNewMetadata, updateInProgress } from '../../shared/helpers/signal.helpers';
import { TranslatePipe } from '@ngx-translate/core';
import { HasPackageDirective } from '../../packages/has-package.directive';

@Component({
  selector: 'maporium-node-form',
  templateUrl: './node-form.component.html',
  styleUrls: ['./node-form.component.scss'],
  standalone: true,
  imports: [
    NodeStyleLightComponent,
    NodeStyleAdvancedComponent,
    ReactiveFormsModule,
    CommonModule,
    MetaDataControlComponent,
    MatListModule,
    SelectInputComponent,
    MatButtonModule,
    TitleDescriptionInputComponent,
    CdkAccordion,
    MaporiumAccordionItemComponent,
    MatIcon,
    CommentsComponent,
    TranslatePipe,
    HasPackageDirective
  ]
})
export class NodeFormComponent extends SidebarFormAbstractComponent<ExtendedNode> implements OnInit, OnDestroy, OnChanges {
  formGroup = new FormGroup({
    name: new FormControl(''),
    description: new FormControl(''),
    properties: new FormGroup({
      label: new FormControl(DefaultValues.node.properties.label),
      textValign: new FormControl(DefaultValues.node.properties.textValign.toString()),
      textHalign: new FormControl(DefaultValues.node.properties.textHalign.toString()),
      textMarginX: new FormControl(DefaultValues.node.properties.textMarginX.toString()),
      textRotation: new FormControl(DefaultValues.node.properties.textRotation.toString()),
      imageOpacity: new FormControl(DefaultValues.node.properties.imageOpacity),
      opacity: new FormControl(DefaultValues.node.properties.opacity),
      textMarginY: new FormControl(DefaultValues.node.properties.textMarginY.toString()),
      textFontSize: new FormControl(DefaultValues.node.properties.textFontSize.toString()),
      textFontColor: new FormControl(DefaultValues.node.properties.textFontColor.toString()),
      scale: new FormControl(DefaultValues.node.properties.scale.toString()),
    }),
    color: new FormControl(DefaultValues.node.color),
    imageUrl: new FormControl(''),
    weight: new FormControl(DefaultValues.node.weight.toString()),
    type: new FormControl(DefaultValues.node.type),
    excludedFromState: new FormControl('false'),
    metadata: new FormControl([])
  });
  commentsCount = 0;

  @Input() isDefaultState = false;
  @Input() excludeFromStateOptions: {key: string, value: string, icon?: string}[] = [];
  @Input() focusedNode: ExtendedNode | undefined;
  @Output() resetEvent = new EventEmitter<{node: ExtendedNode}>();
  @Output() updateNodeEvent = new EventEmitter<Partial<ExtendedNode>>();
  @Output() metaExtend = new EventEmitter<void>();
  @ViewChild('nodeNameInput') nodeNameInput!: ElementRef;

  constructor(private nodeService: NodeService) {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {
    const previousNode = changes['focusedNode']?.previousValue as ExtendedNode;
    const currentNode = changes['focusedNode']?.currentValue as ExtendedNode;

    if (updateInProgress() && !isUndefined(previousNode) && previousNode.id === currentNode.id && !hasGeneratedNewMetadata()) {
      return;
    }

    // we should update the form if the focusedNode changes
    if (changes['focusedNode'] && changes['focusedNode'].currentValue) {
      const node = changes['focusedNode'].currentValue as ExtendedNode;
      if (changes['focusedNode'].previousValue?.id !== node.id || hasGeneratedNewMetadata()) {
        this.patchForm(node);
        hasGeneratedNewMetadata.set(false);
      }
      if (this.focusedNode?.isNew){
        this.selectNameInput();
      }
    }

    // check if the new value of node has isNew property and if it does, we should select the name input
    if (changes['focusedNode'] && changes['focusedNode'].currentValue?.isNew) {
      if (this.focusedNode?.isNew) {
        this.selectNameInput();
      }
    }
  }

  ngOnInit(): void {
    super.init();
  }

  ngOnDestroy(): void {
    this.update();
  }

  selectNameInput() {
    setTimeout(() => {
      this.nodeNameInput?.nativeElement?.select();
    }, 200);
  }

  resetState() {
    if (this.focusedNode) {
      this.resetEvent.emit({node: this.focusedNode});
    }
  }

  patchForm(node: ExtendedNode) {
    // Update basic fields
    this.formGroup.patchValue({
      name: node.name,
      description: node.description,
      color: node.color,
      imageUrl: node.imageUrl,
      weight: node.weight as unknown as string,
      type: node.type,
      excludedFromState: node.excludedFromState === true ? 'true' : 'false',
      properties: {
        label: this.getValueOrDefault(node.properties?.label, this.DefaultValues.node.properties.label),
        textValign: this.getValueOrDefault(node.properties?.textValign, this.DefaultValues.node.properties.textValign),
        textHalign: this.getValueOrDefault(node.properties?.textHalign, this.DefaultValues.node.properties.textHalign),
        textMarginX: this.getValueOrDefault(node.properties?.textMarginX, this.DefaultValues.node.properties.textMarginX),
        textRotation: this.getValueOrDefault(node.properties?.textRotation, this.DefaultValues.node.properties.textRotation),
        imageOpacity: this.getValueOrDefault(node.properties?.imageOpacity, this.DefaultValues.node.properties.imageOpacity),
        opacity: this.getValueOrDefault(node.properties?.opacity, this.DefaultValues.node.properties.opacity),
        textMarginY: this.getValueOrDefault(node.properties?.textMarginY, this.DefaultValues.node.properties.textMarginY),
        textFontSize: this.getValueOrDefault(node.properties?.textFontSize, this.DefaultValues.node.properties.textFontSize),
        textFontColor: this.getValueOrDefault(node.properties?.textFontColor, this.DefaultValues.node.properties.textFontColor),
        scale: this.getScale(node.properties?.scale).toString()
      },
    });

    // Handle metadata updates
    if (node.metadata !== undefined) {
      this.metadataControl.setValue(orderBy(node.metadata, ['order'], ['asc']));
    } else if (node.metadata === null || node.metadata === undefined) {
      this.metadataControl.setValue([]);
    }
  }


  onNameDescriptionChange(event: { title: string, description?: string }) {
    this.formGroup.patchValue({ name: event.title, description: event.description });
  }

  update() {
    if (!this.focusedNode) return;
    const raw = this.formGroup.getRawValue();
    const metadataFormData = this.formGroup.get('metadata')?.getRawValue()?.pairs || this.formGroup.get('metadata')?.getRawValue();
    // @ts-ignore
    const node: Partial<ExtendedNode> = {
      id: this.focusedNode?.id || '',
      name: raw.name as string,
      description: raw.description as string,
      color: raw.color as string,
      type: raw.type as string,
      imageUrl: raw.imageUrl as string,
      weight: parseInt(raw.weight as string),
      excludedFromState: raw.excludedFromState === 'true',
      properties: {
        imageOpacity: this.getValueOrDefault(raw.properties.imageOpacity, this.DefaultValues.node.properties.imageOpacity),
        opacity: this.getValueOrDefault(raw.properties.opacity, this.DefaultValues.node.properties.opacity),
        textValign: raw.properties.textValign || '',
        textHalign: raw.properties.textHalign || '',
        label: this.getValueOrDefault(raw.properties.label, this.DefaultValues.node.properties.label),
        textRotation: raw.properties.textRotation || '',
        textMarginX: this.getValueOrDefault(raw.properties.textMarginX, this.DefaultValues.node.properties.textMarginX),
        textMarginY: this.getValueOrDefault(raw.properties.textMarginY, this.DefaultValues.node.properties.textMarginY),
        textFontSize: raw.properties.textFontSize || '0',
        textFontColor: raw.properties.textFontColor || '',
        scale: this.getScale(raw.properties.scale)
      },
      // @ts-ignore
      originalNode: this.focusedNode.originalNode,
      states: this.focusedNode.states,
      metadata: metadataFormData.map((obj: any) => {
        if (obj.id === '') {
          const {id, ...rest} = obj;
          return rest;
        } else {
          return obj;
        }
      })
    };

    this.focusedNode = {...this.focusedNode, ...node};
    this.focusedNode.metadata = orderBy(this.focusedNode.metadata ? [...this.focusedNode.metadata] : [], ['order'], ['asc']);
    node.metadata = this.focusedNode.metadata;
    this.updateNodeEvent.emit(node);
  }

  private getScale(scaleValue: any): number {
    return scaleValue?.toString() === '0' ? 0 : scaleValue || DefaultValues.node.properties.scale;
  }

  private getValueOrDefault(value: any, defaultValue: any): any {
    return value !== undefined && value !== null ? value : defaultValue;
  }
  protected readonly metadata = metadata;
  protected readonly PACKAGE_NAMES = PACKAGE_NAMES;
}

interface ExtendedNode extends GraphNode{
  isNew?: boolean;
}

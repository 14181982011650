import { Component, Input } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { SelectInputComponent } from '../../../shared/forms/select-input/select-input.component';
import { DefaultValues, MODULE_NAMES } from '@maporium-workspace/shared';
import { HasModuleDirective } from '../../../packages/has-module.directive';
import { GraphNodeLink } from '../../../api/interfaces/graph-node.interface';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
  template: `
    <ng-container [formGroup]="formGroup">
      <app-select-input formControlName="label"
                        [label]="'label'"
                        [isIcon]="true"
                        [noIcons]="true"
                        [truncate]="true"
                        [metadata]="metadata"
                        *appHasModule="MODULE_NAMES.TAG_BINDING"
                        [placeholder]="'LINKS.SET_LABEL' | translate">
      </app-select-input>
    </ng-container>
  `,
  selector: 'maporium-link-style-advanced',
  standalone: true,
  imports: [
    FormsModule,
    MatButtonModule,
    MatDividerModule,
    ReactiveFormsModule,
    SelectInputComponent,
    HasModuleDirective,
    TranslatePipe
  ]
})
export class LinkStyleAdvancedComponent {
  protected readonly MODULE_NAMES = MODULE_NAMES;
  protected readonly DefaultValues = DefaultValues;

  @Input() formGroup: any;
  @Input() metadata: any;
  @Input() focusedLink: GraphNodeLink | undefined;

  toNumber(value: any) {
    return Number(value || 0);
  }

}

import { AfterViewInit, Component, Input } from '@angular/core';

@Component({
  selector: 'app-loading-animation',
  standalone: true,
  template: `<div class="loading-container">
    <canvas #canvasElement></canvas>
  </div>`,
  styles: [`
    .loading-container {
      display: flex;
      justify-content: center;
      align-items: center;
      position: var(--loading-animation-position, relative); /* Default to relative */
      width: var(--loading-animation-size, auto);
      height: var(--loading-animation-size, auto);
      transition: opacity 0.5s ease-in-out;
      opacity: 0.5;
    }
    canvas {
      display: block;
      border: 1px solid transparent; /* Invisible border for aesthetics */
    }
  `]
})
export class LoadingAnimationComponent implements AfterViewInit {
  @Input() size: number = 100; // Default size for the loading animation
  @Input() position: 'fixed' | 'absolute' | 'relative' = 'relative'; // Default position

  private ctx!: CanvasRenderingContext2D;
  private angle: number = 0;

  ngAfterViewInit(): void {
    const canvas = document.querySelector('canvas') as HTMLCanvasElement;
    canvas.width = this.size;  // Set canvas width
    canvas.height = this.size; // Set canvas height
    this.ctx = canvas.getContext('2d')!;

    // Set CSS variables for dynamic positioning
    this.setPositioningStyles();

    this.animate();
  }

  private setPositioningStyles() {
    const hostElement = (document.querySelector('app-loading-animation') as HTMLElement);
    hostElement.style.setProperty('--loading-animation-position', this.position);
    hostElement.style.setProperty('--loading-animation-size', `${this.size}px`);
  }

  private drawLink(x1: number, y1: number, x2: number, y2: number, direction: number) {
    const lineLength = Math.hypot(x2 - x1, y2 - y1);
    const verticalOffset = direction * lineLength / 1.5; // Control distance between anchor points

    // Control point at the center, moved vertically for curvature
    const controlX = (x1 + x2) / 2;
    const controlY = (y1 + y2) / 2 + verticalOffset;

    // Draw the quadratic curve
    this.ctx.beginPath();
    this.ctx.moveTo(x1, y1);
    this.ctx.quadraticCurveTo(controlX, controlY, x2, y2);
    this.ctx.strokeStyle = 'black';
    this.ctx.lineWidth = 2;
    this.ctx.stroke();
  }

  private drawNode(x: number, y: number) {
    const outerRadius = this.size / 8;              // Outer circle radius proportional to size
    const innerCoverRadius = outerRadius - 1;       // Inner cover radius slightly smaller
    const nodeRadius = outerRadius - 10;            // Central node radius proportional

    // Draw outer circle
    this.ctx.beginPath();
    this.ctx.arc(x, y, outerRadius, 0, Math.PI * 2);
    this.ctx.lineWidth = 2.5;
    this.ctx.strokeStyle = 'black';
    this.ctx.stroke();

    // Draw inner cover
    this.ctx.beginPath();
    this.ctx.arc(x, y, innerCoverRadius, 0, Math.PI * 2);
    this.ctx.fillStyle = 'white'; // Cover circle color
    this.ctx.fill();

    // Draw black central node
    this.ctx.beginPath();
    this.ctx.arc(x, y, nodeRadius, 0, Math.PI * 2);
    this.ctx.fillStyle = 'black'; // Black central node
    this.ctx.fill();
  }

  private animate() {
    this.ctx.clearRect(0, 0, this.size, this.size);

    const orbitRadiusX = this.size / 3; // Adjust based on size
    const orbitRadiusY = this.size / 4; // Adjust based on size
    const centerX = this.size / 2;
    const centerY = this.size / 2;

    const node1X = centerX + orbitRadiusX * Math.cos(this.angle);
    const node1Y = centerY + orbitRadiusY * Math.sin(this.angle);
    const node2X = centerX + orbitRadiusX * Math.cos(this.angle + Math.PI);
    const node2Y = centerY + orbitRadiusY * Math.sin(this.angle + Math.PI);

    this.drawLink(node1X, node1Y, node2X, node2Y, 1);  // Link with upward offset
    this.drawLink(node2X, node2Y, node1X, node1Y, -1); // Link with downward offset

    this.drawNode(node1X, node1Y);
    this.drawNode(node2X, node2Y);

    this.angle += 0.03;

    requestAnimationFrame(() => this.animate());
  }
}

import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { BaseCustomInputComponent } from '../base-custom-input.component';
import { NgClass, NgIf } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AutoSelectInputDirective } from '../../directives/auto-select-input.directive';


@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-text-input',
  standalone: true,
  styles: [`
    input {
      outline: none;
      border: 1px solid rgb(118, 118, 118);
      background-color: white;
    }
    .hidden-div {
      width: auto;
      display: inline-block;
      visibility: hidden;
      position: fixed;
      overflow:auto;
    }
  `],
  template: `
    <div class="row align-items-center no-gutter" #inputWrapper [ngClass]="{'mt-2': !noTopMargin}">
      <div class="col-4 pe-1" *ngIf="label && !isIcon">
        {{label}}
      </div>
      <div class="col-2 justify-content-center align-items-center d-flex"
           *ngIf="label && isIcon"
           [matTooltip]="placeholder">
        <span class="material-symbols-rounded"><label>{{label}}</label></span>
      </div>
      <div [ngClass]="{'col-8': label !== undefined && label !== '' && !isIcon, 'col-10': label !== '' && isIcon, 'p-0': autoAdjust}">
        <input
          class="w-100 app-text-input"
          (blur)="hasBlured.emit($event)"
          type="text"
          (input)="adjustInputWidth()"
          autoSelect
          [autoSelectEnabled]="autoFocusAndSelect"
          [formControl]="formControl"
          [value]="value"/>
      </div>
    </div>
      <div *ngIf="autoAdjust" class="hidden-div" #hiddenDiv>
        {{value}}
      </div>
  `,
  imports: [FormsModule, ReactiveFormsModule, NgClass, NgIf, MatTooltipModule, AutoSelectInputDirective],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextInputComponent),
      multi: true,
    },
  ],
})
export class TextInputComponent extends BaseCustomInputComponent implements AfterViewInit{
  @Input() label = '';
  @Input() placeholder = '';
  @Input() value: string | number | undefined = '';
  @Input() isIcon = false;
  @Input() noTopMargin = false;
  @Input() autoFocusAndSelect = false;
  @Input() autoAdjust = false;
  @Input() autoSelect = false;
  @Output() hasBlured = new EventEmitter<any>();
  //@ts-ignore
  @ViewChild('inputWrapper', { static: false }) inputWrapper: ElementRef;
  //@ts-ignore
  @ViewChild('hiddenDiv', { static: false }) hiddenDiv: ElementRef;

  ngAfterViewInit(): void {
    if (this.autoAdjust) {
      //@ts-ignore
      const hiddenDiv = this.hiddenDiv.nativeElement as HTMLInputElement;
      //@ts-ignore
      this.inputWrapper.nativeElement.style.width = hiddenDiv.clientWidth + 5 +  'px';
    }
    if (this.autoSelect) {
      //@ts-ignore
      const input = this.inputWrapper.nativeElement.querySelector('input');
      input.select();
    }
  }

  adjustInputWidth() {
    if (!this.autoAdjust) {
      return;
    }

    const hiddenDiv = this.hiddenDiv.nativeElement as HTMLInputElement;
    hiddenDiv.innerText = this.formControl.value;
    this.inputWrapper.nativeElement.style.width = hiddenDiv.clientWidth + 5 + 'px';
  }
}

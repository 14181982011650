import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatListModule } from '@angular/material/list';
import { MatLineModule } from '@angular/material/core';
import { GraphMap, GraphNodeMapKey } from '../api/interfaces/graph-node.interface';
import { CommonModule } from '@angular/common';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { CreateNewMapDialogComponent } from './create-new-map-dialog.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MapService } from '../api/services/map.service';
import { UserService } from '../api/services/user.service';
import { Subscription, switchMap, timer } from 'rxjs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ImportMapDialogComponent } from './import-map.dialog.component';
import { PackageService } from '../api/services/package.service';
import { MODULE_NAMES } from '@maporium-workspace/shared';
import { ToursService } from '../api/services/tour.service';
import { MapDialogsService } from './map-dialogs.service';

@Component({
  selector: 'maporium-map-list',
  styleUrls: ['./map-list.bottomsheet.component.scss'],
  template: `
    <mat-nav-list class="list-wrapper">
      <a href="" mat-list-item *ngFor="let map of maps"
         [matTooltip]="map.description || ''"
         matTooltipPosition="right"
         (click)="selectMap(map); $event.preventDefault(); $event.stopPropagation()"
         class="d-flex justify-content-evenly p-0">
        <span class="row w-100 align-items-center m-0">
          <span class="col-2">
            <span *ngIf="map.isReadonly">
              <button mat-icon-button
                      [matTooltip]="map.owner.name + ' ' + (map.owner.lastName || '' )+ ' shared the smap with you as a viewer'">
                  <mat-icon class="material-symbols-rounded">visibility</mat-icon>
              </button>
            </span>
            <span *ngIf="!map.isReadonly">
              <ng-container *ngIf="map!.sharedWith!.length > 0; else noShared">
                <button mat-icon-button
                        [matTooltip]="getToolTip(map)">
                    <mat-icon class="material-symbols-rounded">group</mat-icon>
                </button>
              </ng-container>
              <ng-template #noShared>
                <button mat-icon-button
                        [matTooltip]="'You are the owner of the smap'">
                    <mat-icon class="material-symbols-rounded">person</mat-icon>
                </button>
              </ng-template>
            </span>

          </span>
          <span class="col-6 pe-5">
              <span matLine>{{ map.name }}</span>
          </span>
          <span class="col-4  d-flex justify-content-end">
            <button mat-icon-button
                    matTooltip="Edit the smap’s information"
                    [disabled]="map.isReadonly === true"
                    (click)="edit(map);$event.preventDefault();  $event.stopPropagation()">
              <mat-icon class="material-symbols-rounded">edit</mat-icon>
            </button>
            <button mat-icon-button
                    matTooltip="Create a copy of the smap"
                    [disabled]="map.isReadonly === true"
                    (click)="duplicate(map); $event.preventDefault();  $event.stopPropagation()">
              <mat-icon class="material-symbols-rounded">file_copy</mat-icon>
            </button>
            <button mat-icon-button
                    matTooltip="Export and download the smap"
                    [disabled]="map.isReadonly === true || !hasModuleExport"
                    (click)="download(map);$event.preventDefault(); $event.stopPropagation()">
              <mat-icon class="material-symbols-rounded">download</mat-icon>
            </button>
            <span *ngIf="!map.isReadonly">
                <button mat-icon-button
                        matTooltip="Delete the smap"
                        (click)="deleteConfirmation(map);$event.preventDefault();  $event.stopPropagation()">
                    <mat-icon class="material-symbols-rounded">delete</mat-icon>
                </button>
            </span>
            <span *ngIf="map.isReadonly">
              <button mat-icon-button
                      matTooltip="Remove your access to the smap"
                      (click)="unshareConfirmation(map);$event.preventDefault();  $event.stopPropagation()">
                  <mat-icon class="material-symbols-rounded">person_remove</mat-icon>
              </button>
            </span>
          </span>
        </span>
      </a>
    </mat-nav-list>
    <mat-divider></mat-divider>
    <div class="p-0 mt-1">
          <span class="row w-100 align-items-center m-0">
            <span class="col-6">
              <button matTooltip="Create a new smap constantly autosaved to the cloud"
                      mat-flat-button
                      (click)="createNew(); $event.preventDefault();">
              <mat-icon class="material-symbols-rounded">add_circle</mat-icon>
                Create Smap
              </button>
            </span>
            <span class="col-6 d-flex justify-content-end">
              <button matTooltip="Import a smap stored in a ZIP archive"
                      mat-flat-button
                      (click)="importMap(); $event.preventDefault();">
              <mat-icon class="material-symbols-rounded">publish</mat-icon>
                Import Smap
              </button>
            </span>
          </span>
    </div>
  `,

  standalone: true,
  imports: [
    MatListModule,
    CommonModule,
    MatLineModule,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
  ]
})

export class MapListBottomsheetComponent implements OnInit, OnDestroy {
  public maps: GraphMap[] = [];
  private subSink: Subscription = new Subscription();

  constructor(private matDialog: MatDialog,
              private mapService: MapService,
              private userService: UserService,
              private mapDialogsService: MapDialogsService,
              private packageService: PackageService,
              private tourService: ToursService,
              private bottomSheetRef: MatBottomSheetRef<MapListBottomsheetComponent>) {
  }

  ngOnInit() {
    this.subSink.add(timer(0, 10000)
      .pipe(
        switchMap(() => this.mapService.findAllForUser(this.userService.getCurrentUserFromStorage().id))
      )
      .subscribe((maps) => {
        this.maps = maps;
      }));
  }

  get hasModuleExport() {
    return this.packageService.hasModule(MODULE_NAMES.MAP_EXPORT)
  }



  ngOnDestroy() {
    this.subSink.unsubscribe();
  }

  selectMap(map: GraphMap) {
    this.tourService.activateTourSubject.next(null);
    this.bottomSheetRef.dismiss(map);
  }

  getToolTip(map: GraphMap) {
    return 'You are the owner of the smap, shared with ' + map?.sharedWith?.length + (map?.sharedWith?.length === 1 ? ' viewer' : ' viewers');
  }

  createNew() {
    const ref = this.matDialog.open(CreateNewMapDialogComponent, {width: '35%', autoFocus: 'input'});
    ref.afterClosed().subscribe((map: GraphMap) => {
      const newMap = {map, isNew: true};
      if (map) {
        this.tourService.activateTourSubject.next(null);
        this.bottomSheetRef.dismiss(newMap);
      }
    });
  }

  edit(map: GraphMap) {
    this.mapDialogsService.edit(map).subscribe((m: GraphMap) => {
      if (m) {
        // this.bottomSheetRef.dismiss(map);
        this.maps = this.maps.map((m) => {
          if (m.id === m.id) {
            return m;
          }
          return m;
        });
        if(this.mapService.getCurrentSelectedMapFromStore()?.id === m.id) {
          this.mapService.mapNameChangedSubject.next(m.name);
          this.mapService.setCurrentSelectedMapToStore(m);
        }
      }
    });
  }

  duplicate(map: GraphMap) {
    this.mapDialogsService.duplicate(map).subscribe((m: GraphMap) => {
      if (m) {
        this.tourService.activateTourSubject.next(null);
        //@ts-ignore
        m.isNew = true;
        this.bottomSheetRef.dismiss(m);
      }
    });
  }

  delete(map: GraphMap) {
    this.mapService.delete(map.id).subscribe((res) => {
      const storedMap = this.mapService.getCurrentSelectedMapFromStore();

      if (storedMap && storedMap.id === map.id) {
        this.mapService.removeCurrentSelectedMapFromStore();
      }
      const mapsWithoutDeleted = this.maps.filter((m) => m.id !== map.id);
      this.maps = mapsWithoutDeleted;
      if (mapsWithoutDeleted.length > 0) {
        this.mapService.mapChangedSubject.next(mapsWithoutDeleted[0]);
        this.mapService.setCurrentSelectedMapToStore(mapsWithoutDeleted[0])
      } else {
        this.mapService.mapChangedSubject.next(undefined);
        this.mapService.setCurrentSelectedMapToStore(undefined)

      }
    });
  }

  unshare(map: GraphMap) {
    this.mapService.unshareMap(map.id).subscribe((res) => {
      const currentMap = this.mapService.getCurrentSelectedMapFromStore();
      if (currentMap && currentMap.id === map.id) {
        this.mapService.removeCurrentSelectedMapFromStore();
      }
      const mapsWithoutDeleted = this.maps.filter((m) => m.id !== map.id);
      this.maps = mapsWithoutDeleted;
      if (mapsWithoutDeleted.length > 0) {
        //TODO: Subject is deprecated
        this.mapService.mapChangedSubject.next(mapsWithoutDeleted[0]);
        this.mapService.setCurrentSelectedMapToStore(mapsWithoutDeleted[0])
      } else {
        localStorage.removeItem(GraphNodeMapKey);
        this.mapService.mapChangedSubject.next(undefined);
        this.mapService.setCurrentSelectedMapToStore(undefined)
      }
    });
  }

  download(map: GraphMap) {
    this.mapDialogsService.download(map);
  }


  deleteConfirmation(map: GraphMap) {
    this.mapDialogsService.deleteConfirmation(map).subscribe((res) => {
      if (res) {
        this.delete(map);
      }
    });
  }

  unshareConfirmation(map: GraphMap) {
    this.mapDialogsService.unshareConfirmation(map).subscribe((res) => {
      if (res) {
        this.unshare(map);
      }
    });
  }

  importMap() {
    const ref = this.matDialog.open(ImportMapDialogComponent, {
      width: '35%',
      hasBackdrop: false,
      data: {}
    });
  }

}

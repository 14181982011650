import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GraphNodeLink } from '../../api/interfaces/graph-node.interface';
import { DatatifyDirective } from '../../shared/directives/datatify.directive';
import { MatTooltipModule } from '@angular/material/tooltip';
import { OrderByPipe } from '../../shared/pipes/orderBy.pipe';
import { MatDividerModule } from '@angular/material/divider';
import { MetaDataViewerComponent } from '../../shared/forms/meta-data-viewer/meta-data-viewer.componet';
import {
  TitleDescriptionInputComponent
} from '../../shared/forms/title-description-input/title-description-input.component';
import { CdkAccordion } from '@angular/cdk/accordion';
import {
  MaporiumAccordionItemComponent
} from '../../shared/components/maporium-accordion/maporium-accordion-item.component';
import { CommentsComponent } from '../../shared/components/comments/comments.component';
import { isInIframe } from '../../shared/helpers/maporium.validators';

@Component({
  selector: 'maporium-link-view',
  standalone: true,
  template: `
    <ng-container *ngIf="focusedLink">
      <maporium-title-description [isEditing]="false"
                                  [entityId]="focusedLink.id"
                                  [fields]="{title: focusedLink.name || '', description: focusedLink.description}">
      </maporium-title-description>

      @if (focusedLink.metadata?.length && isInIframe()) {
        <mat-divider class="mb-2 mt-2"></mat-divider>
      }

      <div class="mt-1">
        @if (!isInIframe()) {
          <cdk-accordion [multi]="true" class="accordion">
            <maporium-accordion-item [title]="'Properties'"
                                     [sections]="focusedLink.metadata?.length || 0">
              <maporium-meta-viewer [metadata]="focusedLink.metadata"></maporium-meta-viewer>
            </maporium-accordion-item>
            <maporium-accordion-item [title]="'Comments'" [sections]="commentsCount">
              <maporium-comments [entityId]="focusedLink!.id"
                                 (updated)="hasComments.emit($event)"
                                 (commentCount)="commentsCount = $event"
                                 [entityType]="'link'"
                                 [isEditing]="true"></maporium-comments>
            </maporium-accordion-item>
          </cdk-accordion>
        } @else {
          <maporium-meta-viewer [metadata]="focusedLink.metadata"></maporium-meta-viewer>
        }
      </div>
    </ng-container>
  `,
  imports: [
    CommonModule,
    DatatifyDirective,
    MatTooltipModule,
    OrderByPipe,
    MatDividerModule,
    MetaDataViewerComponent,
    TitleDescriptionInputComponent,
    CdkAccordion,
    MaporiumAccordionItemComponent,
    CommentsComponent
  ]
})
export class LinkViewComponent {
  @Input() focusedLink: GraphNodeLink | undefined;
  @Output() hasComments = new EventEmitter<boolean>();
  commentsCount = 0;

  get metadata(): any[]{
    return this.focusedLink?.metadata || [];
  }

  protected readonly isInIframe = isInIframe;
}

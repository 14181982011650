import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostListener,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren
} from '@angular/core';
import {
  ControlValueAccessor,
  FormArray,
  FormBuilder,
  FormGroup,
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule
} from '@angular/forms';
import { CommonModule } from '@angular/common';
import { CdkDrag, CdkDragDrop, CdkDragHandle, CdkDropList } from '@angular/cdk/drag-drop';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AutoSelectInputDirective } from '../../directives/auto-select-input.directive';
import { MetaData } from '../../../api/interfaces/metadata';
import { MatDividerModule } from '@angular/material/divider';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';
import { AutoResizeInputDirective } from '../../directives/auto-resize-input.directive';
import { HasPackageDirective } from '../../../packages/has-package.directive';
import { PACKAGE_NAMES } from '@maporium-workspace/shared';
import { TranslatePipe } from '@ngx-translate/core';


@Component({
  selector: 'maporium-meta-data-control',
  templateUrl: './meta-data-control.component.html',
  styleUrls: ['./meta-data-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MetaDataControlComponent),
      multi: true
    },
  ],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    CdkDropList,
    CdkDrag,
    CdkDragHandle,
    MatTooltipModule,
    AutoSelectInputDirective,
    MatDividerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    AutoResizeInputDirective,
    HasPackageDirective,
    TranslatePipe
  ]
})
export class MetaDataControlComponent implements OnInit, ControlValueAccessor {
  @Input() sorting = false;
  @Output() addMeta = new EventEmitter<FormGroup>;
  @Output() extendMeta = new EventEmitter<void>();

  //@ts-ignore
  keyValueForm: FormGroup;

  selectedRowIndex: number | null = null;
  packageNames = PACKAGE_NAMES;

  @ViewChildren('txtAreaKey') txtAreaKeys!: QueryList<ElementRef>;
  @ViewChildren('txtAreaValue') txtAreaValues!: QueryList<ElementRef>;
  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    const targetElement = event.target as HTMLElement;

    // Check if clicked outside of any of the rows
    if (!targetElement.closest('.pseudo-row')) {
      this.selectedRowIndex = null;
    }
  }


  get pairs(): FormArray {
    return this.keyValueForm.get('pairs') as FormArray;
  }

  constructor(private fb: FormBuilder) {
  }


  ngOnInit(): void {
    this.keyValueForm = this.fb.group({
      pairs: this.fb.array([])
    });
  }

  selectRow(index: number, $event: MouseEvent, isEditable = true): void {
    if (isEditable === false) {
      return;
    }
    this.selectedRowIndex = index;
    $event.stopPropagation();
  }

  drop(event: CdkDragDrop<string[]>) {
    //TODO : Need order index on server side
    const controls = this.pairs.controls;
    const draggedControl = controls[event.previousIndex];
    controls.splice(event.previousIndex, 1);
    controls.splice(event.currentIndex, 0, draggedControl);
    this.pairs.updateValueAndValidity();
    this.selectedRowIndex = event.currentIndex;
  }

  addPair(): void {
    const meta = {
      id: undefined,
      key: '',
      value: '',
      type: 'string',
      order: this.pairs.length,
      editable: true
    };
    let pair;

    if (this.selectedRowIndex !== null) {
      // Determine the insertion index
      const index = this.selectedRowIndex - 1 === -1 ? 0 : this.selectedRowIndex;
      // Set the order for the new meta object
      meta.order = index + 1;
      pair = this.fb.group(meta);
      // Insert the new pair at the correct position
      this.pairs.insert(index + 1, pair);

      // Update the order of subsequent elements
      for (let i = index + 2; i < this.pairs.length; i++) {
        this.pairs.at(i).patchValue({ order: i });
      }

      this.handleAutoFocus(index + 1);
    } else {
      // If no row is selected, just append to the end
      meta.order = this.pairs.length;
      pair = this.fb.group(meta);
      this.pairs.push(pair);
      this.handleAutoFocus(this.pairs.length - 1);
    }
    this.addMeta.emit(pair);
  }


  removePair(): void {
    if (this.selectedRowIndex !== null) {
      this.pairs.removeAt(this.selectedRowIndex);
      this.selectedRowIndex = null;
      this.pairs.markAsTouched();
    }
  }

  extendExistingMetaWithValue(): void {
    this.extendMeta.emit();
  }

  writeValue(pairs: MetaData[] | {pairs: MetaData[]}): void {
    let pairsArray: MetaData[];
    if (Array.isArray(pairs)) {
      pairsArray = pairs;
    } else {
      pairsArray = pairs.pairs;
    }

    const pairFGs = pairsArray.map(pair => this.fb.group(pair));
    const pairsFormArray = this.fb.array(pairFGs);
    this.keyValueForm.setControl('pairs', pairsFormArray);
  }

  registerOnChange(fn: any): void {
    this.keyValueForm.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    // Implement if needed
  }

  onEnter($event: KeyboardEvent): void {
    if ($event.key === 'Enter' || $event.keyCode === 13) {
      const target = $event.target as HTMLInputElement;
      if (target.classList.contains('key-input')) {
        // its a key dont do antything
        $event.preventDefault();
        return;
      }
    }
  }

  onTab($event: any, rowIndex: number, fieldType: string, isEditable = true): void {
    if (!isEditable) {
      return;
    }
    // If tabbing from the key field, just update the selected row.
    if (fieldType === 'key') {
      this.selectRow(rowIndex, $event);
      return;
    }

    // If tabbing from the value field and it's not the last row, go to the key field of the next row.
    if (fieldType === 'value' && rowIndex < this.pairs.length - 1) {
      $event.preventDefault(); // prevent default tabbing behavior.

      this.selectRow(rowIndex + 1, $event);

      setTimeout(() => {
        const nextKeyInput = document.getElementsByClassName('meta-key')[rowIndex + 1] as HTMLInputElement;
        nextKeyInput.focus();
      });
    }
  }


  handleAutoFocus(index: number): void {
    setTimeout(() => {
      this.selectRow(index, new MouseEvent('click'));
      // focus the key input
      const keyInput = document.getElementsByClassName('meta-key')[index] as HTMLInputElement;
      keyInput.focus();
    }, 100);
  }

}

import { Injectable } from '@angular/core';
import { BehaviorSubject, tap } from 'rxjs';
import { TourResource } from '../resource/tour.resource';
import { MapTour, TourStep } from '../interfaces/map-tour';
import { CytoscapeService } from '../../services/cy-services/cytoscape.service';
import { ViewService } from './view.service';
import { MapService } from './map.service';
import { PackageService } from './package.service';
import { MODULE_NAMES } from '@maporium-workspace/shared';
import { invalidatePanZoomForCollapsedNode } from '../../../cytoscape-extensions/pan-zoom-storage';
import { isInIframe } from '../../shared/helpers/maporium.validators';

@Injectable({providedIn: 'root'})
export class ToursService {
  //@ts-ignore
  public activateTourSubject: BehaviorSubject<MapTour | null> = new BehaviorSubject(null);
  constructor(private resource: TourResource,
              private cyService: CytoscapeService,
              private mapService: MapService,
              private packageService: PackageService,
              private savedViewService: ViewService) {
  }

  public getAll(mapId: string) {
    return this.resource.getAll(mapId);
  }

  getAllPublic(mapId: string) {
    return this.resource.getAllPublic(mapId);
  }

  public getOne(id: string) {
    return this.resource.getOne(id);
  }

  public save(mapTour: MapTour) {
    return this.resource.create(mapTour)
      .pipe(
        tap(() => CytoscapeService.menuChangedSubject.next())
      );
  }

  public update(mapTour: MapTour) {
    return this.resource.update(mapTour).pipe(
      tap(() => CytoscapeService.menuChangedSubject.next())
    );
  }

  public delete(mapTour: MapTour) {
    return this.resource.delete(mapTour.id as string).pipe(
      tap(() => CytoscapeService.menuChangedSubject.next())
    );
  }


  public playTour(mapTour: MapTour) {
    invalidatePanZoomForCollapsedNode();
    const cy = this.cyService.cy;
    if (!cy) {
      return;
    }

    this.activateTourSubject.next(mapTour);
  }

  public async getTourCtxMenuItems() {
    const mapId = this.mapService.getCurrentSelectedMapFromStore().id;
    if (!mapId) {
      return null;
    }

    const tours = isInIframe() ?
      await this.getAllPublic(mapId).toPromise() :
      await this.getAll(mapId).toPromise();

    if (!tours || tours.length === 0) {
      return null;
    }

    const subMenuWithTours = tours.map((tour) => {
      return {
        id: tour.id,
        content: tour.name,
        tooltipText: tour.description,
        onClickFunction: (event: any) => {
          this.activateTourSubject.next(tour);
        },
      };
    });

    return {
      id: 'tours_menu',
      content: 'Play Story',
      // tooltipText: 'Play Story',
      image: {src: 'assets/mat-symbols/outlined/play_circle.svg', width: 12, height: 12, x: 5, y: 6},
      coreAsWell: true,
      submenu: subMenuWithTours,
    };
  }

  private async animateStep(step: TourStep) {
    const cy = this.cyService.cy;
    if (!cy) {
      return;
    }

    const view = step.savedView;

    this.savedViewService.playView(view, step);
  }
}

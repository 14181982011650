import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { Injectable } from '@angular/core';
import { Core } from 'cytoscape';
import { MapService } from '../api/services/map.service';
import { GraphNode, GraphNodeLink } from '../api/interfaces/graph-node.interface';
import { NodeService } from '../api/services/node.service';
import { assign, first, omit, size, uniqBy } from 'lodash';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { CytoscapeService } from './cy-services/cytoscape.service';
import { UndoRedoService } from './undo-redo.service';
import { UserService } from '../api/services/user.service';
import { MetaData } from '../api/interfaces/metadata';
import { updateInProgress } from '../shared/helpers/signal.helpers';

@Injectable({
  providedIn: 'root'
})
export class ButtonPressService {
  public static isShiftPressed = false;
  private ctrlKeyPressed: boolean;
  private altKeyPressed: boolean;
  private copyStream: Subject<void>;
  // @ts-ignore
  private nodeCreationStream: BehaviorSubject<GraphNode[]> = new BehaviorSubject<GraphNode[]>(null);
  private pasteStream = new Subject<KeyboardEvent>();
  private deleteStream: Subject<void> = new Subject<void>();
  private selectAllStream: Subject<void> = new Subject<void>();
  private cutStream: Subject<void> = new Subject<void>();

  private mousePosition = {x: 0, y: 0};
  private subs = new Subscription();

  constructor(private mapService: MapService,
              private userService: UserService,
              private cyService: CytoscapeService,
              private undoService: UndoRedoService,
              private nodeService: NodeService) {
    this.ctrlKeyPressed = false;
    this.altKeyPressed = false;
    this.copyStream = new Subject<void>();
    this.addAllSubs();
  }

  public init(cy: Core) {

    localStorage.removeItem('clipboard');
    document.addEventListener('keydown', this.handleKeyDownEvent);
    document.addEventListener('keyup', this.handleKeyUpEvent);
    setTimeout(() => {
      if (this.cyService.cy) {
        this.cyService.cy.on('mousemove', this.trackMousePosition);
      }
    }, 500);
    this.addAllSubs();
    this.handleKeyEvents();
    return this.nodeCreationStream.asObservable();
  }

  public getNodeCreation() {
    return this.nodeCreationStream.asObservable();
  }

  public initUndoRedo(cy: Core & {undoRedo: () => any}) {
    document.addEventListener('keydown', function(event) {
      // Checking if the Command key is pressed on macOS or Control key on Windows/Linux
      const modifierKey = event.ctrlKey || (event.metaKey && navigator.platform.toUpperCase().indexOf('MAC') >= 0) || event.metaKey;

      if (modifierKey) {
        // Undo
        if (event.key === 'z' || event.key === 'Z') {
          cy.undoRedo().undo();
        }

        // Redo for Windows/Linux
        if (event.ctrlKey && (event.key === 'y' || event.key === 'Y')) {
          cy.undoRedo().redo();
        }

        // Redo for macOS
        if (event.metaKey && event.shiftKey && (event.key === 'z' || event.key === 'Z')) {
          cy.undoRedo().redo();
        }
      }
    });
  }

  public copyFromClipboard(parent?: GraphNode | null) {
    if (!parent) {
      parent = DashboardComponent.tappedNode;
    }
    if (document.activeElement !== document.body) return;
    const clipboard = localStorage.getItem('clipboard');
    if (clipboard === null) {
      return;
    } else {
      const mousePosition = this.getPosition();
      const elements = JSON.parse(clipboard as string);
      const nodes = elements.filter((element: any) => element.group === 'nodes');
      const nPos = nodes.map((e: any) => e.position);
      const target_X = mousePosition.x;
      const target_Y = mousePosition.y;

    const centerOfMass_X = nPos.reduce((sum: any, pos: { x: number, y: number }) => sum + pos.x, 0) / nPos.length;
    const centerOfMass_Y = nPos.reduce((sum: any, pos: { x: number, y: number }) => sum + pos.y, 0) / nPos.length;

    const relativePositions = nPos.map((pos: { x: number, y: number }) => ({
      x: pos.x - centerOfMass_X,
      y: pos.y - centerOfMass_Y,
    }));

    const newPos: { x: number, y: number }[] = relativePositions.map((relativePos: { x: number, y: number }) => ({
      x: target_X + relativePos.x,
      y: target_Y + relativePos.y,
    }));

      const newNodes = nodes.map((e: any, index: number) => {
        return {...e.data, position: newPos[index]};
      }) as GraphNode[];
      const currentMap = this.mapService.getCurrentSelectedMapFromStore();
      // omit id for all metadata
      newNodes.forEach((n) => {
        if (n.metadata) {
          n.metadata = n.metadata.map((m) => omit(m, ['id']) as MetaData);
        }
      });
      this.mapService.copyToMap(newNodes, currentMap.id, parent).subscribe((res) => {
        this.nodeCreationStream.next(res);
        const toFocus = first(res) as GraphNode;
        const cy = this.cyService.cy;
        if (!cy) return;
        const cyElementsIds = res.map((e) => e.id);
        cy.elements(':selected').unselect();
        DashboardComponent.tappedNode = null;
        DashboardComponent.tappedLink = null;

        res.forEach((e) => {
          if (size(e.links) > 0) {
            e.links?.forEach((link) => {
              cyElementsIds.push(link.id);
            });
          }
        });

        // iterate with delay and select
        cyElementsIds.forEach((id, index) => {
          setTimeout(() => {
            cy.$(`#${id}`).select();
          }, index * 50);
        });
        DashboardComponent.tappedNode = toFocus;
        cy.$(`#${toFocus.id}`).select();
      });
    }
  }
  private handleKeyEvents( ) {
    document.addEventListener('keydown', (event) => {
      if (event.key === 'Shift') {
        ButtonPressService.isShiftPressed = true;
      }
    });

    document.addEventListener('keyup', (event) => {
      if (event.key === 'Shift') {
        ButtonPressService.isShiftPressed = false;
      }
    });
  }

  private handleKeyDownEvent = (event: KeyboardEvent) => {
    if (event.altKey && event.key === 'a') {
      this.selectAllStream.next();
    } else if ((event.ctrlKey || event.metaKey) && event.key === 'x') {
      this.cutStream.next();
    } else if ((event.ctrlKey || event.metaKey) && event.key === 'c') {
      this.copyStream.next();
    } else if ((event.ctrlKey || event.metaKey) && event.key === 'v') {
      const parent = DashboardComponent.tappedNode;
      this.pasteStream.next(event);
    } else if (event.key === 'Control' || event.key === 'Meta') {
      this.ctrlKeyPressed = true;
      document.documentElement.style.cursor = 'grab';
    } else if (event.key === 'Delete' || event.key === 'Backspace') {
      if (document.activeElement !== document.body) return;
      this.deleteStream.next();
    } else if (event.key === 'Alt') {
      this.altKeyPressed = true;
    }
  };

  private handleKeyUpEvent = (event: KeyboardEvent) => {
    if (event.key === 'Control' || event.metaKey) {
      this.ctrlKeyPressed = false;
      document.documentElement.style.cursor = 'auto';
    }
  };

  private trackMousePosition = (event: any) => {
    this.mousePosition = event.position;
  };

  private deleteSelected(cy: Core) {
    const currentMap = this.mapService.getCurrentSelectedMapFromStore();
    const currentUser = this.userService.getCurrentUserFromStorage();
    if (currentMap?.owner?.id !== currentUser?.id) {
      return;
    }
    if (document.activeElement !== document.body) return;
    const allSelected = cy.$(':selected');
    const nodes = cy.$('node:selected');
    const edges = cy.$('edge:selected');

    this.undoService.emitRemove({ ids: [...nodes.map((n: { data: () => any; }) => n.data().id), ...edges.map((e) => e.data('id'))] });
    // @ts-ignore
    for (const node of nodes) {
      this.nodeService.deleteNode(node.data('id'))
        .subscribe(() => {
          node.addClass('display-none');
          node.move({ parent: null });
        });
    }
    // @ts-ignore
    for (const edge of edges) {
      edge.data('deleted', true);
      this.nodeService.deleteLink(edge.id())
        .subscribe(() => {
          edge.addClass('display-none');
          const remainingLinks = cy.edges().filter((e) => !e.data('deleted'));
          remainingLinks.style('control-point-distances', '');
          cy.resize();
        });
    }

    setTimeout(() => {
      DashboardComponent.tappedNode = null;
      DashboardComponent.tappedLink = null;
      nodes.data('selected', false);
      edges.data('selected', false);
      nodes.removeClass('highlighted');
      edges.removeClass('highlighted');

      allSelected.unselect();
    }, 300)

    let attempts = 0;
    const interval = setInterval(() => {
      if (updateInProgress()) {
        if (attempts > 2) {
          updateInProgress.set(false);
          clearInterval(interval);
        }
        attempts++;
      }
    }, 1000);
  }

  private addAllSubs() {
    const cy = this.cyService.cy;
    if (!cy) return;

    this.subs.add(
      this.getCopyStream().subscribe(() => this.copySelected(cy))
    );

    this.subs.add(
      this.getPasteStream().subscribe((event) => {
        this.copyFromClipboard();
      })
    );

    this.subs.add(
      this.getDeleteStream().subscribe(() => this.deleteSelected(cy))
    );

    this.subs.add(
      this.getSelectAllStream().subscribe(() => this.selectAll(cy))
    );

    this.subs.add(
      this.getCutStream().subscribe(() => this.cutSelected(cy))
    );
  }

  private selectAll(cy: Core) {
    cy.elements().select();
  }

  public copySelected(cy: Core) {
    const cyElements: any[] = [];
    const copyNodesRecursive = (node: any) => {
      if (node.data.collapsed) {
        cyElements.push(...node.data.collapsedChildren.jsons());
        delete node.data.collapsedChildren;
      }
      if (node.data.children) {
        //Find Cy elements for children
        const children = cy.$(`#${node.data.id}`).children();
        cyElements.push(...children.jsons());
        children.jsons().forEach((child: any) => {
          copyNodesRecursive(child);
        });
      }
      cyElements.push(node);
    }
    const toCopy = cy.$(':selected');

    toCopy.filter('node').forEach((e: any) => {
      let links = e.data('links');

      if (!links || links.length === 0) {
        // Fallback to find links in Cytoscape elements
        links = [];
        cy.edges().forEach((edge) => {
          if (edge.source().id() === e.id() || edge.target().id() === e.id()) {
            links.push(edge.data());
          }
        });
      }

      if (links.length > 0) {
        links.forEach((link: GraphNodeLink, index: number) => {
          const linkElement = cy.$(`#${link.id}`);
          links[index] = assign({}, link, linkElement.data());
        });
      }

    });

    // If we have in toCopy collapsed nodes , we have to remove collapsed related data so we dont run circular deps
    toCopy.jsons().forEach((e: any) => {
      copyNodesRecursive(e);
    });
    const clipboardPayload = uniqBy(cyElements.filter(e => e.group === 'nodes'), 'data.id');
    localStorage.setItem('clipboard', JSON.stringify(clipboardPayload.map((e) => omit(e, ['data.collapsedChildren']))));
  }

  public cutSelected(cy: Core) {
    this.copySelected(cy);
    this.deleteSelected(cy);
  }

  private getCopyStream() {
    return this.copyStream.asObservable();
  }

  public getDeleteStream() {
    return this.deleteStream.asObservable();
  }

  private getPasteStream() {
    return this.pasteStream.asObservable();
  }

  private getPosition() {
    return this.mousePosition;
  }

  private getSelectAllStream() {
    return this.selectAllStream.asObservable();
  }

  private getCutStream() {
    return this.cutStream.asObservable();
  }

  ngOnDestroy() {
    document.removeEventListener('keydown', this.handleKeyDownEvent);
    document.removeEventListener('keyup', this.handleKeyUpEvent);
    this.subs.unsubscribe();
  }
}

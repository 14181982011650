import { Injectable, signal } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class AppWideStateService {
  private readModeSignal = signal(false);
  public canvasLoadedSignal = signal(false);

  private pendingTimeout: ReturnType<typeof setTimeout> | null = null;
  private lastFalseTime: number | null = null; // Tracks when the loader was last shown
  private readonly MIN_VISIBLE_TIME = 1000; // Default minimum visible time in ms

  get readMode() {
    return this.readModeSignal.asReadonly();
  }

  setReadMode(value: boolean) {
    this.readModeSignal.set(value);
  }

  getCanvasLoaded() {
    return this.canvasLoadedSignal();
  }

  setCanvasLoaded(value: boolean, delay?: number) {
    const now = Date.now();
    const minDelay = delay !== undefined ? delay : this.MIN_VISIBLE_TIME;

    if (this.pendingTimeout) {
      clearTimeout(this.pendingTimeout);
      this.pendingTimeout = null;
    }
    if (value === false) {
      this.lastFalseTime = now; // Record when the loader is shown
      this.canvasLoadedSignal.set(false); // Show loader immediately
    } else if (this.lastFalseTime !== null) {
      const elapsedSinceFalse = now - this.lastFalseTime;

      if (elapsedSinceFalse < minDelay) {
        const remainingTime = minDelay - elapsedSinceFalse;

        this.pendingTimeout = setTimeout(() => {
          this.canvasLoadedSignal.set(true);
          this.pendingTimeout = null;
          this.lastFalseTime = null; // Reset after transitioning to true
        }, remainingTime);
      } else {
        this.canvasLoadedSignal.set(true);
        this.lastFalseTime = null; // Reset after transitioning to true
      }
    } else {
      this.canvasLoadedSignal.set(true); // No enforced delay, transition immediately
    }
  }
}

import { Injectable, NgZone } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Job } from '../interfaces/jobs.dto';

@Injectable({
  providedIn: 'root'
})
export class JobResource {
  private eventSource: EventSource | null = null;
  private baseUrl = environment.apiUrl + '/jobs';

  constructor(private http: HttpClient, private zone: NgZone) {
  }

  /**
   * Fetch a job by its ID.
   * @param jobId - The ID of the job to fetch.
   * @returns Observable of the job data.
   */
  getJob(jobId: string) {
    return this.http.get<Job>(`${this.baseUrl}/${jobId}`);
  }

  /**
   * Connect to the SSE endpoint for job updates.
   * @param onMessage - Callback to handle received messages.
   * @param onError - Callback to handle errors.
   */
  connectToSse(
    onMessage: (data: Job) => void,
    onError: (error: any) => void
  ): void {
    if (this.eventSource) {
      this.closeSse();
    }

    const token = localStorage.getItem('accessToken');
    if (!token) {
      if (this.eventSource) {
        this.closeSse();
      }
      console.error('No access token available for SSE connection.');
      return;
    }

    this.eventSource = new EventSource(`${this.baseUrl}/events?token=${token}`);


    // Wrap in NgZone to trigger Angular change detection
    this.eventSource.onmessage = (event) => {
      this.zone.run(() => onMessage(JSON.parse(event.data)));
    };

    this.eventSource.onerror = (error) => {
      this.zone.run(() => {
        onError(error);
        this.closeSse();
      });
    };
  }

  /**
   * Close the SSE connection.
   */
  closeSse(): void {
    if (this.eventSource) {
      this.eventSource.close();
      this.eventSource = null;
    }
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { ColorSketchModule } from 'ngx-color/sketch';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MapService } from '../api/services/map.service';
import { CytoscapeService } from '../services/cy-services/cytoscape.service';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ToursService } from '../api/services/tour.service';
import {
  CdkDrag,
  CdkDragDrop,
  CdkDragHandle,
  CdkDropList,
  CdkDropListGroup,
  moveItemInArray
} from '@angular/cdk/drag-drop';

import { ViewService } from '../api/services/view.service';
import { MatExpansionModule } from '@angular/material/expansion';
import { MapTour, TourStep } from '../api/interfaces/map-tour';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TextInputComponent } from '../shared/forms/text-input/text-input.component';
import { SelectInputComponent } from '../shared/forms/select-input/select-input.component';
import { SliderInputComponent } from '../shared/forms/slider-input/slider-input.component';
import { orderBy } from 'lodash';
import { TextAreaComponent } from '../shared/forms/text-area/text-area-input.component';
import { SlideToggleInputComponent } from '../shared/forms/slide-select/slide-toggle-input.component';
import { isInIframe } from '../shared/helpers/maporium.validators';

@Component({
  selector: 'maporium-new-map-dialog',
  styleUrls: ['./create-new-tour-dialog.component.scss'],
  template: `
    <div class="w-100 d-flex justify-content-between align-items-center dialog-header">
      <h2 class="align-items-center d-flex m-0 p-0"><span class="material-symbols-rounded me-2">{{data ? 'edit' : 'add_circle'}}</span>
        {{data ? 'Update Story' : 'Create Story'}}
      </h2>
      <mat-icon class="material-symbols-rounded close-button"  mat-dialog-close matTooltip="Close the panel">
        close
      </mat-icon>
    </div>

    <div mat-dialog-content>
      <div cdkDropListGroup>
        <form [formGroup]="myForm" class="mb-3">
          <app-text-input [label]="'Title'" formControlName="name" [autoFocusAndSelect]="true"></app-text-input>
          <app-text-area [label]="'Description'" formControlName="description"></app-text-area>
          <app-slide-toggle-input [label]="'visibility_off'"
                                  [isIcon]="true"
                                  matTooltip="Prevent viewers of this smap from seeing this story"
                                  [tooltip]="'Hide from smap viewers'"
                                  formControlName="excludeFromReadOnly"></app-slide-toggle-input>
        </form>
        <div class="tours-container">
          <h6>Steps</h6>
          <div cdkDropList [cdkDropListData]="items" class="tours-list" id="tours_steps"
               (cdkDropListDropped)="drop($event)">
<!-- This is the container with dropped Views. -->

            <ng-container *ngIf="items.length > 0; else placeholder">
              <mat-expansion-panel *ngFor="let item of items; let i = index" class="m-1" cdkDrag>
                <mat-expansion-panel-header cdkDragHandle>
                  {{ item.savedView.name }}
                </mat-expansion-panel-header>
                <ng-template matExpansionPanelContent>
                  <app-slider-input [label]="'Transition Duration'"
                                    [formControl]="itemFormControls[i].controls.animationSpeed"
                                    [min]="0.1"
                                    [max]="5"
                                    [step]="0.1"
                                    matTooltip="Set the time, in seconds, that it takes to transition to the view"></app-slider-input>

                  <app-select-input [label]="'Transition Style'"
                                    matTooltip="Set the style of animation used to transition to the view"
                                    [formControl]="itemFormControls[i].controls.animationCurve"
                                    [options]="animationCurves"></app-select-input>

                  <app-slider-input [label]="'Scene Duration'"
                                    [formControl]="itemFormControls[i].controls.pauseDuration"
                                    [min]="0.1"
                                    [max]="5"
                                    [step]="0.1"
                                    matTooltip="Set the time, in seconds, that the story waits before transitioning to the next view"></app-slider-input>
                </ng-template>
              </mat-expansion-panel>
            </ng-container>
            <ng-template #placeholder>
              <div class="placeholder-text">
                Drag scenes here then reorder them as desired to define the story’s sequence of steps.
              </div>
            </ng-template>
          </div>
        </div>

<!-- This is the list of all available views. Drag views from the list on the right to define the tour’s sequence of steps.&ndash;&gt;-->
        <div class="tours-container">
          <h6>Scenes</h6>
          <div
            cdkDropList
            [cdkDropListData]="savedViews"
            class="tours-list"
            (cdkDropListDropped)="drop($event)">
            <div class="tours-box" *ngFor="let item of savedViews"
                 matTooltipPosition="right"
                 [matTooltip]="item.savedView.description ? item.savedView.description : item.savedView.name"
                 cdkDrag>{{item.savedView.name}}</div>
          </div>
        </div>
      </div>

    </div>
    <div mat-dialog-actions class="d-flex justify-content-evenly">
      <button mat-button mat-dialog-close>Cancel</button>
      <button mat-button
              [disabled]="!myForm.valid"
              (click)="data ? edit() : create()"
              color="accent">{{data ? 'Update' : 'Create'}}</button>
    </div>
  `,
  imports: [
    MatDialogModule,
    MatInputModule,
    MatButtonModule,
    ColorSketchModule,
    CommonModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatCheckboxModule,
    CdkDropListGroup,
    CdkDropList,
    CdkDrag,
    MatExpansionModule,
    MatIconModule,
    MatTooltipModule,
    TextInputComponent,
    SelectInputComponent,
    SliderInputComponent,
    CdkDragHandle,
    TextAreaComponent,
    SlideToggleInputComponent
  ],
  standalone: true
})

export class CreateNewTourDialogComponent implements OnInit {
  myForm: FormGroup;
  items: TourStep[] = [];
  itemFormControls: FormGroup<{
    animationSpeed: FormControl,
    animationCurve: FormControl,
    pauseDuration: FormControl,
  }>[] = [];

  savedViews: TourStep[] = [];
  animationCurves = [
    {key: 'linear', value: 'Linear'},
    {key: 'ease', value: 'Ease'},
    {key: 'ease-in', value: 'Ease In'},
    {key: 'ease-out', value: 'Ease Out'},
    {key: 'ease-in-out', value: 'Ease In Out'},
  ]

  readonly secondsConverter = 0.001;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: MapTour,
    private matDialogRef: MatDialogRef<CreateNewTourDialogComponent>,
    private cyService: CytoscapeService,
    private savedViewService: ViewService,
    private mapService: MapService,
    private toursService: ToursService,
    private formBuilder: FormBuilder
  ) {
    this.myForm = this.formBuilder.group({
      name: ['', Validators.required],
      description: '',
      excludeFromReadOnly: false,
      includeSelected: false
    });

    this.items = this.data ? this.data.steps : [];
    (isInIframe() ? this.savedViewService.getAllPublic(this.mapService.getCurrentSelectedMapFromStore().id) : this.savedViewService.getAll(this.mapService.getCurrentSelectedMapFromStore().id))
      .subscribe((savedViews) => {
      const filteredViewsNotInTour = savedViews.filter((savedView) => {
        return !this.items.find((item) => {
          return item.savedView.id === savedView.id;
        });
      });
      this.savedViews = orderBy(filteredViewsNotInTour, v => v.name).map((savedView) => {
        return {
          animationSpeed: 0.5,
          animationCurve: 'ease-in-out',
          pauseDuration: 5,
          savedView: savedView
        };
      });
    });

    this.items.forEach(item => {
      this.itemFormControls.push(this.createItemFormGroup(item));
    });
  }

  ngOnInit(): void {
    if (this.data) {
      this.myForm.patchValue({
        name: this.data.name,
        description: this.data.description,
        excludeFromReadOnly: this.data.excludeFromReadOnly,
      });
    }

    this.items.forEach((item, index) => {
      this.itemFormControls[index].patchValue({
        animationSpeed: item.animationSpeed * this.secondsConverter || 0.5,
        animationCurve: item.animationCurve  || '',
        pauseDuration: item.pauseDuration * this.secondsConverter || 0
      });
    });
  }

  createItemFormGroup(itemData: any): FormGroup {
    return this.formBuilder.group({
      animationSpeed: itemData.animationSpeed || 0.5,
      animationCurve: itemData.animationCurve || 'linear',
      pauseDuration: itemData.pauseDuration || 0
    });
  }

  drop(event: CdkDragDrop<TourStep[]>) {
    if (event.container.data === null) {
      return;
    }
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      moveItemInArray(this.itemFormControls, event.previousIndex, event.currentIndex);
    } else {
      if (event.previousContainer.data === null) {
        return;
      }

      if (event.container.id === 'tours_steps') {
        // Remove the item from the source array
        const removedItem = event.previousContainer.data.splice(event.previousIndex, 1)[0];
        // Add the removed item to the target array at the specified index
        event.container.data.splice(event.currentIndex, 0, removedItem);

        this.itemFormControls.splice(event.currentIndex, 0, this.createItemFormGroup(removedItem));
      } else {
        // Remove the item from the source (tours_steps) container
        event.container.data.splice(event.currentIndex, 0, event.previousContainer.data[event.previousIndex]);
        event.previousContainer.data.splice(event.previousIndex, 1);
        // Add it to the target (savedViews) container
      }
    }
  }

  create() {
    if (this.myForm.valid) {
      const cy = this.cyService.cy;
      if (!cy) {
        return;
      }
      const items = this.items.map((item, index) => {
        const formGroup = this.itemFormControls[index];
        return {
          animationSpeed: formGroup.value.animationSpeed / this.secondsConverter,
          animationCurve: formGroup.value.animationCurve,
          pauseDuration: formGroup.value.pauseDuration / this.secondsConverter,
          savedView: item.savedView
        };
      });
      const payload: MapTour = {
        name: this.myForm.value.name,
        description: this.myForm.value.description,
        excludeFromReadOnly: this.myForm.value.excludeFromReadOnly,
        steps: items,
        map: this.mapService.getCurrentSelectedMapFromStore(),
      };
      this.toursService.save(payload).subscribe((tour) => {
        this.matDialogRef.close(tour);
      });
    }
  }

  edit() {
    if (this.myForm.valid) {
      const items = this.items.map((item, index) => {
        const formGroup = this.itemFormControls[index];
        return {
          animationSpeed: formGroup.value.animationSpeed / this.secondsConverter,
          animationCurve: formGroup.value.animationCurve,
          pauseDuration: formGroup.value.pauseDuration / this.secondsConverter,
          savedView: item.savedView
        };
      });
      const payload: MapTour = {
        id: this.data.id,
        name: this.myForm.value.name,
        description: this.myForm.value.description,
        excludeFromReadOnly: this.myForm.value.excludeFromReadOnly,
        steps: items,
        map: this.mapService.getCurrentSelectedMapFromStore(),
      }
      this.toursService.update(payload).subscribe((tour) => {
        this.matDialogRef.close(tour);
      });
    }
  }

}

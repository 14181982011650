<form [formGroup]="keyValueForm" class="compact-form" (keydown)="onEnter($event)">
  <div formArrayName="pairs" cdkDropList (cdkDropListDropped)="drop($event)" class="example-list">
    <!-- TODO: Add cdkDrag when order index is added to the server side -->
    <div *ngFor="let pair of pairs.controls; let i = index; let last = last"
         [formGroupName]="i"
         [class.selected-row]="i === selectedRowIndex"
         class="row no-gutter px-0 align-items-center no-gutter mb-1 example-box d-flex align-items-center">

      <div class="col-2" *ngIf="sorting">
        <span class="material-symbols-rounded" cdkDragHandle>drag_handle</span>
      </div>

      <!-- Hidden ID Input -->
      <input type="hidden" formControlName="id">

      <!-- Key Input -->
      <div [ngClass]="{'col-3': sorting, 'col-4': !sorting}" class="px-0 d-flex align-items-center">
        <textarea
          #txtAreaKey
          (click)="selectRow(i, $event, pair.get('editable')?.value)"
          (keydown.tab)="onTab($event, i, 'key')"
          (select)="onTab($event, i, 'key', pair.get('editable')?.value)"
          [class.disabled]="pair.get('editable')?.value === false"
          [class.no-border-input]="selectedRowIndex !== i"
          [placeholder]="'METADATA.KEY' | translate"
          appAutoResizeInput
          autoSelect
          class="w-100 meta-key key-input"
          formControlName="key"
          rows="1"
          type="text">
        </textarea>
      </div>

      <!-- Value Input -->
      <div class="px-0 d-flex align-items-center ps-1"
           [class.no-border-input]="selectedRowIndex !== i"
           [ngClass]="{'col-7': sorting, 'col-8': !sorting}">
        <ng-container [ngSwitch]="pair.get('type')?.value">
          <ng-container *ngSwitchCase="'date'">
            <input
              (click)="picker.open(); selectRow(i, $event, pair.get('editable')?.value)"
              [class.disabled]="!pair.get('editable')?.value === false"
              [class.no-border-input]="selectedRowIndex !== i"
              [matDatepicker]="picker"
              class="w-100 meta-value value-input date-input p-0"
              formControlName="value"
              matInput
            >
            <mat-datepicker #picker [disabled]="!pair.get('editable')?.value"></mat-datepicker>
          </ng-container>
          <ng-container *ngSwitchDefault>
            <textarea
              #txtAreaValue
              (click)="selectRow(i, $event)"
              (keydown.tab)="onTab($event, i, 'value')"
              [class.no-border-input]="selectedRowIndex !== i"
              [placeholder]="'METADATA.VALUE' | translate"
              appAutoResizeInput
              autoSelect
              class="w-100 meta-value value-input"
              formControlName="value"
              rows="1"
              type="text">
            </textarea>
          </ng-container>
        </ng-container>
      </div>

      <mat-divider class="mt-1" *ngIf="!last"></mat-divider>
    </div>
  </div>

  <div class="mt-2 add-button-container d-flex justify-content-between">
    <div class="text-start">
      <span
        (click)="extendExistingMetaWithValue()"
        *appHasPackage="[packageNames.developer]"
        [matTooltip]="'METADATA.GENERATE_MISSING_VALUES' | translate"
        class="material-symbols-rounded">
        cognition
      </span>
    </div>
    <div class="text-end">
      <span
        (click)="addPair()"
        [matTooltip]="'METADATA.ADD_PROPERTY' | translate"
        class="material-symbols-rounded">
        add_circle
      </span>
      <span
        (click)="removePair()"
        [class.disabled]="selectedRowIndex === null"
        [matTooltip]="'METADATA.REMOVE_PROPERTY' | translate"
        class="material-symbols-rounded">
        do_not_disturb_on
      </span>
    </div>
  </div>
</form>

<div class="comment-container">
  @for (comment of comments; track comment.id; let last = $last; let index = $index) {
    <ng-container
      *ngTemplateOutlet="commentBody;
                         context: {
                           $implicit: comment,
                           index: index,
                           isReply: false,
                           elementId: 'root_comment_' + index
                         }">
    </ng-container>

    @if (replyToIndex === index && createNewComment && isEditing) {
      <div class="replies-container">
        <ng-container *ngTemplateOutlet="newComment"></ng-container>
      </div>
    }
    @if (!last || createNewComment && replyToIndex === null) {
      <mat-divider class="mt-2 mb-2"></mat-divider>
    }
  }
  @if (createNewComment && replyToIndex === null && isEditing) {
    <ng-container *ngTemplateOutlet="newComment"></ng-container>
  }
  @if (isEditing) {
    <div class="mt-2 text-end add-button-container">
      @if (!createNewComment && currentEditingCommentElementId === null) {
        <span class="material-symbols-rounded"
              (click)="startCreatingComment()"
              [matTooltip]="'COMMENTS.ADD_COMMENT' | translate"
        >
          add_circle
        </span>
      }
    </div>
  }
</div>

<ng-template #commentBody
             let-comment
             let-elementId="elementId"
             let-index="index"
             let-isLast="isLast"
             let-isReply="isReply">
  <div class="comment" [id]="elementId">
    <ng-container
      *ngTemplateOutlet="commentTitle;
                         context: {
                           $implicit: comment,
                           index: index,
                           isReply: isReply
                         }">
    </ng-container>

    <div class="comment-content" (click)="startEditingComment(elementId, comment)">
      @if (currentEditingCommentElementId === elementId) {
        <textarea type="text"
                  #txtAreaValue
                  rows="1"
                  cdkTextareaAutosize
                  cdkAutosizeMinRows="1"
                  [placeholder]="'COMMENTS.PLACEHOLDER_COMMENT' | translate"
                  [formControl]="newCommentControl"
                  autoSelect
                  class="w-100 comment-input">
        </textarea>
      } @else {
        <span class="pre-line markdown">
          {{ comment.content }}
        </span>
      }
    </div>

    @if (comment.replies.length > 0) {
      <mat-divider class="mt-1 mb-1"></mat-divider>
      <div class="replies-container">
        @for (reply of comment.replies; track reply.id; let replyIndex = $index; let last = $last) {
          <ng-container
            *ngTemplateOutlet="commentBody;
                               context: {
                                 $implicit: reply,
                                 index: replyIndex,
                                 isReply: true,
                                 isLast: last,
                                 elementId: elementId + '_reply_' + replyIndex
                               }">
          </ng-container>
          @if (!last || createNewComment && replyToIndex === index) {
            <mat-divider class="mt-1 mb-1"></mat-divider>
          }
        }
      </div>
    }
  </div>

  @if ((createNewComment || currentEditingCommentElementId !== null)
  && currentEditingCommentElementId === elementId) {
    <div class="d-flex justify-content-end">
      <span class="material-symbols-rounded"
            (click)="cancelComment()"
            [matTooltip]="'COMMENTS.CANCEL_COMMENT' | translate">
        cancel
      </span>
      <span class="material-symbols-rounded"
            (click)="addComment()"
            [matTooltip]="'COMMENTS.SUBMIT_COMMENT' | translate">
        check
      </span>
    </div>
  }
</ng-template>

<ng-template #commentTitle let-comment let-index="index" let-isReply="isReply">
  @if (!getIsLongName(comment.user)) {
    <div class="comment-title w-100">
      <span class="comment-author">{{ getFullName(comment.user) }}</span> &nbsp;
      <span class="comment-date">{{ comment.createdAt | date: 'MM/dd/yyyy hh:mm a' }}</span>
      @if (isEditing) {
        <div class="hover-icons">
          @if(!isReply) {
            <span class="material-symbols-rounded"
                  (click)="replyToComment(index)"
                  [matTooltip]="'COMMENTS.REPLY_COMMENT' | translate">
              reply
            </span>
          }
          @if (currentUser.id === comment.user.id || isSmapAuthor) {
            <span class="material-symbols-rounded"
                  (click)="deleteComment(comment)"
                  [matTooltip]="'COMMENTS.DELETE_COMMENT' | translate">
              delete
            </span>
          }
        </div>
      }
    </div>
  } @else {
    <div class="comment-title truncate w-100"
         [matTooltip]="getFullName(comment.user) + ' ' + getFormattedDate(comment)">
      <span class="comment-author">{{ user.name }}</span> &nbsp;
      <span class="comment-date">{{ comment.createdAt | date: 'MM/dd/yyyy' }}</span>
      @if (isEditing) {
        <div class="hover-icons">
          @if(!isReply) {
            <span class="material-symbols-rounded"
                  (click)="replyToComment(index)"
                  [matTooltip]="'COMMENTS.REPLY_COMMENT' | translate">
              reply
            </span>
          }
          @if (currentUser.id === comment.user.id || isSmapAuthor) {
            <span class="material-symbols-rounded"
                  (click)="deleteComment(comment)"
                  [matTooltip]="'COMMENTS.DELETE_COMMENT' | translate">
              delete
            </span>
          }
        </div>
      }
    </div>
  }
</ng-template>

<ng-template #newComment>
  <div class="comment">
    @if (!getIsLongName()) {
      <div class="comment-title w-100">
        <span class="comment-author">{{ getFullName() }}</span> &nbsp;
        <span class="comment-date">{{ newCommentDate | date: 'MM/dd/yyyy hh:mm a' }}</span>
      </div>
    } @else {
      <div class="comment-title truncate w-100">
        <span class="comment-author">{{ user.name }}</span> &nbsp;
        <span class="comment-date">{{ newCommentDate | date: 'MM/dd/yyyy' }}</span>
      </div>
    }
    <textarea type="text"
              #txtAreaValue
              rows="1"
              cdkTextareaAutosize
              cdkAutosizeMinRows="1"
              [placeholder]="'COMMENTS.PLACEHOLDER_COMMENT' | translate"
              [formControl]="newCommentControl"
              autoSelect
              class="w-100 comment-input">
    </textarea>
    <div class="d-flex justify-content-end">
      <span (click)="cancelComment()"
            [matTooltip]="'COMMENTS.CANCEL_COMMENT' | translate"
            class="material-symbols-rounded">
        cancel
      </span>
      <span class="material-symbols-rounded"
            (click)="addComment()"
            [matTooltip]="'COMMENTS.SUBMIT_COMMENT' | translate">
        check
      </span>
    </div>
  </div>
</ng-template>

import { Component, inject, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIcon } from '@angular/material/icon';
import { MatFormField, MatSuffix } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { CdkCopyToClipboard } from '@angular/cdk/clipboard';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MapService } from '../api/services/map.service';
import { SelectInputComponent } from '../shared/forms/select-input/select-input.component';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { TextInputComponent } from '../shared/forms/text-input/text-input.component';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { LinkViewMode } from '../api/services/setting.service';
import { concatMap } from 'rxjs';
import { GraphMap } from '../api/interfaces/graph-node.interface';

@Component({
  selector: 'app-embed-dialog',
  styles: [`
    ::ng-deep {

      .share-map-form {
        --mdc-theme-primary: #000000;

        .mdc-floating-label {
          color: #000000 !important;
        }
      }

      #buttonName {
        input {
          color: rgba(0, 0, 0, 0.6);
          font-size: 16px;
          padding-left: 25px;
        }
      }

    }
  `],
  template: `
    <div mat-dialog-content class="p-3 px-4">
      <h4>{{ 'EMBED_DIALOG.INSTRUCTIONS.TITLE' | translate }}</h4>
      <ol>
        <li>{{ 'EMBED_DIALOG.INSTRUCTIONS.MAKE_PUBLIC' | translate }}</li>
        <li [innerHTML]="'EMBED_DIALOG.INSTRUCTIONS.CHOOSE_STYLE' | translate"></li>
        <li>{{ 'EMBED_DIALOG.INSTRUCTIONS.COPY_SNIPPET' | translate }}</li>
      </ol>

      <h4>{{ 'EMBED_DIALOG.ACCESS.TITLE' | translate }}</h4>
      <app-select-input
        [label]="'EMBED_DIALOG.ACCESS.LEVEL' | translate"
        [formControl]="accessControl"
        [options]="accessOptions">
      </app-select-input>

      <h4 class="mt-3">{{ 'EMBED_DIALOG.SM_SETUP.TITLE' | translate }}</h4>
      <app-select-input
        [label]="'EMBED_DIALOG.SM_SETUP.LINK_DISPLAY_MODE' | translate"
        [formControl]="linkViewModeControl"
        [options]="linkViewModeOptions">
      </app-select-input>

      <h4 class="mt-3">{{ 'EMBED_DIALOG.STYLE.TITLE' | translate }}</h4>
      <app-select-input
        [label]="'EMBED_DIALOG.STYLE.CHOOSE' | translate"
        [formControl]="embedOption"
        [options]="embedOptions">
      </app-select-input>

      @if (embedOption.value === 'button' || embedOption.value === 'link') {
        <div class="mt-3">
          <app-text-input id="buttonName"
                          [autoFocusAndSelect]="true"
                          [label]="'EMBED_DIALOG.STYLE.BUTTON_LINK_NAME' | translate"
                          [title]="'EMBED_DIALOG.STYLE.BUTTON_LINK_NAME' | translate"
                          [formControl]="buttonOrLinkNameControl"></app-text-input>
        </div>
      }

      <h4 class="mt-3">{{ 'EMBED_DIALOG.SNIPPET.TITLE' | translate }}</h4>
      <mat-form-field class="w-100">
    <textarea
      [value]="embedSnippet()"
      #embedCode
      matInput
      disabled
      cdkTextareaAutosize>
    </textarea>
        <button
          mat-icon-button
          matSuffix
          [cdkCopyToClipboard]="embedSnippet()"
          [matTooltip]="'EMBED_DIALOG.SNIPPET.COPY_TO_CLIPBOARD' | translate"
          (click)="embedCode.select(); snackBar.open('Code copied to clipboard', '', { duration: 2000 })">
          <mat-icon class="material-symbols-outlined">content_copy</mat-icon>
        </button>
      </mat-form-field>

      <h4 class="mt-3">{{ 'EMBED_DIALOG.TUTORIAL.TITLE' | translate }}</h4>
      <iframe width="100%" height="315"
              src="https://www.youtube.com/embed/sY-TNMWRT0Q"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen>
      </iframe>
    </div>

    <div mat-dialog-actions class="d-flex justify-content-center">
      <button mat-button mat-dialog-close>{{ 'EMBED_DIALOG.GENERAL.CANCEL' | translate }}</button>
      <button mat-button color="accent" (click)="done()">{{ 'EMBED_DIALOG.GENERAL.OK' | translate }}</button>
    </div>
  `,
  standalone: true,
  imports: [
    MatDialogModule,
    MatButtonModule,
    MatTooltipModule,
    MatIcon,
    MatFormField,
    MatInput,
    CdkTextareaAutosize,
    CdkCopyToClipboard,
    MatSuffix,
    SelectInputComponent,
    ReactiveFormsModule,
    TextInputComponent,
    TranslatePipe
  ]
})

export class EmbedDialogComponent {
  snackBar = inject(MatSnackBar);

  accessControl = new FormControl('private');

  embedOption = new FormControl<'iframe' | 'button' | 'link'>('iframe');
  embedOptions: { key: string; value: string; icon: string }[] = [];
  accessOptions: { key: string; value: string; icon: string }[] = [];
  linkViewModeControl = new FormControl(LinkViewMode.ALL.toString()); // Default to ALL Links
  linkViewModeOptions = [
    { key: '0', value: 'All Links', icon: 'device_hub' },
    { key: '2', value: 'Selection Links', icon: 'device_hub' },
    { key: '1', value: 'No Links', icon: 'device_hub' }
  ];

  buttonOrLinkNameControl = new FormControl<string>(''); // Control for customizing name

  private host = location.origin;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      map: GraphMap;
      mapName: string;
      isPublic: boolean
    },
    private matDialogRef: MatDialogRef<EmbedDialogComponent>,
    private mapService: MapService,
    private translate: TranslateService
  ) {
    this.accessControl.setValue(data.isPublic ? 'public' : 'private');
    this.linkViewModeControl.setValue(data.map.smapletLinkViewMode?.toString() || LinkViewMode.ALL.toString());
    // Initialize buttonOrLinkNameControl with the default name
    const defaultName = `View ${this.data.mapName?.trim() || 'Unnamed'} Smap`;
    this.buttonOrLinkNameControl.setValue(defaultName);
    this.initializeOptions();
  }

  private initializeOptions(): void {
    this.translate.get([
      'EMBED_DIALOG.STYLE.IFRAME',
      'EMBED_DIALOG.STYLE.BUTTON',
      'EMBED_DIALOG.STYLE.LINK',
      'EMBED_DIALOG.ACCESS.PUBLIC',
      'EMBED_DIALOG.ACCESS.PRIVATE'
    ]).subscribe(translations => {
      this.embedOptions = [
        { key: 'iframe', value: translations['EMBED_DIALOG.STYLE.IFRAME'], icon: 'integration_instructions' },
        { key: 'button', value: translations['EMBED_DIALOG.STYLE.BUTTON'], icon: 'smart_button' },
        { key: 'link', value: translations['EMBED_DIALOG.STYLE.LINK'], icon: 'link' }
      ];

      this.accessOptions = [
        { key: 'public', value: translations['EMBED_DIALOG.ACCESS.PUBLIC'], icon: 'public' },
        { key: 'private', value: translations['EMBED_DIALOG.ACCESS.PRIVATE'], icon: 'lock' }
      ];
    });
  }

  iframeCode(mapId: string): string {
    return `<iframe sandbox="allow-scripts allow-same-origin allow-popups"
      src="${this.host}/dashboard?mapId=${mapId}"
      width="100%" height="100%">
    </iframe>`;
  }

  fullscreenButtonCode(mapId: string): string {
    const iframeUrl = `${this.host}/dashboard?mapId=${mapId}`;
    const buttonLabel = this.buttonOrLinkNameControl.value?.trim() || `View ${this.data.mapName || ''} Smap`;
    const scriptCode = `<script src="${this.host}/assets/embeds/fullscreen-script.min.js"></script>`;

    const buttonCode = `${scriptCode}

    <button onclick="openFullscreenMap('${mapId}', '${this.data.mapName || ''}', '${this.host}')">${buttonLabel}</button> `;

    return buttonCode;
  }

  fullscreenLinkCode(mapId: string): string {
    const linkLabel = this.buttonOrLinkNameControl.value?.trim() || `View ${this.data.mapName || ''} Smap`;
    const scriptCode = `<script src="${this.host}/assets/embeds/fullscreen-script.min.js"></script>`;

    const linkCode = `${scriptCode}

    <a href="javascript:openFullscreenMap('${mapId}', '${this.data.mapName || ''}', '${this.host}')">${linkLabel}</a> `;

    return linkCode;
  }

  embedSnippet(): string {
    const mapId = this.data.map.id;
    if (this.embedOption.value === 'iframe') return this.iframeCode(mapId);
    if (this.embedOption.value === 'button') return this.fullscreenButtonCode(mapId);
    return this.fullscreenLinkCode(mapId); // Default to link if selected
  }

  done() {
    const publish = this.accessControl.value === 'public';
    const stream = publish ? this.publish() : this.unpublish();
    stream
      .pipe(
        concatMap(() => this.mapService.setLinkViewMode(this.data.map.id, (this.linkViewModeControl.value as any * 1)))
      )
      .subscribe(() => {
        this.data.map.smapletLinkViewMode = this.linkViewModeControl.value as any * 1;
        this.mapService.setCurrentSelectedMapToStore(this.data.map);
        this.matDialogRef.close({ published: publish });
      });
  }

  publish() {
    return this.mapService.openPublicAccessToMap(this.data.map.id);
  }

  unpublish() {
    return this.mapService.closePublicAccessToMap(this.data.map.id);
  }
}



import {
  ApplicationRef,
  Component,
  ComponentFactoryResolver,
  effect,
  ElementRef,
  Injector,
  OnInit,
  signal,
  ViewChild,
  WritableSignal
} from '@angular/core';
import cytoscape, { Core, EdgeSingular, Layouts, NodeDataDefinition, NodeSingular } from 'cytoscape';
import { HttpClientModule } from '@angular/common/http';
import { EdgeTypeMapCy, GraphMap, GraphNode, GraphNodeLink } from '../api/interfaces/graph-node.interface';
import { MatDrawer, MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { ReactiveFormsModule } from '@angular/forms';
import { LayoutTypes } from '../shared/layouts';
import { CytoscapeService } from '../services/cy-services/cytoscape.service';
import { NodeService } from '../api/services/node.service';
import { MatInputModule } from '@angular/material/input';
import { ColorSketchModule } from 'ngx-color/sketch';
import { DatatifyDirective } from '../shared/directives/datatify.directive';
import { MapService } from '../api/services/map.service';
import { environment } from '../../environments/environment';
import { BlockUIModule } from 'ng-block-ui';
import {
  catchError,
  concatMap,
  delay,
  delayWhen,
  filter,
  finalize,
  firstValueFrom,
  from,
  mergeMap,
  of,
  retryWhen,
  Subscription,
  switchMap,
  tap,
  throwError,
  timer
} from 'rxjs';
import { UserService } from '../api/services/user.service';
import { DefaultSidebarWidth, LinkViewMode, SettingsService } from '../api/services/setting.service';
import { MapInfoSectionComponent } from '../sidebar/map-info-section.component';
import { GeneralInfo } from '../api/interfaces/general-info';
import { cyNodeStyles, miscStyles, StatusIcons } from './cy-styles';
// eslint-disable-next-line @typescript-eslint/no-var-requires
// @ts-ignore
import { GptService } from '../api/services/gpt.service';
import { GptMapResponse } from '../api/interfaces/gpt-map.response';
import { SETTINGS_STORAGE_KEY } from '../nav/local-storage.settings';
import { ButtonPressService } from '../services/button-press.service';
import { drawGradientNode } from '../../cytoscape-extensions/draw-gradient-node';
import { doubleTapExtension, getDoubleTapSetting } from '../../cytoscape-extensions/double-tap.extension';
import { DefaultNodeProperties, DefaultValues, MapStateDto } from '@maporium-workspace/shared';

import {
  destroyPanZoom,
  ExpandCollapseInProgress,
  invalidateGeneralPanZoom,
  invalidatePanZoomForCollapsedNode,
  restoreGeneralPanZoom,
  restorePanZoomDynamic,
  restorePanZoomForCollapsedNode,
  restoreSavedPanZoomForCollapsedNode,
  setPanZoomForCollapsedNode,
  setPanZoomGeneral,
  storePanZoomDynamic
} from '../../cytoscape-extensions/pan-zoom-storage';
import { ResizableModule, ResizeEvent } from 'angular-resizable-element';
import { ViewService } from '../api/services/view.service';
import { ToursPanelComponent } from '../shared/tours-panel/tours-panel.component';
import { ToursService } from '../api/services/tour.service';
import { MapStateService } from '../api/services/map-state.service';
import { SidebarService } from '../sidebar-form/sidebar.service';
import { initCyPlugins } from './cy-plugins.import';
import { setGridPlugin } from './grid-plugin.config';
import { SidebarFormComponent } from '../sidebar-form/sidebar-form.component';
import { cloneDeep, get, isEmpty, isEqual, omit, reverse, set, uniqWith } from 'lodash';
import { MultiUpdateEvent } from '../sidebar-form/dtos/node-allowed-multi-change';
import { LinkTarget } from '../shared/pipes/link-target/link-target.enum';
import { MetaData } from '../api/interfaces/metadata';
import chroma from 'chroma-js';
import { NodeStateData, StateLessNode } from '../../../../maporium/src/app/entities/types/node-state-data';
import { LinkStateData, StateLessLink } from '../../../../maporium/src/app/entities/types/link-state.data';
import { AppWideStateService } from '../services/app-state/app-wide-state.service';
import { isInIframe } from '../shared/helpers/maporium.validators';
import { ActivatedRoute } from '@angular/router';
import { toObservable } from '@angular/core/rxjs-interop';
import { UndoRedoService } from '../services/undo-redo.service';
import {
  callAddAnchor,
  createNewAnchorsAndInitiateEdgeEditing,
  getPointDataFromAnchorsArray,
  mapAnchorsToXYArray
} from '../shared/helpers/edge-editing.helper';
import { LoadingAnimationComponent } from '../shared/components/loading-logo';
import { shouldSkipLinkDataUpdate, updateInProgress } from '../shared/helpers/signal.helpers';
import { BlockUiInterceptor } from '../interceptors/block-ui.interceptor';
import { TourMatMenuModule, TourService } from 'ngx-ui-tour-md-menu';
import { TourStepTemplateComponent } from '../shared/components/tour-step-template/tour-step-template.component';
import { Logger } from '../shared/helpers/logger.helper';
import { PackageService } from '../api/services/package.service';
import { SmapStylesDirective } from '../shared/directives/smap-styles.directive';

initCyPlugins();
Object.defineProperty(window, 'getTappedLink', {
  value: () => DashboardComponent.tappedLink,
  writable: false,
  configurable: true
});

@Component({
  selector: 'maporium-dashboard',
  templateUrl: './dashboard.component.html',
  standalone: true,
  styleUrls: ['./dashboard.component.scss'],
  imports: [
    HttpClientModule,
    MatSidenavModule,
    MatButtonModule,
    MatIconModule,
    CommonModule,
    MatFormFieldModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatInputModule,
    ColorSketchModule,
    BlockUIModule,
    MapInfoSectionComponent,
    ResizableModule,
    ToursPanelComponent,
    SidebarFormComponent,
    LoadingAnimationComponent,
    TourMatMenuModule,
    TourStepTemplateComponent,
    SmapStylesDirective
  ]

})

export class DashboardComponent implements OnInit {
  static tappedNode: GraphNode | null = null;
  static tappedLink: GraphNodeLink | null = null;

  @ViewChild('cytoscapeContainer', { static: true }) cytoscapeContainer: ElementRef | undefined;
  @ViewChild('tooltipContainer', { static: true }) tooltipContainer: ElementRef | undefined;
  @ViewChild('canvas', { static: true }) canvas: HTMLCanvasElement | undefined;
  private cy: cytoscape.Core | undefined;

  @ViewChild('drawer') drawer: MatDrawer | undefined;
  drawerWidth = DefaultSidebarWidth;


  public hasNoMap = false;
  public nodes: GraphNode[] = [];
  public selectedNodes: GraphNode[] = [];
  public selectedLinks: GraphNodeLink[] = [];
  public isReadOnly = false;
  public buildNumber = 'local';
  public hasNodesNoNodes = false;
  public sidebarResizable = true;
  public isChangingLinkSource = false;
  public isCanvasLoaded = true;

  public get focusedLink() {
    return DashboardComponent.tappedLink;
  }

  public get focusedNode() {
    return DashboardComponent.tappedNode;
  }

  private drawerMaxWidth = 400;
  private drawerMinWidth = 200;
  private edgeViewMode: LinkViewMode = LinkViewMode.ALL;
  private isDetailsOpen = false;
  private isUnlinking = false;
  private cyLayout: Layouts | undefined;
  public currentMap: GraphMap | undefined;
  public isSideBarOpen = false;
  private settings: Partial<GeneralInfo> | undefined;
  private contextMenuInstance: any;
  private isLoading = false;
  private lastKnownSidebarWidth = 0;
  private isCentering = false;
  public readModeAppState = this.appWideStateService.readMode();

  private tooltipTimeout: any;
  private edgeEditingInstance: any;
  private linkEndpointSignal: WritableSignal<{
    point: 'source' | 'target',
    direction: 'top' | 'bottom' | 'right' | 'left'
  } | null> = signal(null);
  public static multiSelectedElements: (GraphNode | GraphNodeLink)[] = [];

  private copyPasteSub: Subscription | undefined;
  private createFromMenu = false;
  private menuSubscription: Subscription | undefined;
  private eh: any;
  private hasCommentsPollingSub = new Subscription();

  constructor(private nodeService: NodeService,
              private cyService: CytoscapeService,
              private userService: UserService,
              private packageService: PackageService,
              private buttonPressService: ButtonPressService,
              private settingsService: SettingsService,
              private toursService: ToursService,
              private route: ActivatedRoute,
              private mapService: MapService,
              private gptService: GptService,
              private injector: Injector,
              private appRef: ApplicationRef,
              private resolver: ComponentFactoryResolver,
              private undoService: UndoRedoService,
              private viewService: ViewService,
              private mapStateService: MapStateService,
              private appWideStateService: AppWideStateService,
              public tourService: TourService,
              private sidebarService: SidebarService) {

    effect(() => {
      this.isCanvasLoaded = this.appWideStateService.canvasLoadedSignal();
      if (this.cy) {
        this.cy.style().update();
      }
    });

    // @TODO Rework to plain signal remember about the agressive build
    toObservable(this.settingsService.linkViewModeSignal)
      .subscribe((mode) => {
        const cy = this.cy;
        if (cy) {
          if (mode === LinkViewMode.ALL) {
            cy.edges().removeClass('hidden');
          }
          if (mode === LinkViewMode.OFF || mode === LinkViewMode.RELATED) {
            cy.edges().not(':selected').addClass('hidden');
          }

          if (mode === LinkViewMode.RELATED) {
            cy.nodes(':selected').edgesWith('*').removeClass('hidden');
          }
          this.edgeViewMode = mode;
        }
      });
    // @TODO Rework to plain signal remember about the agressive build
    toObservable(this.mapService.selectedMap)
      .pipe(
        filter(() => this.cy !== undefined),
        retryWhen(errors =>
          errors.pipe(
            delayWhen(() => timer(200)) // Retry after 1 second
          )
        )
      )
      .subscribe((newMap) => {
        if (isInIframe()) {
          this.currentMap = newMap;
        }
        const isShowTourActive = localStorage.getItem(TourStepTemplateComponent.SHOW_TOUR_KEY);
        if (isShowTourActive !== 'false') {
          setTimeout(() => {
            this.tourService.initialize(
              [{
                anchorId: 'welcome',
                title: 'WELCOME!',
                content: 'Get ready to experience visual context in a new way.',
                nextBtnTitle: 'NEXT',
                showArrow: false
              },
                {
                  anchorId: 'node-anchor',
                  title: 'EXPLORE…',
                  content: 'Double click any node to focus and go deeper, then double click again to go back. <br> <br>' +
                    'Right click any container to collapse it via the contextual menu, then expand it again.',
                  isAsync: true,
                  asyncStepTimeout: 2000,
                  nextBtnTitle: 'NEXT',

                  enableBackdrop: true,
                  showArrow: this.cy!.nodes().length > 0
                },
                {
                  anchorId: 'sidebar-anchor',
                  nextBtnTitle: 'NEXT',

                  title: 'DISCOVER…',
                  popoverClass: 'sidebar-popover',
                  content: 'See information related to the selected node or link in the sidebar. <br><br>' +
                    ' Click the canvas to deselect everything and see smap information.',
                  enableBackdrop: true,
                  placement: {
                    horizontal: true
                  }
                },
                {
                  nextBtnTitle: 'NEXT',
                  anchorId: 'link-menu-anchor',
                  title: 'CONTROL…',
                  content: 'Choose your level of link complexity. <br> <br> With selection links, use the Shift key to follow a path.',
                  enableBackdrop: true,
                  placement: {
                    xPosition: 'before'
                  }
                },
                {
                  nextBtnTitle: 'NEXT',
                  anchorId: 'logo-anchor',
                  title: 'NOW START EXPLORING…',
                  content: 'Visit our website later by clicking this logo and share the story of your experience.',
                  enableBackdrop: false,
                  endBtnTitle: 'END'
                }
              ], {
                nextBtnTitle: 'NEXT',
                enableBackdrop: true,
                popoverClass: 'tour-popover',
                backdropConfig: {
                  backgroundColor: 'rgba(0,0,0,0.5)'
                },
                stepDimensions: {
                  minWidth: '300px',
                  maxWidth: '400px'
                }
              }
            );
            setTimeout(() => {
              this.tourService.start();
            }, 1000);
          }, 1000);
        }
        this.resetAllSelected(this.cy as cytoscape.Core);
        this.listenToMapChanges(this.cy as cytoscape.Core, this.eh, newMap);
      });

    effect(() => {
      this.readModeAppState = this.appWideStateService.readMode();
      if (this.cy) {
        this.cy!.style().update();
      }
    });

    effect(async () => {
      const restoredNode = this.undoService.restored();
      if (restoredNode && restoredNode.nodes.length > 0) {
        for (const node of restoredNode.nodes) {
          const cyNode = this.cy?.$('#' + node.id).first() as NodeSingular;
          if (cyNode && node.parent) {
            cyNode.move({ parent: node.parent.id as string });
            cyNode.removeClass('display-none');
          }
        }
      }
      if (restoredNode && restoredNode.links.length > 0) {
        for (const link of restoredNode.links) {
          const cyLink = this.cy?.$('#' + link.id);
          // @ts-ignore
          cyLink.removeClass('display-none');
          // @ts-ignore
          cyLink.removeData('deleted');
        }
      }
      if (this.cy) {
        this.cy!.style().update();
      }
    });

    effect(() => {
      const state = this.mapStateService.getStateSignal();
      this.listenToMapStateChanges(state);
      if (this.cy) {
        this.cy!.style().update();
      }
    }, { allowSignalWrites: true });

    effect(() => {
      const editMode = this.sidebarService.getEditModeSignal();
      if (this.cy) {
        this.cy.style().update();
      }
    });
  }

  async ngOnInit() {
    const isShowTourActive = localStorage.getItem(TourStepTemplateComponent.SHOW_TOUR_KEY);

    // Refresh user
    this.userService.refreshLocalUser()
      .subscribe();
    const storedMap = this.mapService.getCurrentSelectedMapFromStore();
    this.hasNoMap = this.mapService.hasNoStoredMap(storedMap?.id);
    if (isShowTourActive !== 'false' && !this.hasNoMap) {
      setTimeout(() => {
        this.tourService.initialize(
          [{
            anchorId: 'welcome',
            title: 'WELCOME!',
            content: 'Get ready to experience visual context in a new way.',
            nextBtnTitle: 'NEXT',
            showArrow: false
          },
            {
              anchorId: 'node-anchor',
              title: 'EXPLORE…',
              content: 'Double click any node to focus and go deeper, then double click again to go back. <br> <br>' +
                'Right click any container to collapse it via the contextual menu, then expand it again.',
              isAsync: true,
              asyncStepTimeout: 2000,
              nextBtnTitle: 'NEXT',

              enableBackdrop: true,
              showArrow: this.cy!.nodes().length > 0
            },
            {
              anchorId: 'sidebar-anchor',
              nextBtnTitle: 'NEXT',

              title: 'DISCOVER…',
              popoverClass: 'sidebar-popover',
              content: 'See information related to the selected node or link in the sidebar. <br><br>' +
                ' Click the canvas to deselect everything and see smap information.',
              enableBackdrop: true,
              placement: {
                horizontal: true
              }
            },
            {
              nextBtnTitle: 'NEXT',
              anchorId: 'link-menu-anchor',
              title: 'CONTROL…',
              content: 'Choose your level of link complexity. <br> <br> With selection links, use the Shift key to follow a path.',
              enableBackdrop: true,
              placement: {
                xPosition: 'before'
              }
            },
            {
              nextBtnTitle: 'NEXT',
              anchorId: 'logo-anchor',
              title: 'NOW START EXPLORING…',
              content: 'Visit our website later by clicking this logo and share the story of your experience.',
              enableBackdrop: false,
              endBtnTitle: 'END'
            }
          ], {
            nextBtnTitle: 'NEXT',
            enableBackdrop: true,
            popoverClass: 'tour-popover',
            backdropConfig: {
              backgroundColor: 'rgba(0,0,0,0.5)'
            },
            stepDimensions: {
              minWidth: '300px',
              maxWidth: '400px'
            }
          }
        );
        setTimeout(() => {
          this.tourService.start();
        }, 1000);
      }, 1000);
    }
    this.currentMap = this.hasNoMap ? undefined : storedMap as GraphMap;

    if (this.hasNoMap && !isInIframe()) {
      const maps = await firstValueFrom(this.mapService.findAllForUser(this.userService.getCurrentUserFromStorage()?.id as string));
      this.currentMap = maps[0];
    } else if (isInIframe()) {
      this.currentMap = await firstValueFrom(this.mapService.findPublicMap(this.route.snapshot.queryParams['mapId']));
      this.hasNoMap = !this.currentMap;
    }

    const localSettings = this.settingsService.getLocalStorageSettings();
    const editMode = this.currentMap?.isReadonly ? false : Boolean(localSettings.isEditMode);
    editMode ? this.sidebarService.enableEditMode() : this.sidebarService.disableEditMode();

    const cy = this.initCy();
    CytoscapeService.menuChangedSubject.next();
    doubleTapExtension(cy, {
      options: {
        doubleTapDelay: getDoubleTapSetting()
      }
    });
    //@ts-ignore
    const eh = this.initDrawMode(cy);
    this.eh = eh;
    this.initCtxMenu2(cy, eh).then();
    this.onTap(cy);
    this.handleNodeLinking(cy);
    this.handleNodePositionChange(cy);
    this.handleElementSelection(cy);
    this.handleElementTap(cy);
    this.cy = cy;
    this.cyService.init(cy);
    this.listenToGptGeneration(cy);
    this.handleEdgeEditingEnd(cy);

    // TODO: Refactor, maybe use signals
    cy.on('doubleTap', 'node', (event: { target: any; }) => {
      const targetNode = event.target;
      const isCollapsed = targetNode.hasClass('collapsed');
      const selectedCyNodes = this.selectedNodes.map((n) => cy.getElementById(n.id as string));
      const storedNodePan = restorePanZoomForCollapsedNode(targetNode?.data('id'));

      if (isCollapsed) {
        // get all collapsed nodes that are currently selected
        this.cyService.expandMultipleNodes(selectedCyNodes, true)
          .pipe(
            switchMap((res) => {
              if (res) {
                return this.updateNode(res, true);
              } else {
                return of(null);
              }
            })
          )
          .subscribe();
      } else if (targetNode.children().length > 0 && !storedNodePan) {

        setPanZoomForCollapsedNode(targetNode?.data('id'), isCollapsed);
        this.cyService.centerOnElements(cy, targetNode, true, this.cyService.ZOOM_PERCENTAGE_CONTAINER);
      } else {
        if (storedNodePan) {
          if (storedNodePan.collapsed) {
            this.cyService.collapseNode(event, true)
              .pipe(
                switchMap((res) => {
                  if (res) {
                    return this.updateNode(res, true);
                  } else {
                    return of(null);
                  }
                })
              )
              .subscribe();
          }
          restoreSavedPanZoomForCollapsedNode(targetNode?.data('id'), cy);
          invalidatePanZoomForCollapsedNode();
        } else {
          setPanZoomForCollapsedNode(targetNode?.data('id'), isCollapsed);
          this.cyService.centerOnElements(cy, targetNode, true, this.cyService.ZOOM_PERCENTAGE_NODE);
        }
      }
    });
    cy.on('doubleTap', (event: any) => {
      if (isEmpty(event.target.data())) {
        const state = restoreGeneralPanZoom(cy);
        if (state) {
          ExpandCollapseInProgress.set(true);
          cy.animate({
            pan: state.pan,
            zoom: state.zoom,
            duration: 500,
            easing: 'ease-in-out',
            complete() {
              ExpandCollapseInProgress.set(false);
              invalidateGeneralPanZoom();
            }
          });
        } else {
          setPanZoomGeneral();
          this.cyService.centerCanvas();
        }
      }
    });

    //Listen for new node creation events
    this.listenToNodeCreate(cy);
    this.listenToMapStateChanges();
    this.listenToLayoutChanges(cy);
    this.handleCanvasUpdateForLabelMarginOnDrag(cy);

    this.nodeService.addGptToSubject.subscribe(() => {
      this.saveGptNodes();
    });

    this.settingsService.getGeneralInfo().subscribe((info) => {
      this.buildNumber = info.buildNumber;
      this.settings = info;
      setGridPlugin(cy as any, {
        drawGrid: isInIframe() ? false : Boolean(info.showGrid),
        geometricGuideline: isInIframe() ? false : info.showAlignLines,
        snapToGridDuringDrag: isInIframe() ? false : info.snapToGrid,
        snapToAlignmentLocationDuringDrag: isInIframe() ? false : info.snapToAlignLines,
        gridSpacing: info.gridSize,
        panGrid: true
      });
    });

    this.settingsService.generalInfoChanged.subscribe((info) => {
      this.settings = info;
      setGridPlugin(cy as any, {
        drawGrid: isInIframe() ? false : Boolean(info.showGrid),
        geometricGuideline: isInIframe() ? false : info.showAlignLines,
        snapToGridDuringDrag: isInIframe() ? false : info.snapToGrid,
        snapToAlignmentLocationDuringDrag: isInIframe() ? false : info.snapToAlignLines,
        gridSpacing: info.gridSize
      });
      // @ts-ignore
      cy.style().selector('edge[label]').style('text-rotation', info.autoRotateEdgeLabels ? 'autorotate' : 'none').update();
    });


    this.handleLocalSettings();
    this.settingsService.localStorageSettingsChanged
      .subscribe(() => {
        this.handleLocalSettings();
      });

    //@ts-ignore
    cy.expandCollapse({
      animate: false, // whether to animate on drawing changes you can specify a function too
      expandCollapseCueSize: 0,
      expandCollapseCueLineSize: 0,
      fisheye: false,
      layoutBy: {
        name: 'preset',
        animate: false,
        randomize: false,
        fit: false
      }
    });

    this.buttonPressService.init(cy).subscribe();

    this.buttonPressService.initUndoRedo(cy as any);

    cy.on('mouseover', 'edge', (event) => {
      const edge = event.target;
      const labelKey = edge.data('label');
      const label = edge.data(labelKey);
      if (!label || label === '') return;

      // Clear any previous timeout to avoid unexpected behavior
      if (this.tooltipTimeout) {
        clearTimeout(this.tooltipTimeout);
      }

      this.tooltipTimeout = setTimeout(() => {
        const mousePos = event.originalEvent; // Get the mouse position
        const containerPos = this.cytoscapeContainer?.nativeElement.getBoundingClientRect(); // Get the container position

        // Create and position the tooltip
        const tooltip = document.createElement('div');
        const data = edge.data();
        if (data?.label === 'name') {
          tooltip.textContent = data.name || '';
        } else {
          tooltip.textContent = data.metadata?.find((meta: {
            key: string;
            value: string
          }) => meta.key === data.label)?.value;
        }

        tooltip.className = 'edge-tooltip';
        tooltip.style.left = `${mousePos.clientX - containerPos.left}px`; // Adjust for container position
        tooltip.style.top = `${mousePos.clientY - containerPos.top}px`; // Adjust for container position

        // Add the tooltip to the container
        // @ts-ignore
        this.cytoscapeContainer?.nativeElement.appendChild(tooltip);
      }, 1000);  // wait for 1 second
    });

    cy.on('mouseout', 'edge', () => {
      // Clear the timeout when mouseout to avoid displaying the tooltip if the mouse has already left
      if (this.tooltipTimeout) {
        clearTimeout(this.tooltipTimeout);
      }

      // @ts-ignore
      const tooltip = this.cytoscapeContainer?.nativeElement.querySelector('.edge-tooltip');

      if (tooltip) {
        // Remove the tooltip from the container
        // @ts-ignore
        this.cytoscapeContainer?.nativeElement.removeChild(tooltip);
      }
    });
    this.registerTooltipClearHandler(cy);
    this.applyLayout(cy, LayoutTypes.PRESET);
    if (!this.readModeAppState) {
      if (this.currentMap?.owner.id !== this.userService.getCurrentUserFromStorage().id) {
        this.updateElementsCommentsFlag();
      }
    }
    if (!this.copyPasteSub || this.copyPasteSub.closed) {
      this.copyPasteSub = new Subscription();
      this.copyPasteSub.add(
        this.buttonPressService.getNodeCreation()
          .subscribe((res) => {
            this.loadCyElements(cy, false, true, res);
          })
      );
    }

    //@ts-ignore
    this.edgeEditingInstance = cy.edgeEditing({
      undoable: false,
      bendRemovalSensitivity: 0,
      enableMultipleAnchorRemovalOption: false,
      initAnchorsAutomatically: false,
      ignoredClasses: ['ignore_control_points'],
      useTrailingDividersAfterContextMenuOptions: false,
      enableCreateAnchorOnDrag: false,
      addBendMenuItemTitle: false,
      removeBendMenuItemTitle: false,
      removeAllBendMenuItemTitle: false,
      addControlMenuItemTitle: false,
      removeControlMenuItemTitle: false,
      removeAllControlMenuItemTitle: false,
      handleReconnectEdge: false,
      bendCornersIsRoundFunction(e: any) {
        return e.data('round');
      },
      bendPositionsFunction: (ele: any) => {
        const currentState = this.mapStateService.getCurrentLocalState();
        const statedBendPoints = ele.data('states')?.find((s: any) => s.stateId === currentState?.id)?.link.bendPointPositions;
        return statedBendPoints || ele.data('bendPointPositions');
      },
      controlPositionsFunction: (ele: any) => {
        const currentState = this.mapStateService.getCurrentLocalState();
        const statedControlPoints = ele.data('states')?.find((s: any) => s.stateId === currentState?.id)?.link.controlPointPositions;
        return statedControlPoints || ele.data('controlPointPositions');
      },
      // A function parameter to set bend point positions
      bendPointPositionsSetterFunction: function(ele: any, bendPointPositions: any) {
        ele.data('bendPointPositions', bendPointPositions);
      },
      // A function parameter to set bend point positions
      controlPointPositionsSetterFunction: function(ele: any, controlPointPositions: any) {
        ele.data('controlPointPositions', controlPointPositions);
      }
    });

    // Sometimes styles need a redraw in init
    setTimeout(() => {
      cy.style().update();
    }, 2500);
  }

  private registerTooltipClearHandler(cy: cytoscape.Core) {
    cy.on('cxttap', () => {
      if (this.tooltipTimeout) {
        clearTimeout(this.tooltipTimeout);
      }

      // @ts-ignore
      const tooltip = this.cytoscapeContainer?.nativeElement.querySelector('.edge-tooltip');

      if (tooltip) {
        // Remove the tooltip from the container
        // @ts-ignore
        this.cytoscapeContainer?.nativeElement.removeChild(tooltip);
      }
    });
  }

  validateResize() {
    return (e: ResizeEvent) => {
      // @ts-ignore
      return e.rectangle.width > this.drawerMinWidth && e.rectangle.width < this.drawerMaxWidth;
    };
  }

  async callUpdateMultiple(event: MultiUpdateEvent) {
    if (!this.cy) return;
    if (isEmpty(event.node) && isEmpty(event.link) && isEmpty(event.general)) return;
    const allSelected = this.cy.$(':selected');
    const nodeProperties = { ...event.general?.properties, ...event.node?.properties };
    const delay = (ms: number) => new Promise(res => setTimeout(res, ms));
    const multiUpdatePayload = {
      nodeIds: allSelected.filter((e: any) => e.isNode()).map((e: any) => e.id()),
      linkIds: allSelected.filter((e: any) => e.isEdge()).map((e: any) => e.id()),
      stateId: this.mapStateService.getCurrentLocalState()?.id as string,
      diff: {
        node: {
          ...event.general,
          ...event.node,
          properties: nodeProperties
        },
        link: { ...event.general, ...event.link }
      }
    };
    const linkDiff = multiUpdatePayload.diff.link;
    const linkDiffType = linkDiff?.type;

    //@ts-ignore
    const updateStream = this.nodeService.multiUpdate(multiUpdatePayload);
    const streamSubscribe = (res: any) => {
      this.selectedNodes = [];
      this.selectedNodes = res.nodes;
      this.selectedLinks = [];
      this.selectedLinks = res.links;

      if (res.nodes?.length > 0) {
        this.refreshNodes(res.nodes);
      }

      if (res.links?.length > 0) {
        this.refreshLinks(res.links);
      }
      Logger.log('Multi update sending update in progress signal - false');
      updateInProgress.set(false);
    };

    if (linkDiffType && linkDiffType !== 'BEZIER') {
      for (const linkId of multiUpdatePayload.linkIds) {
        const cyLink = this.cy.$('#' + linkId);
        cyLink.data('type', linkDiffType);
        cyLink.removeClass('ignore_control_points');
        this.resetAllAnchorPointsDistancesAndWeights(cyLink);
        await delay(300);
        const firstEdgeEditing = !this.hasAnyAnchorPoints(cyLink.data());
        if (firstEdgeEditing) {
          if (linkDiffType === 'STRAIGHT') {
            callAddAnchor(cyLink, 'bend');
          } else {
            callAddAnchor(cyLink, 'control');
          }
          await delay(300);
          if (cyLink.data('cyedgebendeditingDistances')?.length > 0) {
            linkDiff.cyedgebendeditingDistances = cyLink.data('cyedgebendeditingDistances');
            linkDiff.cyedgebendeditingWeights = cyLink.data('cyedgebendeditingWeights');
          } else if (cyLink.data('cyedgecontroleditingDistances')?.length > 0) {
            linkDiff.cyedgecontroleditingDistances = cyLink.data('cyedgecontroleditingDistances');
            linkDiff.cyedgecontroleditingWeights = cyLink.data('cyedgecontroleditingWeights');
          }
        } else {
        }
      }
      updateStream.subscribe(streamSubscribe);
    } else {
      if (linkDiffType === 'BEZIER') {
        for (const linkId of multiUpdatePayload.linkIds) {
          const cyLink = this.cy.$('#' + linkId);
          cyLink.addClass('ignore_control_points');
          this.resetAllAnchorPointsDistancesAndWeights(cyLink);
        }
      }
      await delay(300);
      updateStream.subscribe(streamSubscribe);
    }
  }

  private resetAllAnchorPointsDistancesAndWeights(cyLink: EdgeSingular) {
    cyLink.removeClass('edgecontrolediting-hascontrolpoints');
    cyLink.removeClass('edgebendediting-hasbendpoints');
    cyLink.removeClass('edgebendediting-hasmultiplebendpoints');
    cyLink.data('cyedgebendeditingDistances', []);
    cyLink.data('cyedgebendeditingWeights', []);
    cyLink.data('cyedgecontroleditingDistances', []);
    cyLink.data('cyedgecontroleditingWeights', []);
    cyLink.data('bendPointPositions', []);
    cyLink.data('controlPointPositions', []);
  }

  onResizeEnd(event: ResizeEvent): void {
    this.drawerWidth = event.rectangle.width as number;
    this.settingsService.storeSidebarWidth(this.drawerWidth);
    document.documentElement.style.setProperty('--sidebar-width', `${this.drawerWidth}px`);
  }

  selectMap() {
    this.mapService.openMapSheetSubject.next();
  }

  createNodeFromNoNodeMessage() {
    if (!this.cy) return;
    const bb = this.cy.extent();
    const centerX = (bb.x1 + bb.x2) / 2;
    const centerY = (bb.y1 + bb.y2) / 2;
    this.nodeService.createNodeSubject.next({ x: centerX, y: centerY });
  }

  private listenToGptGeneration(cy: cytoscape.Core) {
    this.gptService.generatedSubject.subscribe((gpt) => {
      if (!cy?.elements()) {
        return;
      }
      cy.elements()?.remove();
      this.isReadOnly = true;
      this.buildGptElements(gpt, cy);
      this.applyLayout(cy, LayoutTypes.COSE_BILKENT);
    });
  }

  resetState($event: { node?: Partial<GraphNode>, link?: Partial<GraphNodeLink> }) {
    const currentState = this.mapStateService.getCurrentLocalState();
    if (currentState?.id === undefined) return;

    if ($event.node) {
      //@ts-ignore
      if ($event?.node?.originalNode === undefined) {
        //@ts-ignore
        const data = this.cy.nodes('#' + $event.node.id).data();
        //@ts-ignore
        $event.node.originalNode = data;
      }
      if ($event.node.states && $event.node.states.length > 0) {
        const nodeToReset = $event.node as GraphNode;
        const stateIndex = $event.node.states.findIndex((s) => s.stateId === currentState?.id);
        if (stateIndex !== -1 && nodeToReset) {
          nodeToReset?.states?.splice(stateIndex, 1);
          //@ts-ignore
          if (nodeToReset.originalNode !== undefined) {
            //@ts-ignore
            nodeToReset.originalNode.states = nodeToReset.states;
          }
          const currentNodeToReplaceIndex = this.selectedNodes.findIndex((n) => n.id === nodeToReset.id);
          this.selectedNodes[currentNodeToReplaceIndex] = { ...nodeToReset };
          //@ts-ignore
          this.selectedNodes[currentNodeToReplaceIndex]['reset'] = true;
          this.updateNode(this.selectedNodes[currentNodeToReplaceIndex])
            .subscribe((res) => {
              if (!this.cy) return;
              this.cy.$('#' + nodeToReset.id).unselect();
              this.cy?.$('#' + res.id).select();
              DashboardComponent.tappedNode = null;
              DashboardComponent.tappedNode = res;
            });
        }
      }
    } else if ($event.link) {
      if ($event.link.states && $event.link.states.length > 0) {
        const linkToReset = $event.link as GraphNodeLink;
        const stateIndex = $event.link.states.findIndex((s) => s.stateId === currentState?.id);
        if (stateIndex !== -1 && linkToReset) {
          linkToReset?.states?.splice(stateIndex, 1);
          //@ts-ignore
          linkToReset.origin.states = linkToReset.states;
          const currentLinkToReplaceIndex = this.selectedLinks.findIndex((l) => l.id === linkToReset.id);
          this.selectedLinks[currentLinkToReplaceIndex] = { ...linkToReset };
          //@ts-ignore
          this.selectedLinks[currentLinkToReplaceIndex]['reset'] = true;
          this.updateLink(this.selectedLinks[currentLinkToReplaceIndex])
            .subscribe((res) => {
              if (!this.cy) return;
              this.cy.$('#' + res.id).unselect();
              this.cy?.$('#' + res.id).select();
            });
        }
      }

    }
  }

  public updateLink(link: GraphNodeLink, doUpdate = true) {
    let meta: MetaData[] = [];
    if (link?.metadata) {
      meta = link.metadata.map((m: MetaData) => {
        if (m.id) {
          return {
            id: m.id,
            key: m.key,
            value: m.value,
            order: m.order
          };
        } else {
          return {
            key: m.key,
            value: m.value
          } as MetaData;
        }
      });
    }
    let payload = {
      ...cloneDeep(link),
      type: (link.type || 'BEZIER') as string,
      shape: (link.shape || 'solid') as string,
      metadata: meta
    } as GraphNodeLink;
    return this.nodeService.updateLink(payload).pipe(
      tap((response) => {
        let res = response;
        if (doUpdate && !shouldSkipLinkDataUpdate()) {
          Logger.log('updating link');
          // @ts-ignore
          this.cy?.batch(() => {
            const edge = this.cy?.$('#' + res.id);
            edge?.data('name', res.name);
            edge?.data('description', res.description);
            edge?.data('label', res.label);
            edge?.data('color', res.color);
            edge?.data('weight', res.weight);
            edge?.data('type', res.type);
            edge?.data('hasComments', res.hasComments);
            edge?.data('textFontSize', res.textFontSize);
            edge?.data('direction', res.direction);
            edge?.data('excludedFromState', res.excludedFromState);
            edge?.data('controlPointPositions', res.controlPointPositions);
            edge?.data('bendPointPositions', res.bendPointPositions);
            if (res.states) {
              edge?.data('states', res.states);
            }
            edge?.data('shape', res.shape?.toLowerCase());
            edge?.data('metadata', res.metadata);
            if (edge?.data('type') !== 'BEZIER') {
              edge?.data('controlPointPositions', res.controlPointPositions);
              edge?.data('bendPointPositions', res.bendPointPositions);
            }
            if (DashboardComponent.tappedLink !== null && DashboardComponent.tappedLink.id === res.id) {
              this.tapElement(res, false, this.cy!);
            }
          });
        }
        this.nodeService.metaUpdatedSubject.next(res.metadata as MetaData[]);
        shouldSkipLinkDataUpdate.set(false);
      })
    );
  }

  private initButtPressService(cy: cytoscape.Core) {
    if (this.currentMap?.isReadonly) {
      if (this.copyPasteSub) {
        this.copyPasteSub.unsubscribe();
      }
      this.buttonPressService.ngOnDestroy();
    } else if (!this.copyPasteSub) {
      this.copyPasteSub = this.buttonPressService.init(cy)
        .subscribe((res) => {
          this.loadCyElements(cy, false, true, res);
        });
    }
  }

  private listenToLayoutChanges(cy: cytoscape.Core) {
    this.nodeService.layoutSubject.subscribe((type: LayoutTypes) => {
      this.applyLayout(cy, type);
    });
  }

  updateSelectedMapMetadata(map: GraphMap) {
    if (!this.currentMap) return;
    this.currentMap.metadata = map.metadata;
  }

  createAnchorsForLink(edge: EdgeSingular): { dataKey: string, anchorsArray: { x: number, y: number }[] } {
    return createNewAnchorsAndInitiateEdgeEditing(edge, this.edgeEditingInstance);
  }

  async callLinkUpdate($event: Partial<GraphNodeLink>) {
    updateInProgress.set(true);
    const cyLink = this.cy?.$('#' + $event.id) as EdgeSingular;
    if (cyLink.data('deleted')) {
      return;
    }
    let hasLinkTypeChanged = $event.type !== DashboardComponent.tappedLink?.type;
    if (DashboardComponent.tappedLink === undefined || DashboardComponent.tappedLink === null) {
      hasLinkTypeChanged = false;
    }

    this.nodeService.getOneLink($event.id as string)
      .pipe(
        switchMap((link) => {

          let payload = {
            ...cloneDeep($event)
          };
          if (hasLinkTypeChanged) {
            payload = this.handleEdgeTypeChangeWithAnchorPoints($event, payload);
          }
          return this.updateLink(payload as GraphNodeLink);
        })
      )
      .subscribe({
        complete: () => {
          setTimeout(() => {
            updateInProgress.set(false);
          }, 1000);
        },
        error: () => {
          setTimeout(() => {
            updateInProgress.set(false);
          }, 1000);
        }
      });
  }

  private handleEdgeTypeChangeWithAnchorPoints($event: Partial<GraphNodeLink>, payload: Partial<GraphNodeLink>): Partial<GraphNodeLink> {
    const cyLink = this.cy?.$('#' + $event.id) as EdgeSingular;
    cyLink.data('type', $event.type);
    const anchorsData = this.createAnchorsForLink(cyLink as EdgeSingular);
    if (anchorsData.dataKey === 'bendPointPositions') {
      payload['controlPointPositions'] = [];
      payload[anchorsData.dataKey] = anchorsData.anchorsArray;
    } else if (anchorsData.dataKey === 'controlPointPositions') {
      payload['bendPointPositions'] = [];
      payload[anchorsData.dataKey] = anchorsData.anchorsArray;
    } else if (anchorsData.dataKey === 'NO_DATA') {
      payload['controlPointPositions'] = [];
      payload['bendPointPositions'] = [];
    }

    return payload;
  }

  private listenToNodeCreate(cy: cytoscape.Core) {
    this.nodeService.loadSubject.subscribe((n) => {
      cy.nodes().unselect();
      this.selectedNodes = [];

      setTimeout(() => {
        const newPan = this.cyService.getVisibleViewportPan(true) || { x: 0, y: 0 };
        const node = cy.add({
          group: 'nodes',
          data: {
            ...this.addCyNode(n, null) as any
          },
          classes: 'expanded',
          position: { x: n.x || newPan.x, y: n.y || newPan?.y }
        });
        this.hasNodesNoNodes = false;
        node.data('isNew', true);
        node.select();
        DashboardComponent.tappedNode = node.data();
        if (!this.createFromMenu) {
          //this.centerOnTarget(cy, node);
        }
        this.createFromMenu = false;
      }, 100);

    });
  }


  private handleLocalSettings() {
    document.documentElement.style.setProperty('--sidebar-width', `${this.drawerWidth}px`);
    timer(300)
      .subscribe(async () => {
        const localStorageSettings = JSON.parse(localStorage.getItem(SETTINGS_STORAGE_KEY) as string);
        this.isSideBarOpen = localStorageSettings?.isSidebarOpen && !localStorageSettings?.forceOpened || false;
        if (localStorageSettings?.isSidebarOpen) {
          this.drawerWidth = localStorageSettings?.sidebarWidth || DefaultSidebarWidth;
          await this.drawer?.open();
        } else {
          const drawerElement = document.getElementsByClassName('drawer')[0] as HTMLElement;
          this.lastKnownSidebarWidth = drawerElement?.offsetWidth;
          await this.drawer?.close();
        }
        document.documentElement.style.setProperty('--sidebar-width', `${this.drawerWidth}px`);
      });
  }

  private handleCanvasUpdateForLabelMarginOnDrag(cy: cytoscape.Core) {
    cy.on('drag', 'node', (e: any) => {
      const draggedNode = e.target;

      // For each connected edge of the dragged node, trigger a dummy data change to force a recalculation
      draggedNode.connectedEdges().forEach((edge: any) => {
        const currentData = edge.data('label');
        edge.data('label', currentData);
      });
    });
  }

  private buildGptElements(data: GptMapResponse, cy: any) {
    data.nodes.forEach((node: any) => {
      cy.add({
        group: 'nodes',
        data: {
          id: node.id,
          name: node.name,
          label: 'name',
          color: node.color || DefaultValues.node.color,
          properties: DefaultNodeProperties
        }
      });
    });
    data.links.forEach((link: any) => {
      cy.add({
        group: 'edges',
        data: {
          id: link.id,
          label: link.label || 'name',
          source: link.from,
          target: link.to,
          textFontSize: link.textFontSize,
          direction: link.direction || 'directed',
          name: link.name || '',
          color: link.color || 'black'
        }
      });
    });
  }

  private resetAllSelected(cy: cytoscape.Core) {
    this.selectedNodes = [];
    this.selectedLinks = [];
    DashboardComponent.tappedLink = null;
    DashboardComponent.tappedNode = null;
    DashboardComponent.multiSelectedElements = [];
    if (!cy?.elements()) {
      return;
    }
    cy.elements().forEach((n) => {
      n.removeClass('highlighted');
      n.removeData('selected');
      n.removeClass('multi');
      n.unselect();
    });
  }

  private listenToMapStateChanges(state: MapStateDto | null = null) {
    if (!this.cyService.cy) {
      return;
    }
    const timeout = 400;
    updateInProgress.set(true);
    const cy = this.cyService.cy;
    const selectedElementIdsBeforeSwitch = cy.$(':selected').map((n: any) => n.id());
    const focusedBeforeSwitch = {
      link: DashboardComponent?.tappedLink?.id,
      node: DashboardComponent?.tappedNode?.id
    };
    this.appWideStateService.canvasLoadedSignal.set(false);
    this.cyService.resetAllSelected();
    cy.elements().remove();
    setTimeout(async () => {
      if (!this.isLoading) {
        await this.loadCyElements(cy, false);
      }
      if (selectedElementIdsBeforeSwitch.length > 0 && !this.mapStateService.getSceneChangeProgressSignal()) {
        setTimeout(() => {
          cy.elements(selectedElementIdsBeforeSwitch.map(id => `#${id}`).join(', ')).select();
          // select first element as tapped element
          const first = cy.elements(':selected').first();
          if (focusedBeforeSwitch.link || focusedBeforeSwitch.node) {
            const focusedElement = cy.getElementById(focusedBeforeSwitch?.link as string || focusedBeforeSwitch?.node as string);
            this.tapElement(focusedElement.data(), focusedElement.isNode(), cy);
          } else {
            this.tapElement(first.data(), first.isNode(), cy);
          }
        }, timeout);
      } else {
      }
      Logger.log('ListenToMapStateChange sending update in progress signal - false');
      updateInProgress.set(false);
    }, timeout);
  }

  private saveGptNodes() {
    if (!this.cy) return;
    const nodes = this.cy.nodes().map((n) => {
      const payload = n.data();
      return {
        ...payload,
        map: this.currentMap,
        x: n.position().x,
        y: n.position().y
      };
    });
    const links = this.cy.edges().map((e: any) => {
      return {
        ...e.data(),
        from: e.data('source'),
        to: e.data('target'),
        map: this.currentMap
      };
    });

    this.nodeService.createMultiple(nodes)
      .pipe(
        switchMap((nodes) => {
          return this.nodeService.createMultipleLinks(links);
        })
      )
      .subscribe((n) => {
        location.reload();
      });
  }

  private updateChildPositionRecursively(child: {
    x: number,
    y: number,
    id: string,
    children: any[]
  }, positionDifference: { x: number; y: number; }) {
    if (!this.cy) return;
    child.x += positionDifference.x;
    child.y += positionDifference.y;

    // Update the child's position on the server
    this.nodeService.updateNodePosition(child.id, child.x, child.y)
      .subscribe((node) => this.updateNodeLinksControlPoints(node));
    if (child.children && child.children.length > 0) {
      child.children.forEach(c => this.updateChildPositionRecursively(c, positionDifference));
    }
  }

  private updateNodeLinksControlPoints(node: GraphNode) {
    const nodeLinks = this.cy?.edges().filter((e) => e.data('source') === node.id || e.data('target') === node.id);
    if (nodeLinks) {
      nodeLinks.forEach((link) => {
        let linkData = link.data();
        linkData = this.updateLinksPointPositionsData(linkData);
        this.updateLink(linkData).subscribe();
      });
    }
  }

  private toggleEdgeVisibility(cy: Core, deselect = false) {
    setTimeout(() => {
      const edgeLocalEdgeViewMode = this.settingsService.linkViewMode;
      if (edgeLocalEdgeViewMode === LinkViewMode.RELATED) {
        if (deselect && cy.elements(':selected').data() == undefined) {
          cy.edges().addClass('hidden');
        } else {
          cy.edges().not(':selected').addClass('hidden');
          setTimeout(() => {
            cy.edges(':selected').removeClass('hidden');
          }, 200);
        }
        this.selectedNodes.forEach((node: any) => {
          const cyNode = cy.getElementById(node.id as string);
          cyNode.edgesWith('*').removeClass('hidden');
        });
      }

      if (edgeLocalEdgeViewMode === LinkViewMode.OFF && deselect && cy.edges(':selected').data() == undefined) {
        cy.edges().addClass('hidden');
      } else if (edgeLocalEdgeViewMode === LinkViewMode.OFF) {
        cy.edges().removeClass('hidden');
      }
    }, 300);

  }

  private handleNodePositionChange(cy: cytoscape.Core) {

    cy.on('free', 'node', (event: any) => {
      if (!this.currentMap?.isReadonly) {
        const distanceChangeTolerance = 1;
        // check how far the position has changed
        const currentPosition = { ...event.target.position() };
        const grabbedPosition = event.target.data('grabbedPosition');
        const positionDifference = {
          x: currentPosition.x - grabbedPosition.x,
          y: currentPosition.y - grabbedPosition.y
        };
        const distance = Math.sqrt(Math.pow(positionDifference.x, 2) + Math.pow(positionDifference.y, 2));
        if (distance < distanceChangeTolerance) {
          return;
        }

        const node = event.target;
        if (!node.data('touched')) {
          node.data('touched', true);
        }

        if (node.data('collapsed')) {
          const currentPosition = { ...node.position() };
          const grabbedPosition = node.data('grabbedPosition');
          const positionDifference = {
            x: currentPosition.x - grabbedPosition.x,
            y: currentPosition.y - grabbedPosition.y
          };
          const children = node.data('children');

          if (children && children.length > 0) {
            children
              .forEach((child: any) => this.updateChildPositionRecursively(child, positionDifference));
          }

          this.nodeService.updateNodePosition(node.id(), currentPosition.x, currentPosition.y)
            .subscribe((node) => this.updateNodeLinksControlPoints(node));
        } else {
          // Logic for standalone nodes or expanded compound nodes if any
          const position = node.position();
          this.nodeService.updateNodePosition(node.id(), position.x, position.y)
            .subscribe((node) => this.updateNodeLinksControlPoints(node));
        }

      }
    });
  }

  private tapElement(data: GraphNode | GraphNodeLink, isNode: boolean, cy: cytoscape.Core) {
    const targetData = data;
    const isDefaultState = !this.mapStateService.getCurrentLocalState()?.id;
    let statedElement: any;

    if (!isDefaultState) {
      statedElement = targetData.states?.find((s: any) => s.stateId === this.mapStateService.getCurrentLocalState()?.id);
    }

    if (ButtonPressService.isShiftPressed && !get(targetData, 'isNew')) {
      const thingsToPushToMultiSelect = [];
      if (this.selectedNodes.length === 1) {
        thingsToPushToMultiSelect.push(this.selectedNodes[0]);
      }
      if (this.selectedLinks.length === 1) {
        thingsToPushToMultiSelect.push(this.selectedLinks[0]);
      }
      thingsToPushToMultiSelect.push(targetData);

      DashboardComponent.multiSelectedElements = uniqWith([...DashboardComponent.multiSelectedElements, ...thingsToPushToMultiSelect], isEqual);
    }

    if (DashboardComponent.multiSelectedElements.length > 0 && !DashboardComponent.multiSelectedElements.find((e: any) => e.id === targetData.id)) {
      DashboardComponent.tappedNode = null;
      DashboardComponent.tappedLink = null;
      this.resetAllSelected(cy);
      if (targetData?.id || statedElement?.link || statedElement?.node) {

        const newElementToSelect = isNode ? targetData || statedElement.node : targetData || statedElement.link;
        if (isNode) {
          DashboardComponent.tappedNode = newElementToSelect as GraphNode;
        } else {
          DashboardComponent.tappedLink = newElementToSelect as GraphNodeLink;
        }
      }
      return;
    }

    if (targetData?.id || statedElement?.link || statedElement?.node) {
      if (isNode || statedElement?.node) {
        DashboardComponent.tappedLink = null;
        if (statedElement) {
          const statedNode = { ...statedElement.node } as any;
          const originalNode = {
            id: targetData.id,
            states: targetData.states
          };
          statedNode.originalNode = originalNode as unknown as StateLessNode;
          statedNode.states = targetData.states;
          DashboardComponent.tappedNode = statedNode;
        } else {
          DashboardComponent.tappedNode = targetData as GraphNode;
        }
      } else {
        DashboardComponent.tappedNode = null;
        if (statedElement) {
          const statedLink = { ...statedElement.link } as any;
          const originalLink = {
            id: targetData.id,
            states: targetData.states
          };
          statedLink.origin = originalLink as unknown as StateLessLink;
          statedLink.states = targetData.states;
          DashboardComponent.tappedLink = statedLink;
        } else {
          DashboardComponent.tappedLink = targetData as GraphNodeLink;
        }
      }
    }

    if (!targetData?.id) {
      this.resetAllSelected(cy);
    }
  }

  private handleElementTap(cy: cytoscape.Core) {
    cy.on('tap', (event) => {
      this.tapElement(event.target.data(), event?.target?.isNode !== undefined && event.target.isNode(), cy);
    });
  }

  centerCanvas() {
    if (this.cyService.cy && !this.isCentering) {
      this.isCentering = true;
      this.cyService.centerCanvas(false);
      setTimeout(() => this.isCentering = false, 300);
    }
  }

  //TODO: Possible duplicate , see cyservice
  private centerOnTarget(cy: Core, ele: any) {
    const sidebarElement = document.getElementsByClassName('drawer')[0] as HTMLElement;
    const toolbarElement = document.getElementsByClassName('navbar')[0] as HTMLElement;

    const sidebarWidth = sidebarElement?.offsetWidth || 0;
    const toolbarHeight = toolbarElement?.offsetHeight || 0;
    const isSidebarToggled = sidebarWidth > 0;

    const viewportSize = {
      width: isSidebarToggled ? cy.width() - sidebarWidth : cy.width(),
      height: cy.height() + toolbarHeight
    };

    let targetPosition;

    if (ele.isNode()) {
      targetPosition = ele.position();
    } else if (ele.isEdge()) {
      const sourcePos = ele.source().position();
      const targetPos = ele.target().position();

      targetPosition = {
        x: (sourcePos.x + targetPos.x) / 2,
        y: (sourcePos.y + targetPos.y) / 2
      };
    }
    const currentPan = cy.pan();
    const currentZoom = cy.zoom();

    const zoomedNodePosition = {
      x: targetPosition.x * currentZoom,
      y: targetPosition.y * currentZoom
    };

    const newPan = {
      x: currentPan.x - (zoomedNodePosition.x + currentPan.x - viewportSize.width / 2),
      y: currentPan.y - (zoomedNodePosition.y + currentPan.y - viewportSize.height / 2)
    };

    cy.animate({
      pan: newPan,
      duration: 500,
      easing: 'ease'
    });
    this.isCentering = false;
  }

  private centerOnElements(cy: Core, elements: any) {
    this.cyService.centerOnElements(cy, elements);
  }

  private handleNodeLinking(cy: cytoscape.Core) {
    // @ts-ignore
    cy.on('ehcomplete', (event, sourceNode, targetNode, addedEdge) => {
      console.log(cloneDeep(addedEdge.data()));
      this.cyService.linkingCompleteSubject.next(true);
      this.isChangingLinkSource = false;
      const currentState = this.mapStateService.getCurrentLocalState();
      const isDefaultState = !currentState?.id;
      if (!this.isUnlinking && !this.currentMap?.isReadonly) {
        addedEdge.data('name', '');
        addedEdge.data('label', '');
        addedEdge.data('color', DefaultValues.link.color);
        addedEdge.data('weight', 1);
        addedEdge.data('type', 'BEZIER');
        addedEdge.data('textFontSize', DefaultValues.link.textFontSize);
        addedEdge.data('shape', 'solid');
        const from = { ...sourceNode.data() };
        const to = { ...targetNode.data() };
        delete from.collapsedChildren;
        delete to.collapsedChildren;

        this.nodeService.linkNodes(from, to)
          .pipe(
            delay(200)
          )
          .subscribe((res) => {

            const updateLinkOnCanvas = () => {
              const linkEndpoint = this.linkEndpointSignal();
              if (linkEndpoint !== null) {
                if (linkEndpoint.point === 'source') {
                  res.sourceEndpoint = linkEndpoint.direction;
                } else {
                  res.targetEndpoint = linkEndpoint.direction;
                }
              }
              const link = this.addCyLink(cy, res);
              if (!link) return;
              targetNode.unselect();
              link.data('isNew', true);
              link.data('metadata', []);
              this.selectedLinks = [];
              link.select();
              DashboardComponent.tappedLink = link.data();
              DashboardComponent.tappedNode = null;
              addedEdge.remove();
              return link;
            };
            cy.elements().unselect();
            if (!isDefaultState) {
              const state = {
                stateId: currentState?.id as string,
                link: {
                  id: res.id,
                  from: from.id,
                  to: to.id,
                  name: '',
                  label: '',
                  color: DefaultValues.link.color,
                  weight: 1,
                  type: 'BEZIER',
                  textFontSize: DefaultValues.link.textFontSize,
                  shape: 'solid'
                }
              };
              res.states = [state] as any;
              this.nodeService.updateLink(res).subscribe(() => {
                const link = updateLinkOnCanvas();
                if (!link) return;
                link.data('isNotDefaultState', true);
                link.data('states', [state]);
                DashboardComponent.tappedLink = link.data();
                DashboardComponent.tappedNode = null;
              });

            } else {
              const link = updateLinkOnCanvas();
              // @ts-ignore
              DashboardComponent.tappedLink = link.data();
              DashboardComponent.tappedNode = null;
              if (res) {
                const sourceNode = cy.$('#' + res.from.id);
                if (sourceNode) {
                  sourceNode.data('links') === undefined ? sourceNode.data('links', [res]) : sourceNode.data('links').push(res);
                }
              }
            }
            DashboardComponent.tappedNode = null;
            this.isSideBarOpen = true;
          });
      }
    });
    // @ts-ignore
    cy.on('ehcancel', (event, source, canceledTarget) => {
      this.isUnlinking = false;
      this.isChangingLinkSource = false;
      this.loadAllForMap(cy, false);
    });

    // @ts-ignore
    cy.on('ehstart', (event) => {
      // @ts-ignore
      this.eh.update(this.cyService.getMousePosition());
    });
  }

  private async loadCyElements(cy: cytoscape.Core,
                               refreshLayout = true,
                               paste = false,
                               nodes?: GraphNode[]) {
    if (this.hasNoMap) return;
    if (this.isLoading) return;
    this.isLoading = true;

    if (!nodes) {
      this.loadAllForMap(cy, refreshLayout);
    } else {
      this.renderCyElements(cy, nodes, refreshLayout, paste);
    }
  }

  private loadAllForMap(cy: Core, refreshLayout = true) {
    const stream = this.readModeAppState ?
      this.nodeService.getPublicNodeForMap(this.currentMap?.id as string) :
      this.nodeService.getAllForMap(this.currentMap?.id as string);
    stream
      .pipe(
        catchError((err) => {
          this.isLoading = false;
          return throwError(err);
        })
      )
      .subscribe((res) => {
        if (cy) {
          this.renderCyElements(cy, res, refreshLayout);
          // Update labels on position change
          cy.on('render', () => {
            cy.nodes().forEach(node => {
              const boundingBox = node.boundingBox({ includeLabels: true });
              const position = { x: (boundingBox.x1 + boundingBox.x2) / 2, y: boundingBox.y1 }; // Label position
              const labelDiv = document.querySelector(`.node-label[data-id="${node.id()}"]`) as any;
              if (labelDiv) {
                labelDiv.style.left = `${position.x}px`;
                labelDiv.style.top = `${position.y}px`;
              }
            });
          });
        }
      });
  }

  callNodeUpdate($event: Partial<GraphNode>) {
    updateInProgress.set(true);
    BlockUiInterceptor.isLoadingSubject.next(false);
    this.updateNode($event)
      .pipe(
        finalize(() => {
          updateInProgress.set(false);
        })
      )
      .subscribe();
  }

  private addNodeToParent(parent: cytoscape.NodeSingular, position?: { x: number, y: number }) {

    const parentBB = parent.boundingBox();
    const centerPositionFromParent = {
      x: parentBB.x1 + (parentBB.w / 2),
      y: parentBB.y1 + (parentBB.h / 2)
    };
    const node: Partial<GraphNode> = {
      name: '',
      parent: parent.data().id,
      weight: 1,
      type: 'ELLIPSE',
      x: position ? position.x : centerPositionFromParent.x,
      y: position ? position.y : centerPositionFromParent.y,
      map: this.currentMap,
      color: DefaultValues.node.color,
      properties: DefaultNodeProperties
    };
    this.nodeService.create(node as GraphNode).subscribe((res) => {
      if (res) {
        this.cy?.batch(() => {
          const newNode = this.cy?.add({
            // @ts-ignore
            group: 'nodes',
            // @ts-ignore
            data: {
              id: res.id,
              children: res.children,
              // @ts-ignore
              name: res.name,
              label: res.name,
              parent: res.parent,
              properties: res.properties,
              type: res.type,
              position: { x: res.x as number, y: res.y as number },
              color: res.color,
              weight: res.weight,
              isNew: true
            }
          });
          if (parent.data('collapsed')) {
            //@ts-ignore
            const api = this.cy.expandCollapse('get');
            parent.removeClass('collapsed');
            parent.addClass('expanded');
            parent.data('collapsed', false);
            api.expand(parent);
          }
          newNode?.position({ x: res.x as number, y: res.y as number });
          newNode?.move({ parent: parent.id() });

          // @ts-ignore
          this.cy.elements().unselect();
          // @ts-ignore
          this.resetAllSelected(this.cy);
          this.nodes = [];
          this.nodes.push(res);
          setTimeout(() => {
            newNode?.select();
            DashboardComponent.tappedNode = newNode?.data();
          }, 100);
        });
      }
    });
  }

  //TODO: Target for refactoring
  private renderCyElements(cy: Core,
                           res: GraphNode[],
                           refreshLayout = true,
                           paste = false) {
    if (!cy) return;
    if (!paste) {
      destroyPanZoom(cy);
    }
    const fadeInNodes = (complete: () => void) => {
      cy.nodes().animate({ style: { opacity: 1 } }, { duration: 300, complete });
    };
    //@ts-ignore
    if (res) {
      const doCyBatch = async () => {
        for (const rootNode of res) {
          await this.loadChildNodesRecursively(cy, rootNode);
          this.moveChildNodesToParentRecursively(cy, rootNode);
        }
        await this.loadAllLinks(cy, res);
        const isDefaultMapState = this.mapStateService.isDefaultState();
        const allNodes = cy.nodes();
        allNodes.forEach((node: any) => {
          if (node.data('collapsed')) {
            //@ts-ignore
            const expandCollapse = cy.expandCollapse('get');
            expandCollapse.collapse(node);
            node.addClass('collapsed');
          }
          if (!isDefaultMapState && node.data('states')) {
            const currentStateId = this.mapStateService.getCurrentLocalState()?.id;
            const nodeState = node.data('states').find((s: any) => s.stateId === currentStateId);

            if (nodeState && nodeState.node.x !== undefined) {
              node.position({ x: nodeState.node.x, y: nodeState.node.y });
              node.data('position', { x: nodeState.node.x, y: nodeState.node.y });
            }
          }
        });
        if (this.settingsService.linkViewMode !== LinkViewMode.OFF) {
          this.toggleEdgeVisibility(cy);
        }

        setTimeout(() => {
          const centerMostNode = this.cyService.getCenterMostNode(this.drawerWidth);
          if (centerMostNode) {
            const bb = centerMostNode.renderedBoundingBox({
              includeLabels: true,
              includeOverlays: true
            });
            const width = bb.w;
            const height = bb.h;
            const topLeft = this.cyService.getNodePagePosition(centerMostNode);
            const isExpandedParent = centerMostNode.data('collapsed') === false && centerMostNode.data('children')?.length > 0;
            if (topLeft) {
              document.documentElement.style.setProperty('--tour-anchor-left', `${isExpandedParent ? topLeft.left - 45 : topLeft.left + 4}px`);
              document.documentElement.style.setProperty('--tour-anchor-top', `${isExpandedParent ? topLeft.top - 80 : topLeft.top - 32}px`);
              document.documentElement.style.setProperty('--tour-anchor-width', `${width + 10}px`);
              document.documentElement.style.setProperty('--tour-anchor-height', `${height + 10}px`);
            }
          }
        }, 2000);

      };
      if (cy) {
        cy.batch(doCyBatch);
      } else {
        setTimeout(() => {
          //@ts-ignore
          cy.batch(doCyBatch);
        }, 300);
      }

      if (refreshLayout && cy) {
        this.applyLayout(cy);
      }
    }

    if (this.nodes.length === 0 && !paste) {
      cy.zoom(5);
    }
    drawGradientNode(cy);
    this.isLoading = false;
    if (!paste) {
      storePanZoomDynamic(cy);
    }
    this.viewService.viewHasLoaded.next(true);
    if (!paste) {
      let isRestoring = false;
      fadeInNodes(() => {
        if (!isRestoring) {
          //@ts-ignore
          if (this.currentMap?.isNew) {
            this.centerCanvas();
          } else {
            setTimeout(() => {
              restorePanZoomDynamic(this.cyService, refreshLayout);
            }, 300);
          }
          isRestoring = true;
        }
        setTimeout(() => {
          this.appWideStateService.setCanvasLoaded(true);
        }, 1500);
      });
    }

    setTimeout(() => {
      this.appWideStateService.canvasLoadedSignal.set(true);
      this.appWideStateService.setCanvasLoaded(true);
    }, 1500);
  }

  private async initCtxMenu2(cy: cytoscape.Core, edgeHandles: any) {
    if (this.hasNoMap) return;
    let toursMenu: any = await this.toursService.getTourCtxMenuItems();
    let stateMenu: any = await this.mapStateService.getCtxMenuItems();
    let viewMenu: any = await this.viewService.getCtxMenuItems();
    const isDev = this.packageService.isDev();
    const options = () => {
      const menu = {
        // Customize event to bring up the context menu
        // Possible options https://js.cytoscape.org/#events/user-input-device-events
        evtType: 'cxttap',
        submenuIndicator: { src: 'assets/mat-symbols/outlined/chevron_right.svg', width: 12, height: 12, x: 5, y: 6 },
        // List of initial menu items
        // A menu item must have either onClickFunction or submenu or both
        menuItems: (this.currentMap?.isReadonly || isInIframe()) ?
          // READ ONLY MENU
          [
            {
              id: 'center_canvas',
              content: 'Center',
              // tooltipText: 'Center Canvas',
              image: { src: 'assets/mat-symbols/outlined/center_focus_weak.svg', width: 12, height: 12, x: 5, y: 6 },
              coreAsWell: true,
              onClickFunction: () => {
                this.centerCanvas();
              },
              hasTrailingDivider: true
            },
            {
              id: 'Collapse',
              content: 'Collapse',
              selector: 'node.expanded:parent',
              image: { src: 'assets/mat-symbols/outlined/unfold_less.svg', width: 12, height: 12, x: 5, y: 6 },
              // tooltipText: 'Collapse node',
              onClickFunction: (e: any) => {
                const cyNodes = this.selectedNodes.map((n) => cy.getElementById(n.id as string));
                this.cyService.collapseMultipleNodes(cyNodes, false, false)
                  .pipe(
                    switchMap((res) => {
                      if (res) {
                        return this.updateNode(res, true);
                      } else {
                        return of(null);
                      }
                    })
                  )
                  .subscribe();
              }
            },
            {
              id: 'Expand',
              content: 'Expand',
              selector: 'node.collapsed',
              image: { src: 'assets/mat-symbols/outlined/unfold_more.svg', width: 12, height: 12, x: 5, y: 6 },
              // tooltipText: 'Expand node',
              onClickFunction: (e: any) => {
                const cyNodes = this.selectedNodes.map((n) => cy.getElementById(n.id as string));
                this.cyService.expandMultipleNodes(cyNodes, false, false)
                  .subscribe();
              },
              hasTrailingDivider: true
            },
            {
              id: 'Center',
              content: 'Center',
              selector: 'node',
              image: { src: 'assets/mat-symbols/outlined/center_focus_weak.svg', width: 12, height: 12, x: 5, y: 6 },
              // tooltipText: 'Center on Node',
              hasTrailingDivider: true,
              onClickFunction: (event: any) => {
                const selectedElements = cy.$(':selected');
                invalidateGeneralPanZoom();
                invalidatePanZoomForCollapsedNode();
                if (selectedElements.length > 0) {
                  this.centerOnElements(cy, selectedElements);
                } else {
                  this.centerOnTarget(cy, event.target);
                }
              }
            },
            {
              id: 'center_on_source',
              content: 'Center Source',
              image: { src: 'assets/mat-symbols/outlined/line_start.svg', width: 12, height: 12, x: 5, y: 6 },
              selector: 'edge',
              onClickFunction: (event: any) => this.centerOnTarget(cy, event.target.source())
            },
            {
              id: 'center_on_target',
              content: 'Center Target',
              selector: 'edge',
              image: { src: 'assets/mat-symbols/outlined/line_end.svg', width: 12, height: 12, x: 5, y: 6 },
              onClickFunction: (event: any) => this.centerOnTarget(cy, event.target.target())
            },
            {
              id: 'center_canvas_2',
              content: 'Center',
              // tooltipText: 'Center Canvas',
              image: { src: 'assets/mat-symbols/outlined/center_focus_weak.svg', width: 12, height: 12, x: 5, y: 6 },
              selector: 'edge',
              hasTrailingDivider: true,
              onClickFunction: (event: any) => {
                this.centerOnTarget(cy, event.target);
              }
            }
          ].filter(Boolean)
          // READ ONLY MENU END
          :
          [
            // CANVAS MENU
            {
              id: 'create_node',
              content: 'Create Node',
              // tooltipText: 'Create Node',
              image: { src: 'assets/mat-symbols/outlined/add.svg', width: 12, height: 12, x: 5, y: 6 },
              coreAsWell: true,
              hasTrailingDivider: true,
              onClickFunction: (event: any) => {
                const position = event.position;
                this.createFromMenu = true;
                this.resetAllSelected(cy);
                this.nodeService.createNodeSubject.next({ x: position.x, y: position.y });
              }
            },
            {
              id: 'undo',
              content: 'Undo',
              // tooltipText: 'Paste',
              image: { src: 'assets/mat-symbols/outlined/undo.svg', width: 12, height: 12, x: 5, y: 6 },
              coreAsWell: true,
              hasTrailingDivider: true,
              hasLeadingDivider: true,
              onClickFunction: (event: any) => {
                //@ts-ignore
                this.cy.undoRedo().undo();
              }
            },
            {
              id: 'paste',
              content: 'Paste',
              // tooltipText: 'Paste',
              image: { src: 'assets/mat-symbols/outlined/content_paste.svg', width: 12, height: 12, x: 5, y: 6 },
              coreAsWell: true,
              hasTrailingDivider: true,
              onClickFunction: (event: any) => {
                this.buttonPressService.copyFromClipboard(event.target.data()?.id ? event.target.data() : undefined);
              }
            },
            {
              id: 'center_canvas',
              content: 'Center',
              // tooltipText: 'Center Canvas',
              image: { src: 'assets/mat-symbols/outlined/center_focus_weak.svg', width: 12, height: 12, x: 5, y: 6 },
              coreAsWell: true,
              hasTrailingDivider: true,
              onClickFunction: () => {
                invalidateGeneralPanZoom();
                invalidatePanZoomForCollapsedNode();
                this.centerCanvas();
              }
            },
            // CANVAS MENU END

            // Node Menu starts here
            {
              id: 'add-node',
              content: 'Create Node',
              // tooltipText: 'Create node',
              image: { src: 'assets/mat-symbols/outlined/add.svg', width: 12, height: 12, x: 5, y: 6 },
              selector: 'node',
              onClickFunction: (event: any) => {
                const ele = event.target;
                if (ele.group() === 'nodes') {
                  this.addNodeToParent(ele, event.position);
                }
              }
            },
            {
              id: 'Link',
              content: 'Create Link',
              selector: 'node',
              image: { src: 'assets/mat-symbols/outlined/linear_scale.svg', width: 12, height: 12, x: 5, y: 6 },
              // tooltipText: 'Create Link',
              hasTrailingDivider: true,
              onClickFunction: function(event: any) {
                edgeHandles.start(event.target);
              }
            },
            {
              id: 'undoNode',
              content: 'Undo',
              selector: 'node',
              // tooltipText: 'Paste',
              image: { src: 'assets/mat-symbols/outlined/undo.svg', width: 12, height: 12, x: 5, y: 6 },
              hasTrailingDivider: true,
              hasLeadingDivider: true,
              onClickFunction: (event: any) => {
                //@ts-ignore
                this.cy.undoRedo().undo();
              }
            },
            {
              id: 'cut',
              content: 'Cut',
              // tooltipText: 'Cut',
              image: { src: 'assets/mat-symbols/outlined/content_cut.svg', width: 12, height: 12, x: 5, y: 6 },
              selector: 'node',
              onClickFunction: (event: any) => {
                event.target.select();
                this.buttonPressService.cutSelected(cy);
              }
            },
            {
              id: 'copy',
              content: 'Copy',
              // tooltipText: 'Copy',
              image: { src: 'assets/mat-symbols/outlined/content_copy.svg', width: 12, height: 12, x: 5, y: 6 },
              selector: 'node',
              onClickFunction: (event: any) => {
                event.target.select();
                this.buttonPressService.copySelected(cy);
              }
            },
            {
              id: 'paste_node',
              content: 'Paste',
              // tooltipText: 'Paste',
              image: { src: 'assets/mat-symbols/outlined/content_paste.svg', width: 12, height: 12, x: 5, y: 6 },
              selector: 'node',
              onClickFunction: (event: any) => {
                this.buttonPressService.copyFromClipboard(event.target.data()?.id ? event.target.data() : undefined);
              }
            },
            {
              id: 'Remove_node',
              content: 'Delete',
              selector: 'node',
              image: { src: 'assets/mat-symbols/outlined/delete.svg', width: 12, height: 12, x: 5, y: 6 },
              hasTrailingDivider: true,
              onClickFunction: (event: any) => {
                const ele = event.target;
                const allSelected = cy.$(':selected');
                const nodes = cy.$('node:selected');
                const edges = cy.$('edge:selected');

                if (allSelected.length > 1) {
                  this.undoService.emitRemove({ ids: allSelected.map((n: any) => n.data('id')) });
                  // @ts-ignore
                  for (const node of allSelected) {
                    this.nodeService.deleteNode(node.data('id'))
                      .subscribe(() => {
                        node.addClass('display-none');
                        const hasNodes = cy.nodes().length > 0;
                        if (!hasNodes) {
                          this.hasNodesNoNodes = true;
                        }
                      });
                  }
                } else {
                  const childrenIds = ele.children() ? ele.children().map((c: {
                    id: () => any;
                  }) => c.id()) : undefined;
                  this.nodeService.deleteNode(ele.data('id'))
                    .subscribe(() => {
                      this.undoService.emitRemove({ ids: [ele.data('id'), ...childrenIds] });
                      ele.addClass('display-none');
                      ele.move({ parent: null });
                      const hasNodes = cy.nodes().length > 0;
                      if (!hasNodes) {
                        this.hasNodesNoNodes = true;
                      }
                    });
                }
                this.selectedNodes = [];
                DashboardComponent.tappedNode = null;
                DashboardComponent.tappedLink = null;

                nodes.data('selected', false);
                edges.data('selected', false);
                nodes.removeClass('highlighted');
                edges.removeClass('highlighted');

                allSelected.unselect();
              }
            },
            {
              id: 'Expand',
              content: 'Expand',
              selector: 'node.collapsed',
              image: { src: 'assets/mat-symbols/outlined/unfold_more.svg', width: 12, height: 12, x: 5, y: 6 },
              // tooltipText: 'Expand node',
              onClickFunction: (event: any) => {
                const cyNodes = this.selectedNodes.map((n) => cy.getElementById(n.id as string));
                this.cyService.expandMultipleNodes(cyNodes, true, false)
                  .subscribe((res) => {
                    if (res) {
                      this.updateNode(res, true).subscribe();
                    }
                  });
              }
            },
            {
              id: 'Collapse',
              content: 'Collapse',
              selector: 'node:parent',
              image: { src: 'assets/mat-symbols/outlined/unfold_less.svg', width: 12, height: 12, x: 5, y: 6 },
              // tooltipText: 'Collapse node',
              onClickFunction: (event: any) => {
                const cyNodes = this.selectedNodes.map((n) => cy.getElementById(n.id as string));
                this.cyService.collapseMultipleNodes(cyNodes, true, false)
                  .pipe(
                    switchMap((res) => {
                      if (res) {
                        return this.updateNode(res, true);
                      } else {
                        return of(null);
                      }
                    })
                  )
                  .subscribe();
              }
            },
            {
              id: 'Center',
              content: 'Center',
              selector: 'node',
              image: { src: 'assets/mat-symbols/outlined/center_focus_weak.svg', width: 12, height: 12, x: 5, y: 6 },
              // tooltipText: 'Center on Node',
              hasTrailingDivider: true,
              onClickFunction: (event: any) => {
                invalidatePanZoomForCollapsedNode();
                invalidateGeneralPanZoom();
                const selectedElements = cy.$(':selected');
                if (selectedElements.length > 0) {
                  this.centerOnElements(cy, selectedElements);
                } else {
                  this.centerOnTarget(cy, event.target);
                }
              }
            },
            {
              id: 'Move_Up',
              content: 'Extract from Container',
              selector: 'node',
              disabled: this.mapStateService.getCurrentLocalState()?.id !== undefined,
              // tooltipText: 'Move the node 1 level up',
              image: { src: 'assets/mat-symbols/outlined/step_out.svg', width: 12, height: 12, x: 5, y: 6 },
              onClickFunction: (ele: any) => {
                if (ele.target.group() === 'nodes') {
                  const selectedElements = cy.$('node:selected');
                  if (selectedElements.length > 0) {
                    // filter out nodes that are in selection and children of a node in the selection
                    const nodesToMove = selectedElements.filter((node: NodeSingular) => {
                      const parent = node.parent();
                      return !selectedElements.contains(parent);
                    });
                    from(nodesToMove)
                      .pipe(
                        switchMap((node: NodeSingular) => {
                          return this.moveNodeOneLevelUp(node);
                        })
                      )
                      .subscribe();
                  } else {
                    this.moveNodeOneLevelUp(ele.target).subscribe();
                  }
                }
              }
            },
            {
              id: 'Move_Top',
              content: 'Extract to Canvas',
              selector: 'node',
              // tooltipText: 'Move the node to root level',
              image: { src: 'assets/mat-symbols/outlined/vertical_align_top.svg', width: 12, height: 12, x: 5, y: 6 },
              hasTrailingDivider: true,
              disabled: this.mapStateService.getCurrentLocalState()?.id !== undefined,
              onClickFunction: (ele: any) => {
                if (ele.target.group() === 'nodes') {
                  const selectedElements = cy.$('node:selected');
                  if (selectedElements.length > 0) {
                    // filter out nodes that are in selection and children of a node in the selection
                    const nodesToMove = selectedElements.filter((node: NodeSingular) => {
                      const parent = node.parent();
                      return !selectedElements.contains(parent);
                    });
                    from(nodesToMove)
                      .pipe(
                        switchMap((node: NodeSingular) => {
                          return this.removeNodeFromParent(node);
                        })
                      ).subscribe();
                  } else {
                    this.removeNodeFromParent(ele.target).subscribe();
                  }
                }
              }
            },
            // Edge context menu
            {
              id: 'Remove',
              content: 'Delete',
              selector: 'edge',
              image: { src: 'assets/mat-symbols/outlined/delete.svg', width: 12, height: 12, x: 5, y: 6 },
              hasTrailingDivider: true,
              onClickFunction: (event: any) => {
                updateInProgress.set(true);
                const selected = cy.$(':selected');
                const remove = (ele: EdgeSingular) => {
                  const edgeData = ele.data();
                  this.nodeService.deleteLink(ele.id()).subscribe(() => {
                    ele.unselect();
                    ele.addClass('display-none');
                    ele.data('deleted', true);
                    const remainingLinks = cy.edges().filter((e) => !e.data('deleted'));
                    // remainingLinks.style('control-point-distances', '');
                    cy.resize();
                    // if deleted edge is in selected links, remove it
                    this.selectedLinks = this.selectedLinks.filter((link) => link.id !== edgeData.id);
                    // if selectedLink has only one element and the element is the one that is deleted, empty the array
                    if (this.selectedLinks.length === 1 && this.selectedLinks[0].id === edgeData.id) {
                      this.selectedLinks = [];
                    }
                    //If it is in DashboardComponent.tappedLink, remove it
                    if (DashboardComponent.tappedLink?.id === edgeData.id) {
                      DashboardComponent.tappedLink = null;
                    }
                    updateInProgress.set(false);
                  });

                };
                if (selected.length > 1) {
                  selected.forEach((ele: EdgeSingular) => {
                    remove(ele);
                  });
                } else {
                  remove(event.target);
                }

              }
            },
            {
              id: 'center_on_source',
              content: 'Center Source',
              image: { src: 'assets/mat-symbols/outlined/line_start.svg', width: 12, height: 12, x: 5, y: 6 },
              selector: 'edge',
              onClickFunction: (event: any) => {
                const selectedEdges = cy.$('edge:selected');
                if (selectedEdges.length > 0) {
                  const sources = selectedEdges.map((edge: EdgeSingular) => edge.source());
                  const selector = sources.map(s => `#${s.id()}`).join(', ');
                  const elements = cy.$(selector);
                  // @ts-ignore
                  this.centerOnElements(cy, elements);
                } else {
                  this.centerOnTarget(cy, event.target.source());
                }
              }
            },
            {
              id: 'center_on_target',
              content: 'Center Target',
              selector: 'edge',
              image: { src: 'assets/mat-symbols/outlined/line_end.svg', width: 12, height: 12, x: 5, y: 6 },
              onClickFunction: (event: any) => {
                const selectedEdges = cy.$('edge:selected');
                if (selectedEdges.length > 0) {
                  const targets = selectedEdges.map((edge: EdgeSingular) => edge.target());
                  const selector = targets.map(s => `#${s.id()}`).join(', ');
                  const elements = cy.$(selector);
                  // @ts-ignore
                  this.centerOnElements(cy, elements);
                } else {
                  this.centerOnTarget(cy, event.target.target());
                }
              }
            },
            {
              id: 'center_canvas_2',
              content: 'Center',
              // tooltipText: 'Center Canvas',
              image: { src: 'assets/mat-symbols/outlined/center_focus_weak.svg', width: 12, height: 12, x: 5, y: 6 },
              selector: 'edge',
              hasTrailingDivider: true,
              onClickFunction: (event: any) => {
                const selectedEdges = cy.$(':selected');
                if (selectedEdges.length > 0) {
                  this.centerOnElements(cy, selectedEdges);
                } else {
                  this.centerOnTarget(cy, event.target);
                }
              }
            },

            {
              id: 'Change Link Source',
              content: 'Change Source',
              disabled: this.mapStateService.getCurrentLocalState()?.id !== undefined,
              selector: 'edge',
              // tooltipText: 'Change Link Source',
              image: { src: 'assets/mat-symbols/outlined/line_start_circle.svg', width: 12, height: 12, x: 5, y: 6 },
              onClickFunction: (event: any) => {
                const ele = event.target;
                const edge = ele as unknown as cytoscape.EdgeSingular;
                const source = edge.target() as cytoscape.NodeSingular;
                const edgeData = edge.data();
                delete edgeData.source;

                edge.remove();
                this.isUnlinking = true;
                this.isChangingLinkSource = true;
                // @ts-ignore
                edgeHandles.start(source);
                // @ts-ignore
                const linkEvent = (event, sourceNode, targetNode, addedEdge) => {
                  if (!this.isUnlinking && !this.isChangingLinkSource) return;
                  let newEdgeData = addedEdge.data();
                  newEdgeData = { ...newEdgeData, ...edgeData };
                  addedEdge.data(newEdgeData);
                  addedEdge.style('line-color', newEdgeData.color);
                  addedEdge.style('target-arrow-color', newEdgeData.color);
                  const payload: GraphNodeLink = {
                    from: targetNode.data().id,
                    to: sourceNode.data().id,
                    type: newEdgeData.type,
                    shape: newEdgeData.shape,
                    id: newEdgeData.id,
                    metadata: newEdgeData.metadata,
                    textFontSize: newEdgeData.textFontSize,
                    name: newEdgeData.name,
                    direction: newEdgeData.direction || DefaultValues.link.direction,
                    color: newEdgeData.color,
                    weight: newEdgeData.weight
                  };


                  this.nodeService.updateLink(payload).subscribe((res) => {
                    const newLink = this.addCyLink(cy, res);
                    if (!newLink) return;
                    addedEdge.remove();
                    DashboardComponent.tappedLink = null;
                    DashboardComponent.tappedNode = null;
                    cy.elements().unselect();
                    DashboardComponent.tappedLink = newLink.data();
                    this.initAnchors([newLink], newLink.data('type'));
                    newLink.select();
                    this.isUnlinking = false;
                    // @ts-ignore
                    cy.off('ehcomplete', linkEvent);
                  });
                };
                // @ts-ignore
                cy.on('ehcomplete', linkEvent);
              }
            },
            {
              selector: 'edge',
              id: 'Change_Target',
              content: 'Change Target',
              disabled: this.mapStateService.getCurrentLocalState()?.id !== undefined,
              image: { src: 'assets/mat-symbols/outlined/line_end_circle.svg', width: 12, height: 12, x: 5, y: 6 },
              onClickFunction: (event: any) => {
                const ele = event.target;
                const edge = ele as unknown as cytoscape.EdgeSingular;
                const source = edge.source() as cytoscape.NodeSingular;
                const edgeData = edge.data();
                delete edgeData.target;

                edge.remove();
                this.isUnlinking = true;
                // @ts-ignore
                edgeHandles.start(source);
                // @ts-ignore
                const linkEvent = (event, sourceNode, targetNode, addedEdge) => {
                  if (!this.isUnlinking) return;
                  let newEdgeData = addedEdge.data();
                  newEdgeData = { ...newEdgeData, ...edgeData };
                  addedEdge.data(newEdgeData);
                  addedEdge.style('line-color', newEdgeData.color);
                  addedEdge.style('target-arrow-color', newEdgeData.color);
                  const payload: GraphNodeLink = {
                    from: sourceNode.data().id,
                    to: targetNode.data().id,
                    type: newEdgeData.type,
                    shape: newEdgeData.shape,
                    id: newEdgeData.id,
                    textFontSize: newEdgeData.textFontSize,
                    direction: newEdgeData.direction || DefaultValues.link.direction,
                    metadata: newEdgeData.metadata,
                    name: newEdgeData.name,
                    weight: newEdgeData.weight,
                    color: newEdgeData.color,
                    controlPointPositions: newEdgeData.controlPointPositions,
                    bendPointPositions: newEdgeData.bendPointPositions
                  };
                  this.nodeService.updateLink(payload).subscribe((res) => {
                    const newLink = this.addCyLink(cy, res);
                    if (!newLink) return;
                    addedEdge.remove();
                    DashboardComponent.tappedLink = null;
                    DashboardComponent.tappedNode = null;
                    cy.$(':selected').unselect();
                    DashboardComponent.tappedLink = newLink.data();
                    newLink.select();
                    this.initAnchors([newLink], newLink.data('type'));
                    this.isUnlinking = false;
                    // @ts-ignore
                    cy.off('ehcomplete', linkEvent);
                  });
                };
                // @ts-ignore
                cy.on('ehcomplete', linkEvent);
              }
            },
            {
              id: 'Reverse Direction',
              content: 'Reverse',
              selector: 'edge',
              disabled: this.mapStateService.getCurrentLocalState()?.id !== undefined,
              // tooltipText: 'Reverses Link source and target',
              image: { src: 'assets/mat-symbols/outlined/multiple_stop.svg', width: 12, height: 12, x: 5, y: 6 },
              onClickFunction: (event: any) => {
                const linksToUpdate: any[] = [];
                const reverseLink = (edge: EdgeSingular) => {
                  const edgeData = cloneDeep(edge.data());
                  const source = edgeData.source;
                  const target = edgeData.target;
                  edgeData.source = target;
                  edgeData.target = source;

                  linksToUpdate.push({
                    from: target,
                    to: source,
                    ...edgeData
                  });
                };

                const selectedEdges = cy.$('edge:selected');

                if (selectedEdges.length > 0) {
                  selectedEdges.forEach((ele) => reverseLink(ele));
                  const newLinks: any[] = [];
                  from(linksToUpdate).pipe(
                    mergeMap((link) => this.nodeService.updateLink(link)),
                    concatMap((res) => {
                      newLinks.push(this.addCyLink(cy, res));
                      return timer(100);
                    })
                  ).subscribe(() => {
                    newLinks.forEach((ele: any, index) => {
                      const edgeType = ele.data('type');
                      let edgeEditingType = edgeType === 'BEZIER' ? 'none' : edgeType === 'STRAIGHT' ? 'bend' : 'control';
                      if (edgeEditingType === 'bend') {
                        ele.data('bendPointPositions', reverse(ele.data('bendPointPositions')));
                      } else if (edgeEditingType === 'control') {
                        ele.data('controlPointPositions', reverse(cloneDeep(ele.data('controlPointPositions'))));
                      }
                      this.edgeEditingInstance.initAnchorPoints(newLinks, edgeEditingType);
                      this.nodeService.updateLink(ele.data()).subscribe();
                      ele.select();
                    });

                  });
                } else {
                  reverseLink(event.target);
                  this.nodeService.updateLink(linksToUpdate[0] as any)
                    .subscribe((res) => {
                      this.addCyLink(cy, res);
                      this.isUnlinking = false;
                    });
                }
                this.isUnlinking = true;
              },
              hasTrailingDivider: true
            }

          ]
      };

      // @ts-ignore
      if (stateMenu !== null) {
        menu.menuItems.push(stateMenu);
      }

      // @ts-ignore
      if (viewMenu !== null) {
        menu.menuItems.push(viewMenu);
      }
      // @ts-ignore
      if (toursMenu !== null) {
        menu.menuItems.push(toursMenu);
      }


      return menu;
    };

    const originalContextMenus = cy.contextMenus;
    cy.contextMenus = function(action) {
      const originalResult = originalContextMenus.call(this, action);
      if (action === 'get') {
        //@ts-ignore
        originalResult.getOptions = function() {
          return options();
        };
      }
      return originalResult;
    };
    //@ts-ignore
    this.contextMenuInstance = cy.contextMenus(options());

    if (this.menuSubscription) {
      this.menuSubscription.unsubscribe();
    }

    this.menuSubscription = CytoscapeService.menuChangedSubject.subscribe(async () => {
      this.contextMenuInstance.destroy();
      viewMenu = await this.viewService.getCtxMenuItems();
      toursMenu = await this.toursService.getTourCtxMenuItems();
      stateMenu = this.appWideStateService.readMode() ? null : await this.mapStateService.getCtxMenuItems();

      //@ts-ignore
      this.contextMenuInstance = cy.contextMenus(options());
    });

    cy.on('cxttapstart', 'node, edge', (event: any) => {
      const target = event.target.data();
      if (!target?.metadata) return;
      const urls = target?.metadata.filter((m: any) => {
        try {
          new URL(m.value);
          return true;
        } catch {
          return false;
        }
      });
      const buildMenu = () => {
        return {
          id: 'Urls',
          content: 'Open Resource',
          selector: 'edge,node',
          tooltipText: 'Open Resource',
          image: { src: 'assets/mat-symbols/outlined/language.svg', width: 12, height: 12, x: 5, y: 6 },
          submenu: urls.map((url: MetaData) => {
            return {
              id: url.id,
              content: url.key,
              selector: 'edge,node',
              tooltipText: url.key,
              onClickFunction: () => {
                const settings = this.settingsService.getLocalStorageSettings();
                // @ts-ignore
                const linkTarget = settings.linkTarget * 1 ?? LinkTarget.SingleWindow;
                DatatifyDirective.onLinkClick(null, url.value, linkTarget);
              }
            };
          }),
          hasTrailingDivider: true
        };
      };

      const loadMenus = () => {
        try {
          this.contextMenuInstance.removeMenuItem('Urls');
        } catch (e) {
          // we dont care
        }
        try {
          //@ts-ignore
          this.contextMenuInstance.appendMenuItem(buildMenu(), undefined);
        } catch (e) {
          // we dont care
        }
      };

      try {
        this.contextMenuInstance.removeMenuItem('Urls');
      } catch (e) {
        // we dont care
      }


      if (urls.length > 0) {
        if (target?.direction !== undefined) {
          loadMenus();
        } else {
          loadMenus();
        }
      }

    });
  }

  private initAnchors(edges: EdgeSingular[], type: string) {
    this.cyService.initAnchors(edges, type);
  }

  private async loadChildNodesRecursively(cy: cytoscape.Core, rootNode: GraphNode) {
    if (!rootNode) return;
    // Check if cytoscape already has the element
    const nodeExists = cy.$(`#${rootNode.id}`).length > 0;
    if (nodeExists) return;

    cy.add({
      group: 'nodes',
      data: {
        ...this.addCyNode(rootNode, rootNode.parent as GraphNode),
        isSaved: true
      },
      classes: 'expanded',
      position: { x: rootNode?.x || 0, y: rootNode?.y || 0 }
    });

    if (rootNode.children && rootNode.children.length > 0) {
      for (const child of rootNode.children) {
        await this.loadChildNodesRecursively(cy, child);
      }
    }

  }

  private moveChildNodesToParentRecursively(cy: cytoscape.Core, rootNode: GraphNode) {
    if (!rootNode.children) return;
    rootNode.children.forEach((child: GraphNode) => {
      //@ts-ignore
      cy.nodes('#' + child.id).move({ parent: rootNode.id });
      if (child.children && child.children.length > 0) {
        this.moveChildNodesToParentRecursively(cy, child);
      }
    });
  }

  updateNode($event: Partial<GraphNode>, collapseExpand = false, ignoreUiBlock = false) {

    const payload = {
      ...$event
    };
    // @ts-ignore
    delete payload['collapsedChildren'];
    payload.children?.forEach((c) => {
      // @ts-ignore
      delete c['collapsedChildren'];
    });
    if (collapseExpand) {
      delete payload['children'];
    }
    // @ts-ignore
    return this.nodeService.updateNode(payload as GraphNode, ignoreUiBlock)
      .pipe(
        tap((res) => {
          // @ts-ignore
          this.cy?.batch(() => {
            const node = this.cy?.$('#' + res.id);
            // @ts-ignore
            node?.data('name', res.name);
            node?.data('description', res.description);
            node?.data('collapsed', res.collapsed);
            node?.data('color', res.color);
            node?.data('label', res.name);
            node?.data('imageUrl', res.imageUrl);
            node?.data('metadata', res.metadata);
            node?.data('hasComments', res.hasComments);
            node?.data('properties', res.properties);
            node?.data('excludedFromState', res.excludedFromState);
            if (res.states) {
              node?.data('states', res.states);
            }
            node?.data('type', res.type);
            node?.data('weight', res.weight);
            const nodeIndex = this.nodes.findIndex((n) => n.id === res.id);
            this.nodes[nodeIndex] = res;
            const currentState = this.mapStateService.getCurrentLocalState();
            // @ts-ignore
            if (DashboardComponent?.tappedNode?.id === res.id && currentState?.id === undefined) {
              DashboardComponent.tappedNode = res;
            }
          });
          this.nodeService.metaUpdatedSubject.next(res.metadata as MetaData[]);
        })
      );
  }

  private getNodeDataBasedOnState(node: NodeSingular): GraphNode {
    const currentMapState = this.mapStateService.getCurrentLocalState();
    const isDefaultState = currentMapState?.id === undefined;
    let nodeData = node.data() as GraphNode;
    if (!isDefaultState) {
      const getStatedNode = nodeData.states?.find((s: NodeStateData) => s.stateId === currentMapState?.id);
      if (getStatedNode) {
        nodeData = getStatedNode?.node as unknown as GraphNode;
      } else {
        nodeData = node.data() as GraphNode;
      }
    }
    return nodeData;
  }

  private hasLinkDataControlOrSegmentPoints(data: GraphNodeLink): boolean {
    //@ts-ignore
    return data?.bendPointPositions?.length > 0 || data?.controlPointPositions?.length > 0;
  }
  private getLinkDataBasedOnState(link: EdgeSingular, tappedLink?: GraphNodeLink | null): GraphNodeLink {
    const currentMapState = this.mapStateService.getCurrentLocalState();
    const isDefaultState = currentMapState?.id === undefined;
    let linkData = tappedLink || link.data() as GraphNodeLink;
    if (!isDefaultState) {
      const getStatedLink = linkData.states?.find((s: LinkStateData) => s.stateId === currentMapState?.id);
      if (getStatedLink) {
        linkData = getStatedLink?.link as unknown as GraphNodeLink;
      } else {
        linkData = link.data() as GraphNodeLink;
      }
    }
    return linkData;
  }

  private addCyNode(c: GraphNode, p: GraphNode | null): NodeDataDefinition {
    return {
      id: c.id,
      label: c.name,
      type: c.type,
      parent: p?.id || undefined,
      name: c.name,
      description: c.description,
      weight: c.weight,
      links: c.links,
      excludedFromState: c.excludedFromState,
      metadata: c.metadata,
      imageUrl: c.imageUrl,
      properties: c.properties,
      states: c.states,
      collapsed: c.collapsed,
      children: c.children,
      hasComments: c.hasComments,
      position: { x: c?.x || 0, y: c?.y || 0 },
      color: c?.color || '#696969',
      touched: c?.x !== 0 || c?.y !== 0
    };
  }

  private applyLayout(cy: cytoscape.Core, type: LayoutTypes = LayoutTypes.PRESET) {
    switch (type) {
      case LayoutTypes.PRESET:
        this.applyPresetLayout(cy);
        break;
      case LayoutTypes.CONCENTRIC:
        this.applyConcentricLayout(cy);
        break;
      case LayoutTypes.COSE_BILKENT:
        this.applyCoseBilkentLayout(cy);
        break;
    }
    // @ts-ignore
    this.cyLayout.run();
  }

  private applyPresetLayout(cy: cytoscape.Core) {
    if (!cy.layout) {
      return;
    }
    this.cyLayout = cy.layout({
      name: 'preset', positions: (e: any) => {
        return {
          x: e.data('position').x,
          y: e.data('position').y
        };
      }
    });
  }

  private listenToMapChanges(cy: cytoscape.Core, eh: any, map: GraphMap | undefined) {
    destroyPanZoom(cy);
    if (!this.hasNoMap && this.currentMap?.id === map?.id) {
      return;
    }
    this.currentMap = map;
    this.isReadOnly = map?.isReadonly || false;
    this.selectedNodes = [];
    this.selectedLinks = [];
    this.initButtPressService(cy);

    this.cy?.autolock(this.currentMap?.isReadonly || isInIframe());
    this.hasNoMap = this.mapService.hasNoStoredMap();
    if (this.contextMenuInstance) {
      this.contextMenuInstance.destroy();
    }
    if (!this.hasNoMap && cy) {
      this.initCtxMenu2(cy, eh).then();
    }
    this.cy?.nodes().unselect();
    this.cy?.edges().unselect();
    this.cy?.remove('*');
    this.loadCyElements(cy);

    // @ts-ignore
    if (map?.isNew) {
      const localSettings = this.settingsService.getLocalStorageSettings();
      localSettings.isEditMode = true;
      this.sidebarService.enableEditMode();
      this.settingsService.setLocalStorageSettings(localSettings);
      if (!localSettings.isSidebarOpen) {
        this.settingsService.toggleSidebar();
      }
      setTimeout(() => {
        const node = cy.nodes().first();
        if (node) {
          node.data('isNew', true);
          node.select();
          this.tapElement(node.data(), true, cy);
        }
        //@ts-ignore
        map.isNew = false;
        this.mapService.setCurrentSelectedMapToStore(map);
      }, 1000);
    }
  }

  private updateElementsCommentsFlag() {
    this.hasCommentsPollingSub.unsubscribe();
    this.hasCommentsPollingSub = new Subscription();
    this.hasCommentsPollingSub.add(
      timer(0, 6 * 10 * 1000)
        .pipe(
          switchMap(() => this.nodeService.getElementHasComments(this.currentMap!.id))
        )
        .subscribe((res) => {
          if (!this.cy) return;
          this.cy.batch(() => {
            res.elements.forEach((element) => {
              if (!this.cy) return;
              const cyElement = this.cy.$(`#${element.id}`);
              cyElement.data('hasComments', element.hasComments);
            });
          });
        })
    );
  }

  private initDrawMode(cy: cytoscape.Core) {
    // the default values of each option are outlined below:
    const defaults = {
      canConnect: function(sourceNode: { same: (arg0: any) => any; }, targetNode: any) {
        // whether an edge can be created between source and target
        return !sourceNode.same(targetNode); // e.g. disallow loops
      },
      edgeParams: function(sourceNode: any, targetNode: any, i: any) {
        // for edges between the specified source and target
        // return element object to be passed to cy.add() for edge
        return {};
      },
      handleColor: '#000000', // the colour of the edge handle
      previewColor: '#000000', // the colour of the handle's preview
      hoverDelay: 50, // time spent hovering over a target node before it is considered selected
      snap: false, // when enabled, the edge can be drawn by just moving close to a target node (can be confusing on compound graphs)
      snapThreshold: 1, // the target node must be less than or equal to this many pixels away from the cursor/finger
      snapFrequency: 30, // the number of times per second (Hz) that snap checks done (lower is less expensive)
      noEdgeEventsInDraw: true, // set events:no to edges during draws, prevents mouseouts on compounds
      disableBrowserGestures: true // during an edge drawing gesture, disable browser gestures such as two-finger trackpad swipe and pinch-to-zoom
    };

    // @ts-ignore
    return cy['edgehandles'](defaults);
  }

  private applyConcentricLayout(cy: cytoscape.Core) {
    this.cyLayout = cy.layout({
      name: 'concentric', fit: true,
      animate: true,
      avoidOverlap: true,
      levelWidth: () => {
        return 1;
      }
    });
  }

  private applyCoseBilkentLayout(cy: cytoscape.Core) {
    this.cyLayout = cy.layout({
      name: 'cose-bilkent'
    });
  }

  private initCy() {
    return cytoscape({
      container: this.cytoscapeContainer?.nativeElement,
      maxZoom: CytoscapeService.MAX_ZOOM_LEVEL,
      minZoom: CytoscapeService.MIN_ZOOM_LEVEL,
      wheelSensitivity: CytoscapeService.ZOOM_SENSITIVITY,
      autolock: this.currentMap?.isReadonly || isInIframe(),
      warnings: false,
      style: [
        {
          selector: 'node',
          style: {
            'background-color': (node: any) => {
              const nodeData = this.getNodeDataBasedOnState(node);

              const color = nodeData?.color || DefaultValues.node.color;
              const nodeOpacity = nodeData?.properties?.opacity;
              if (nodeOpacity === 0 || nodeOpacity === '0') return '#fff';
              const trueOpacity = nodeOpacity !== undefined && nodeOpacity !== null ? nodeOpacity as number : DefaultValues.node.properties.opacity as number;
              return chroma(color)
                .brighten((1 - trueOpacity) * DefaultValues.brightnessMultiplier)
                .desaturate(1 - trueOpacity)
                .hex();
            },
            'shape': (node: any) => {
              const data = this.getNodeDataBasedOnState(node) as any;
              return data.type?.toLowerCase() || 'ellipse';
            },
            'background-image': (node: any) => {
              const data = this.getNodeDataBasedOnState(node) as any;

              return data.imageUrl ? environment.apiUrl + '/public/image?url=' + data.imageUrl : 'default';
            },

            'background-image-opacity': (node: any) => {
              const data = this.getNodeDataBasedOnState(node) as any;

              const defaultOpacity = this.settingsService.getLocalStorageSettings()?.defaultImageOpacity || DefaultNodeProperties.imageOpacity;
              const propertyOpacity = data.properties?.imageOpacity;
              const opacity = propertyOpacity !== undefined && propertyOpacity !== null ? propertyOpacity : defaultOpacity;
              return opacity > 1 ? 1 : opacity;
            },
            'background-fit': 'cover',
            'width': (node: NodeSingular) => {
              const data = this.getNodeDataBasedOnState(node) as any;

              const scale = (data.properties?.scale === '0' || data.properties?.scale === 0) ? 0.25 : data.properties?.scale;
              return scale ? scale * 10 : 10;
            },
            'height': (node: NodeSingular) => {
              const data = this.getNodeDataBasedOnState(node) as any;

              const scale = (data.properties?.scale === '0' || data.properties?.scale === 0) ? 0.25 : data.properties?.scale;
              return scale ? scale * 10 : 10;
            },
            'border-style': 'solid',
            'border-width': (node: any) => {
              const data = this.getNodeDataBasedOnState(node) as any;

              const weight = data.weight;
              return weight !== undefined && weight !== null ? weight * DefaultValues.weightMultiplier + 'px' : '0px';
            },
            'border-color': (node: NodeSingular) => {
              const nodeData = this.getNodeDataBasedOnState(node);
              return nodeData?.color || DefaultValues.node.color;
            },
            'opacity': (node: NodeSingular) => {
              const currentStateNode = this.getNodeDataBasedOnState(node);
              if (currentStateNode.excludedFromState) {
                return 0.4;
              } else {
                return 1;
              }
            },
            'z-index': 3,
            'visibility': (node: NodeSingular) => {
              const isEditMode = this.sidebarService.getEditModeSignal();
              if (!isEditMode || isInIframe() || this.isReadOnly) {
                const currentStateNode = this.getNodeDataBasedOnState(node);
                return currentStateNode.excludedFromState ? 'hidden' : 'visible';
              } else {
                return 'visible';
              }
            }
          }
        },
        {
          selector: 'node:parent',
          style: {
            'shape': 'roundrectangle',
            'background-color': (node: NodeSingular) => {
              const data = this.getNodeDataBasedOnState(node) as any;

              const color = data.color || DefaultValues.node.color;
              const nodeOpacity = data.properties?.opacity;
              if (nodeOpacity === 0 || nodeOpacity === '0') return '#fff';
              const trueOpacity = nodeOpacity !== undefined && nodeOpacity !== null ? nodeOpacity : DefaultValues.node.properties.opacity;
              return chroma(color)
                .brighten((1 - trueOpacity) * DefaultValues.brightnessMultiplier)
                .desaturate(1 - trueOpacity)
                .hex();
            },
            'background-image': (node: NodeSingular) => {
              const data = this.getNodeDataBasedOnState(node) as any;

              return data.imageUrl ? environment.apiUrl + '/public/image?url=' + data.imageUrl : 'default';
            }
          }
        },
        {
          selector: 'node.grabbed',
          style: {
            opacity: () => {
              return SettingsService.sharedValues.node.nodeDragOpacity;
            }
          }
        },
        {
          'selector': 'node[label]',
          'style': {
            'label': (node: NodeSingular) => {
              const data = this.getNodeDataBasedOnState(node) as any;
              let name = '';
              if (data.properties?.label === 'name' || data.metadata === null) {
                name = data.name || '';
              } else {
                name = data.metadata.find((p: any) => p.key === data.properties?.label)?.value || '';
              }
              const hasSomeUrlMetaValue = data.metadata?.some((m: any) => {
                try {
                  new URL(m.value);
                  return true;
                } catch {
                  return false;
                }
              });
              if (hasSomeUrlMetaValue) {
                name = StatusIcons.HAS_LINKS + '\u00A0' + name;
              }
              if (data.hasComments) {
                name = name + '\u00A0' + StatusIcons.HAS_COMMENTS;
              }
              return name;
            },
            'font-family': 'Roboto, "Material Icons"',
            'text-valign': (node: NodeSingular) => {
              const data = this.getNodeDataBasedOnState(node) as any;
              return data?.properties?.textValign || 'center';
            },
            'text-rotation': (node: NodeSingular) => {
              const data = this.getNodeDataBasedOnState(node) as any;
              const angle = data?.properties?.textRotation || 0;
              return (angle * Math.PI) / 180;
            },
            'text-halign': (node: NodeSingular) => {
              const data = this.getNodeDataBasedOnState(node) as any;
              return data.properties?.textHalign || 'center';
            },
            'text-margin-y': (node: NodeSingular) => {
              const data = this.getNodeDataBasedOnState(node) as any;

              let val = data?.properties?.textMarginY;
              const isNotEmpty = val !== undefined && val !== null;

              if (data.properties?.textValign === 'top' && isNotEmpty) {
                val = val * -1;
              }

              return isNotEmpty ? val : DefaultValues.node.properties.textMarginY;
            },
            'text-margin-x': (node: NodeSingular) => {
              const data = this.getNodeDataBasedOnState(node) as any;

              let val = data?.properties?.textMarginX;
              const isNotEmpty = val !== undefined && val !== null;
              if (data.properties?.textHalign === 'left' && isNotEmpty && val !== 0) {
                val = val * -1;
              }
              return val !== undefined && val !== null ? val : DefaultValues.node.properties.textMarginX;
            },
            'font-size': (node: NodeSingular) => {
              const data = this.getNodeDataBasedOnState(node) as any;

              const size = data?.properties?.textFontSize;
              return size !== undefined && size !== null && size !== '' ? size : DefaultValues.node.properties.textFontSize;
            },
            'text-wrap': 'wrap',
            'text-max-width': '100px'
          }
        },
        {
          selector: 'edge',
          style: {
            //@ts-ignore
            'curve-style': (element) => {
              const data = this.getLinkDataBasedOnState(element);
              //@ts-ignore
              return EdgeTypeMapCy[data.type || 'BEZIER'];
            },
            'target-arrow-shape': (element: EdgeSingular) => {
              const data = this.getLinkDataBasedOnState(element);
              const direction = data.direction;
              return (direction !== 'undirected' || direction === undefined) ? 'triangle' : 'none';
            },
            'source-arrow-shape': (element: EdgeSingular) => {
              const data = this.getLinkDataBasedOnState(element);

              const direction = data.direction;
              return (direction === 'bidirected') ? 'triangle' : 'none';
            },
            'line-color': (link: EdgeSingular) => {
              const data = this.getLinkDataBasedOnState(link);
              return data?.color || DefaultValues.link.color;
            },
            'target-arrow-color': (link: EdgeSingular) => {
              const data = this.getLinkDataBasedOnState(link);

              return data?.color || DefaultValues.link.color;
            },
            'source-arrow-color': (link: EdgeSingular) => {
              const data = this.getLinkDataBasedOnState(link);

              return data?.color || DefaultValues.link.color;
            },
            'arrow-scale': (link: EdgeSingular) => {
              const data = this.getLinkDataBasedOnState(link);
              const isGhostNode = Object.keys(data)?.length === 3;

              return data.weight as number >= 1 ? data.weight as number / 2 :
                isGhostNode ? 0.5 : 0.3;
            },
            // 'z-compound-depth': 'bottom',
            'width': (link: EdgeSingular) => {
              const data = this.getLinkDataBasedOnState(link);
              const isGhostNode = Object.keys(data)?.length === 3;
              return data.weight as number >= 1 ?
                data.weight as number :
                isGhostNode ? 1 : 0.1;
            },
            'visibility': (link: EdgeSingular) => {
              const isEditMode = this.sidebarService.getEditModeSignal();
              const currentState = this.mapStateService.getCurrentLocalState();
              if (currentState?.id === undefined) {
                return 'visible';
              }
              if (!isEditMode || isInIframe() || this.isReadOnly) {
                const data = this.getLinkDataBasedOnState(link);
                return data.excludedFromState ? 'hidden' : 'visible';
              } else {
                return 'visible';
              }
            },
            'target-distance-from-node': (edge: EdgeSingular): number => {
              return 0;
              const data = this.getLinkDataBasedOnState(edge);
              if (!this.hasLinkDataControlOrSegmentPoints(data)) {
                return 0;
              }
              const target = edge.target();
              if (!target.isParent()) {
                // Fallback for leaf nodes
                return 0;
              }

              const boundingBox = target.boundingBox({
                includeLabels: false,
                includeNodes: true
              });

              // Center of the bounding box
              const cx = (boundingBox.x1 + boundingBox.x2) / 2;
              const cy = (boundingBox.y1 + boundingBox.y2) / 2;

              // Safely get the target arrow angle
              let tgtArrowAngle = get(edge, '_private.rscratch.tgtArrowAngle', 0) as number;
              // Normalize the angle to [0, 2π]
              tgtArrowAngle = (tgtArrowAngle + 2 * Math.PI) % (2 * Math.PI);
              tgtArrowAngle -= Math.PI / 2; // Adjust for the fact that 0deg is at the top.
              // Calculate direction vector from angle.
              const dx = Math.cos(tgtArrowAngle);
              const dy = Math.sin(tgtArrowAngle);

              // Prepare to collect intersection parameters (t-values).
              const tValues: number[] = [];

              // Because we do (cx + t * dx = side), we only push solutions if dx (or dy) != 0.
              if (dx !== 0) {
                // Left
                tValues.push((boundingBox.x1 - cx) / dx);
                // Right
                tValues.push((boundingBox.x2 - cx) / dx);
              }
              if (dy !== 0) {
                // Top
                tValues.push((boundingBox.y1 - cy) / dy);
                // Bottom
                tValues.push((boundingBox.y2 - cy) / dy);
              }

              // We only care about the "forward" intersection (t > 0).
              const forwardT = tValues.filter((t) => t > 0);

              // If for some reason no forward intersections, fallback to 0 or some default.
              if (!forwardT.length) {
                return 0;
              }

              // The smallest positive t is the boundary we hit first.
              const t = Math.min(...forwardT);

              // Compute the intersection point.
              const intersectionX = cx + dx * t;
              const intersectionY = cy + dy * t;

              // Return the distance from the center of the bounding box to the intersection point.
              return Math.sqrt((intersectionX - cx) ** 2 + (intersectionY - cy) ** 2);
            },
            'source-distance-from-node': (edge: EdgeSingular) => {
              return 0;
            },
            'target-endpoint': (edge) => {
              return 'outside-to-node';
            },
            'source-endpoint': (edge) => {
              return 'outside-to-node';
            },
            'opacity': (link: EdgeSingular) => {
              const data = this.getLinkDataBasedOnState(link);
              if (data.excludedFromState) {
                return 0.4;
              } else {
                return 1;
              }
            }
          }
        },
        {
          selector: 'edge[shape]',
          style: {
            //@ts-ignore
            'line-style': (element) => {
              const data = this.getLinkDataBasedOnState(element);
              return data.shape || DefaultValues.link.shape;
            },
            'z-index-compare': 'manual',
            'z-index': 1
          }
        },
        {
          selector: 'edge.hidden',
          style: {
            'visibility': 'hidden'
          }
        },
        {
          'selector': 'edge[label]',
          'style': {
            'label': (edge: any): any => {
              let name = '';
              const data = this.getLinkDataBasedOnState(edge);
              if (data?.label === 'name') {
                name = data.name || '';
              } else if (data.metadata && data.metadata.length > 0) {
                name = data.metadata?.find((meta: {
                  key: string;
                  value: string
                }) => meta.key === data.label)?.value || '';
              }
              const hasSomeUrlMetaValue = data.metadata?.some((m: any) => {
                try {
                  new URL(m.value);
                  return true;
                } catch {
                  return false;
                }
              });
              if (hasSomeUrlMetaValue) {
                name = StatusIcons.HAS_LINKS + '\u00A0' + name;
              }
              if (data.hasComments) {
                name = name + '\u00A0' + StatusIcons.HAS_COMMENTS;
              }
              return name;
            },
            'text-valign': 'center',
            'text-halign': 'center',
            'font-family': 'Roboto, "Material Icons"',
            'background-color': '#fff',
            'text-margin-y': (edge: any) => {
              const angle = this.cyService.computeEdgeAngle(edge);
              const margin = this.cyService.computeMarginBasedOnAngle(angle);
              return margin.y;
            },
            'text-margin-x': (edge: any) => {
              const angle = this.cyService.computeEdgeAngle(edge);
              const margin = this.cyService.computeMarginBasedOnAngle(angle);
              return margin.x;
            },
            'text-rotation': () => this.settings?.autoRotateEdgeLabels ? 'autorotate' : 'none',
            'font-size': (edge: any) => {
              const data = this.getLinkDataBasedOnState(edge);

              const size = data.textFontSize;
              return size !== undefined && size !== null && size !== '' ? size : DefaultValues.link.textFontSize;
            },
            'text-wrap': 'wrap'
          }
        },
        // this style is used when a edge is create or dragged
        {
          selector: 'edge.eh-preview',
          style: {
            'background-color': (edge: any) => {
              const data = this.getLinkDataBasedOnState(edge, DashboardComponent.tappedLink);
              return data?.color || DefaultValues.link.color;
            },
            'line-color': (edge: EdgeSingular) => {
              const data = this.getLinkDataBasedOnState(edge, DashboardComponent.tappedLink);
              return data?.color || DefaultValues.link.color;
            },
            'target-arrow-color': (edge: EdgeSingular) => {
              const data = this.getLinkDataBasedOnState(edge, DashboardComponent.tappedLink);

              return data?.color || DefaultValues.link.color;
            },
            'source-arrow-color': (edge: EdgeSingular) => {
              const data = this.getLinkDataBasedOnState(edge, DashboardComponent.tappedLink);
              return data?.color || DefaultValues.link.color;
            },
            //@ts-ignore
            'curve-style': (element) => {
              const data = this.getLinkDataBasedOnState(element, DashboardComponent.tappedLink);
              //@ts-ignore
              return EdgeTypeMapCy[data.type || 'BEZIER'];
            },
            'arrow-scale': (link: EdgeSingular) => {
              const data = this.getLinkDataBasedOnState(link, DashboardComponent.tappedLink);
              const isGhostNode = Object.keys(data)?.length === 3;

              return data.weight as number >= 1 ? data.weight as number / 2 :
                isGhostNode ? 0.5 : 0.3;
            },
            'width': (link: EdgeSingular) => {
              const data = this.getLinkDataBasedOnState(link, DashboardComponent.tappedLink);
              const isGhostNode = Object.keys(data)?.length === 3;
              return data.weight as number >= 1 ?
                data.weight as number :
                isGhostNode ? 1 : 0.1;
            },
            'source-arrow-shape': () => this.isChangingLinkSource ? 'triangle' : 'none',
            'target-arrow-shape': () => this.isChangingLinkSource ? 'none' : 'triangle',
            //@ts-ignore
            'line-style': (element) => {
              const data = this.getLinkDataBasedOnState(element, DashboardComponent.tappedLink);
              return data.shape || DefaultValues.link.shape;
            },
            'z-index-compare': 'manual',
            'z-index': 1
          }
        },
        // Styles for when a new edge is in the process of being created
        {
          selector: '.eh-ghost-edge',
          style: {
            'background-color': (edge: any) => {
              const data = this.getLinkDataBasedOnState(edge, DashboardComponent.tappedLink);
              return data?.color || DefaultValues.link.color;
            },
            'line-color': (edge: EdgeSingular) => {
              const data = this.getLinkDataBasedOnState(edge, DashboardComponent.tappedLink);

              return data?.color || DefaultValues.link.color;
            },
            'target-arrow-color': (edge: EdgeSingular) => {
              const data = this.getLinkDataBasedOnState(edge, DashboardComponent.tappedLink);

              return data?.color || DefaultValues.link.color;
            },
            'source-arrow-color': (edge: EdgeSingular) => {
              const data = this.getLinkDataBasedOnState(edge, DashboardComponent.tappedLink);

              return data?.color || DefaultValues.link.color;
            },
            'source-arrow-shape': () => this.isChangingLinkSource ? 'triangle' : 'none',
            //@ts-ignore
            'line-style': (element) => {
              const data = this.getLinkDataBasedOnState(element, DashboardComponent.tappedLink);
              return data.shape || DefaultValues.link.shape;
            },
            //@ts-ignore
            'curve-style': (element) => {
              const data = this.getLinkDataBasedOnState(element, DashboardComponent.tappedLink);
              //@ts-ignore
              return EdgeTypeMapCy[data.type || 'BEZIER'];
            },
            'arrow-scale': (link: EdgeSingular) => {
              const data = this.getLinkDataBasedOnState(link, DashboardComponent.tappedLink);
              const isGhostNode = Object.keys(data)?.length === 3;

              return data.weight as number >= 1 ? data.weight as number / 2 :
                isGhostNode ? 0.5 : 0.3;
            },
            'width': (link: EdgeSingular) => {
              const data = this.getLinkDataBasedOnState(link, DashboardComponent.tappedLink);
              const isGhostNode = Object.keys(data)?.length === 3;
              return data.weight as number >= 1 ?
                data.weight as number :
                isGhostNode ? 1 : 0.1;
            },
            'z-index-compare': 'manual',
            'z-index': 1,
            'target-arrow-shape': () => this.isChangingLinkSource ? 'none' : 'triangle'
          }
        },
        {
          selector: 'node[selected]',
          style: {
            //@ts-ignore
            'overlay-opacity': () => SettingsService.sharedValues.node.nodeOverlaySelectOpacity,
            'overlay-padding': '2px',
            'z-index': -3,
            'z-index-compare': 'manual'
          }
        },
        {
          selector: 'node:active',
          style: {
            //@ts-ignore
            'overlay-opacity': () => SettingsService.sharedValues.node.nodeOverlaySelectOpacity,
            'overlay-color': () => SettingsService.sharedValues.node.nodeHighlightColor,
            'overlay-padding': '2px',
            'z-index': -3,
            'z-index-compare': 'manual'
          }
        },
        {
          selector: 'edge:selected',
          style: {
            //@ts-ignore
            'overlay-opacity': () => SettingsService.sharedValues.link.selectedOverlayOpacity,
            'overlay-color': () => SettingsService.sharedValues.link.selectedOverlayColor,
            'overlay-padding': '2px',
            'z-index': -1,
            'z-index-compare': 'manual'
          }
        },
        {
          selector: 'edge:active',
          style: {
            //@ts-ignore
            'overlay-opacity': () => SettingsService.sharedValues.link.selectedOverlayOpacity,
            'overlay-color': () => SettingsService.sharedValues.link.selectedOverlayColor,
            'z-index': -1,
            'z-index-compare': 'manual',
            'overlay-padding': '2px'
          }
        },
        // Misc Edge Styles
        {
          'selector': 'edge.hidden',
          'style': {
            'visibility': 'hidden'
          }
        },
        {
          'selector': 'edge.bezier',
          'style': {
            'curve-style': 'bezier',
            'width': 'data(weight)',
            'control-point-step-size': 40,
            'z-index-compare': 'manual',
            'z-index': 1
          }
        },
        {
          'selector': 'edge.unbundled-bezier',
          'style': {
            'curve-style': 'unbundled-bezier',
            'control-point-distances': 120,
            'control-point-weights': 0.1,
            'z-index-compare': 'manual',
            'z-index': 1
          }
        },
        {
          'selector': 'edge.multi-unbundled-bezier',
          'style': {
            'curve-style': 'unbundled-bezier',
            'control-point-distances': [40, -40],
            'z-index-compare': 'manual',
            'z-index': 1,
            'control-point-weights': [0.250, 0.75]
          }
        },
        {
          'selector': 'edge.haystack',
          'style': {
            'curve-style': 'haystack',
            'z-index-compare': 'manual',
            'z-index': 1
          }
        },
        {
          'selector': 'edge.segments',
          'style': {
            'curve-style': 'segments',
            'segment-distances': [40, -40],
            'width': 'data(weight)',
            'segment-weights': [0.250, 0.75],
            'z-index-compare': 'manual',
            'z-index': 1
          }
        },
        {
          'selector': 'edge.taxi',
          'style': {
            'curve-style': 'taxi',
            'taxi-direction': 'downward',
            'taxi-turn': 20,
            'taxi-turn-min-distance': 5,
            'z-index-compare': 'manual',
            'z-index': 1
          }
        },
        // END misc edge styles
        {
          selector: 'node:selected',
          style: {
            'overlay-padding': (e: any) => {
              const id = e.id();
              //@ts-ignore
              return (id === DashboardComponent.tappedNode?.id || id === DashboardComponent.tappedLink?.id) ? '2.5px' : '2px';
            },
            'overlay-opacity': (e: any) => {
              const id = e.id();
              //@ts-ignore
              const multiSelected = this.cy.$(':selected').length > 1;
              if (!multiSelected) {
                return SettingsService.sharedValues.node.nodeOverlaySelectOpacity;
              } else {
                return (id === DashboardComponent.tappedNode?.id || id === DashboardComponent.tappedLink?.id) ? SettingsService.sharedValues.node.nodeOverlaySelectOpacity : SettingsService.sharedValues.node.nodeOverlaySelectOpacity / 2;
              }
            },
            'overlay-color': () => SettingsService.sharedValues.node.nodeHighlightColor,
            'z-index-compare': 'manual',
            'z-index': 2
          }
        },
        {
          selector: '.eh-ghost-edge.eh-preview-active',
          style: {
            'z-index-compare': 'manual',
            'z-index': 1,
            'opacity': 0,
            'source-arrow-shape': 'none'
          }
        },

        ...cyNodeStyles,
        ...miscStyles
      ],
      layout: {
        name: 'preset'
      }
    });
  }

  private loadAllLinks(cy: Core, rootNode: GraphNode[], linksToDraw?: GraphNodeLink[]): Promise<void> {
    return new Promise<void>((resolve) => {
      const links: any[] = linksToDraw || [];

      function getAllLinks(node: any) {
        let allLinks = [...node.links];

        if (node.children && node.children.length > 0) {
          for (const child of node.children) {
            allLinks = allLinks.concat(getAllLinks(child));
          }
        }

        return allLinks;
      }

      // If we have provided linksToDraw, use them, otherwise get all links from the root node
      if (!linksToDraw) {
        rootNode.forEach((e: any) => {
          links.push(getAllLinks(e));
        });
      }


      const delayBetweenIterations = 10;
      const self = this;

      async function processLinksSequentially() {
        //@ts-ignore
        for (const link of (linksToDraw ? links : links.flat())) {
          const newL = self.addCyLink(cy, link);
          //@ts-ignore
          await new Promise((resolve) => setTimeout(resolve, delayBetweenIterations));
        }
      }


      cy.batch(async () => {
        await processLinksSequentially();
        const allLinks = cy.edges();
        if (this.edgeEditingInstance) {
          this.edgeEditingInstance.initAnchorPoints(allLinks);
        }
        resolve();
      });

    });
  }

  private updateLinksPointPositionsData($event: Partial<GraphNodeLink>) {
    if (this.edgeEditingInstance && this.cy) {
      const canvasLink = this.cy.$('#' + $event.id);
      const positions = this.edgeEditingInstance.getAnchorsAsArray(canvasLink);
      const type = this.edgeEditingInstance.getEdgeType(canvasLink) as 'bend' | 'control';
      if (!positions) return $event;
      if (type === 'bend') {
        set($event, 'bendPointPositions', mapAnchorsToXYArray(positions));
      } else if (type === 'control') {
        set($event, 'controlPointPositions', mapAnchorsToXYArray(positions));
      }
    }
    return $event;
  }

  private hasAnyAnchorPoints(link: GraphNodeLink) {
    const hasControlPointsOrWeightsOrDistances = !isEmpty(link?.controlPointPositions) || !isEmpty(link?.cyedgecontroleditingWeights) || !isEmpty(link?.cyedgecontroleditingDistances);
    const hasBendPointsOrWeightsOrDistances = !isEmpty(link?.bendPointPositions) || !isEmpty(link?.cyedgebendeditingWeights) || !isEmpty(link?.cyedgebendeditingDistances);
    return hasControlPointsOrWeightsOrDistances || hasBendPointsOrWeightsOrDistances;
  }

  multiReset($event: { nodeIds: string[], linkIds: string[] }) {
    const currentStateId = this.mapStateService.getCurrentLocalState()?.id;
    if (currentStateId === undefined) {
      return;
    }
    this.nodeService.multiReset({
      nodeIds: $event.nodeIds,
      linkIds: $event.linkIds,
      stateId: currentStateId
    })
      .subscribe((res) => {
        this.selectedLinks = [];
        this.selectedNodes = [];
        this.selectedLinks = res.links;
        this.selectedNodes = res.nodes;

        this.refreshNodes(res.nodes);
        this.refreshLinks(res.links);
      });
  }

  private handleElementSelection(cy: cytoscape.Core) {
    cy.on('select', 'node', async (event: any) => {
      const currentState = this.mapStateService.getCurrentLocalState();
      const nodeData = event.target.data() as GraphNode;
      let dataToPush;
      this.isDetailsOpen = true;
      dataToPush = nodeData;

      if (currentState?.id) {
        const nodesStateBasedOnSelectedState = nodeData.states?.find((s: NodeStateData) => s.stateId === currentState.id);
        if (nodesStateBasedOnSelectedState) {
          const statedNode = { ...nodesStateBasedOnSelectedState.node } as any;
          const originalNode = {
            id: nodeData.id,
            states: nodeData.states
          };
          statedNode.originalNode = originalNode as unknown as StateLessNode;
          statedNode.states = nodeData.states;
          dataToPush = statedNode;
        }
      }

      this.selectedNodes.push(dataToPush);
      event.target.data('selected', true);
      this.selectedLinks = [];
      this.toggleEdgeVisibility(cy, true);
      const selectedElements = cy.$(':selected');
      if (selectedElements.length > 1) {
        selectedElements.forEach((e: any) => e.addClass('multi'));
      }
    });
    cy.on('select', 'edge', (event: any) => {
      const edge = event.target;
      const edgeData = edge.data();
      const currentState = this.mapStateService.getCurrentLocalState();
      edge.addClass('highlighted');
      this.isDetailsOpen = true;
      let dataToPush;
      dataToPush = edgeData;

      if (currentState?.id) {
        const edgesStateBasedOnSelectedState = edgeData.states?.find((s: LinkStateData) => s.stateId === currentState.id);
        if (edgesStateBasedOnSelectedState) {
          const statedLink = { ...edgesStateBasedOnSelectedState.link } as any;
          const originalLink = {
            id: edgeData.id,
            states: edgeData.states
          };
          statedLink.origin = originalLink as unknown as StateLessLink;
          statedLink.states = edgeData.states;
          dataToPush = statedLink;
        }
      }

      this.selectedLinks.push(dataToPush);
      this.selectedNodes = [];
      const selectedElements = cy.$(':selected');
      if (selectedElements.length > 1) {
        selectedElements.forEach((e: any) => e.addClass('multi'));
      }
    });

    cy.on('unselect', 'node', (event: any) => {
      if (DashboardComponent.multiSelectedElements.length > 0) {
        DashboardComponent.multiSelectedElements.forEach((n) => {
          const element = cy.$('#' + n.id);
          if (element.hasClass('force-unselect')) {
            element.unselect();
            element.removeClass('force-unselect');
            element.removeClass('highlighted');
            element.removeData('selected');

            DashboardComponent.multiSelectedElements.splice(DashboardComponent.multiSelectedElements.indexOf(n), 1);
          } else {
            element.select();
          }
        });
        return;
      }

      const target = event.target;
      this.selectedNodes = [];
      this.selectedLinks = [];
      event.target.removeClass('highlighted');
      this.toggleEdgeVisibility(cy, true);
      if (target.data('isNew') || target.data('savedViewSelected')) {
        target.data('isNew', false);
      }
      target.removeData('selected');
    });

    cy.on('unselect', 'edge', (event: any) => {
      if (DashboardComponent.multiSelectedElements.length > 0) {
        DashboardComponent.multiSelectedElements.forEach((n) => {
          const element = cy.$('#' + n.id);
          if (element.hasClass('force-unselect')) {
            element.unselect();
            element.removeClass('force-unselect');
            element.removeClass('highlighted');
            element.removeData('selected');

            DashboardComponent.multiSelectedElements.splice(DashboardComponent.multiSelectedElements.indexOf(n), 1);
          } else {
            element.select();
          }
        });
        return;
      }

      const target = event.target;
      this.selectedNodes = [];
      this.selectedLinks = [];
      target.removeClass('highlighted');
      this.toggleEdgeVisibility(cy, true);
      if (target.data('isNew') || target.data('savedViewSelected')) {
        target.data('isNew', false);
      }
      target.removeData('selected');
    });
  }

  private refreshLinks(links: GraphNodeLink[]) {
    let cyLinks: EdgeSingular[] = [];
    links.forEach((link) => {
      this.cy?.batch(() => {
        const cyLink = this.cy?.$('#' + link.id);
        cyLink?.data({
          source: link.from?.id || link.from,
          targetEndpoint: link.targetEndpoint,
          sourceEndpoint: link.sourceEndpoint,
          label: link.label || DefaultValues.link.label,
          name: link.name || '',
          target: link.to?.id || link.to,
          color: link?.color || DefaultValues.link.color,
          type: link.type,
          textFontSize: link.textFontSize,
          shape: link.shape || DefaultValues.link.shape,
          excludedFromState: link.excludedFromState,
          direction: link.direction || DefaultValues.link.direction,
          weight: link.weight,
          metadata: link.metadata,
          states: link.states,
          id: link.id,
          controlPointPositions: link.controlPointPositions,
          bendPointPositions: link.bendPointPositions
        });
        if (cyLink) {
          cyLinks.push(cyLink);
        }

        //@ts-ignore
        const isMultiSelection = this.cy?.$(':selected').length > 1;
        if (link.id === DashboardComponent.tappedLink?.id && !isMultiSelection) {
          setTimeout(() => {
            const statedLink = this.getLinkDataBasedOnState(cyLink as EdgeSingular);
            DashboardComponent.tappedLink = statedLink;
            cyLink?.select();
          }, 100);
        }
      });
    });
    setTimeout(() => {
      Logger.log('Refreshing links , sending update in progress - false');
      updateInProgress.set(false);
    }, 5000);

    // if (cyLinks.length > 0) {
    //   setTimeout(() => {
    //     // this.nodeService.multiUpdateLinks(payload).subscribe();
    //     updateInProgress.set(false);
    //   }, 100 * cyLinks.length);
    // }
  }

  private refreshNodes(nodes: GraphNode[]) {
    nodes.forEach((node: any) => {
      this.cy?.batch(() => {
        const cyNode = this.cy?.$('#' + node.id);
        cyNode?.data({
          name: node.name,
          collapsed: node.collapsed,
          color: node.color,
          label: node.name,
          imageUrl: node.imageUrl,
          metadata: node.metadata,
          properties: node.properties,
          excludedFromState: node.excludedFromState,
          states: node.states,
          type: node.type,
          weight: node.weight
        });

        if (node.id === DashboardComponent.tappedNode?.id) {
          setTimeout(() => {
            const statedNode = this.getNodeDataBasedOnState(cyNode as NodeSingular);
            DashboardComponent.tappedNode = statedNode;
            cyNode?.select();
          }, 100);
        }
      });
    });
  }

  private addCyLink(cy: cytoscape.Core, link: GraphNodeLink) {
    const fromId = link.from?.id || link.from;
    const toId = link.to?.id || link.to;
    const target = cy.$(`#${toId}`);
    const source = cy.$(`#${fromId}`);
    if (target.length === 0 || source.length === 0) return;
    const linkExists = cy.$(`#${link.id}`).length > 0;
    if (linkExists) {
      cy.$(`#${link.id}`).remove();
    }
    const data = {
      source: link.from?.id || link.from,
      label: link.label || DefaultValues.link.label,
      name: link.name || '',
      description: link.description,
      target: link.to?.id || link.to,
      color: link?.color || DefaultValues.link.color,
      type: link.type,
      textFontSize: link.textFontSize,
      shape: link.shape || DefaultValues.link.shape,
      excludedFromState: link.excludedFromState,
      direction: link.direction || DefaultValues.link.direction,
      weight: link.weight,
      metadata: link.metadata,
      hasComments: link.hasComments,
      targetEndpoint: link.targetEndpoint,
      sourceEndpoint: link.sourceEndpoint,
      states: link.states,
      id: link.id,
      cyedgebendeditingDistances: link.cyedgebendeditingDistances,
      cyedgebendeditingWeights: link.cyedgebendeditingWeights,
      bendPointPositions: link.bendPointPositions,
      controlPointPositions: link.controlPointPositions
    };

    let classes = 'bottom-center';
    const currentEdgeViewMode = this.settingsService.linkViewMode;
    if (currentEdgeViewMode !== LinkViewMode.ALL) {
      classes += ' hidden';
    }

    const isDefaultState = this.mapStateService.isDefaultState();
    //@ts-ignore
    if (!isDefaultState) {
      classes += ' not-default-state';
    }

    if (data.type === 'BEZIER' && isDefaultState) {
      classes += ' ignore_control_points';
    }

    const currentMap = this.mapService.getCurrentSelectedMapFromStore();
    //@ts-ignore
    //@ts-ignore
    if (isInIframe() || this.isReadOnly || currentMap?.isReadonly) {
      classes += ' cant-edit';
    }

    return cy.add({
      group: 'edges',
      data,
      classes
    });
  }

  private removeNodeFromParent(node: cytoscape.NodeSingular) {
    return this.nodeService.removeParent({ childIds: [node.id()], mapId: this.currentMap?.id as string })
      .pipe(
        finalize(() => {
          node.data('parent', null);
          node.move({ parent: null });
          node.data('draggedByParent', false);
        })
      );
  }

  private moveNodeOneLevelUp(node: cytoscape.NodeSingular) {
    const parent = node.parent();
    const grandParent = parent.parent() as NodeSingular;

    if (grandParent.data() !== undefined) {
      node.move({ parent: grandParent.id() });
      node.data('draggedByParent', false);
      return this.nodeService.updateParent({ childIds: [node.id()], parentId: grandParent.id() });
    } else {
      return this.removeNodeFromParent(node);
    }
  }

  private onTap(cy: cytoscape.Core) {
    cy.on('cxttap', (event) => {
      if (!isEmpty(event.target.data())) {
        const isDefaultState = !this.mapStateService.getCurrentLocalState()?.id;
        const currentStateId = this.mapStateService.getCurrentLocalState()?.id;
        const isMultiSelect = cy.$(':selected').length > 1;
        const data = event.target.data();
        if (!ButtonPressService.isShiftPressed && !isMultiSelect) {
          cy.$(':selected').unselect();
          DashboardComponent.tappedNode = null;
          DashboardComponent.tappedLink = null;
        }
        event.target.select();
        if (event.target.isNode()) {
          DashboardComponent.tappedLink = null;
          if (!isDefaultState) {
            DashboardComponent.tappedNode = data.states?.find((s: NodeStateData) => s.stateId === currentStateId)?.node;
          } else {
            DashboardComponent.tappedNode = data;
          }
        } else {
          DashboardComponent.tappedNode = null;
          if (!isDefaultState) {
            DashboardComponent.tappedLink = data.states?.find((s: LinkStateData) => s.stateId === currentStateId)?.link;
          } else {
            DashboardComponent.tappedLink = data;
          }
        }
      }
    });
  }

  private handleEdgeEditingEnd(cy: cytoscape.Core) {
    cy.on('edgeediting_moveend', (event: any, link: EdgeSingular) => {
      if (updateInProgress()) {
        return;
      }
      if (link) {
        if (link.data('deleted')) {
          return;
        }
        const currentState = this.mapStateService.getCurrentLocalState();
        let payload = cloneDeep(link.data());

        if (currentState?.id !== undefined) {
          const linkState = payload.states.find((s: LinkStateData) => s.stateId === currentState.id);
          const index = payload.states.indexOf(linkState);
          const pointData = getPointDataFromAnchorsArray(link, this.edgeEditingInstance, index);
          if (pointData) {
            const statedLinkNoStatesCopy = omit(cloneDeep(linkState.link), ['states']);
            set(statedLinkNoStatesCopy, pointData.dataKey, pointData.pointData);
            set(statedLinkNoStatesCopy, 'states', link.data('states'));
            payload = statedLinkNoStatesCopy;
          }
        } else {
          const pointData = getPointDataFromAnchorsArray(link, this.edgeEditingInstance);
          if (pointData) {
            set(payload, pointData.dataKey, pointData.pointData);
          }
        }
        this.updateLink(payload).subscribe();
      }
    });
  }

}
